import { initialState } from "./reducer";

const getStateData = (state: any) => state["logActivity"] || initialState;

export const getLogActivityList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.logActivityList;
}


export const getLogActivityListLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.logActivityListLoading;
};

export const getLogActivityListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.logActivityListCount;
};
