import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";

interface ModalProps {
  handleClose: ()=>void;
  open: boolean;
  modalTitle?: string;
  children?: React.ReactNode;
  customClass?:string;
}

const CustomModal = ({ handleClose, open, modalTitle, children, customClass }: ModalProps) => {
  return (
    <Dialog className={`stepsDialog ${customClass}`} onClose={handleClose} open={open}>
      <div className="dialogWrapper">
        <div className="dialogWrapper__header">
          <h2>{modalTitle}</h2>
        </div>
        <span role="link" onClick={handleClose} className="closeDialog">
          <Close />
        </span>
        {children}
      </div>
   
    </Dialog>
  );
};

export default CustomModal;
