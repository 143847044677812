import { Dialog } from "@mui/material";
import { PropsWithChildren } from "react";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import styles from "./ConfirmationModal.module.scss";
import {ConfirmationModalProps} from "./interface";


const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  buttonComponent,
  iconComponent,
  closeButtonLabel = "Nevermind",
  showCloseButton = true,
  children,
  rootClasses = "successDialog",
}: PropsWithChildren<ConfirmationModalProps>) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      className={`stepsDialog ${rootClasses}`}
      onClose={closeModal}
      open={isOpen}
    >
      <div className="dialogWrapper">
        <div className={` ${styles.cancelDialog} `}>
          <div className="dflex justifySpaceBetween alignCenter">
            {iconComponent && (
              <span className={` ${styles.cancelDialog__icon}`}>
                {iconComponent}
              </span>
            )}
            <span role="link" onClick={closeModal} className="closeDialog">
              <Close />
            </span>
          </div>
          <div className={` ${styles.cancelDialog__info}`}>{children}</div>
          <div className={` ${styles.cancelDialog__footer} flex`}>
            {showCloseButton && (
              <Button
                label={closeButtonLabel}
                className="outline xl full"
                action={closeModal}
              />
            )}
            {buttonComponent}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default ConfirmationModal;
