import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { LeadStatus } from "../../constant/leadStatus.constant";
import { useForm } from "react-hook-form";
import HeroSection from "../heroSection/HeroSection";
import { useParams } from "react-router-dom";
import {useSelector } from "react-redux";
import SearchReflation from "../../assets/icons/searchReflation";
import SearchBar from "../../atoms/SearchBar";
import Button from "../../atoms/Button";
import Funnel from "../../assets/icons/funnel";
import Circle from "../../assets/icons/circle";
import FilterDropdown from "../filterDropdown/FilterDropdown";
import MultiSelectDropDown, {
  MultiDropDownOption,
} from "../../atoms/MultiSelectDropDown";
import LeadTable from "../../pages/leadTable/LeadTable";
import { resetLeadState } from "../../pages/propertyDetail/reducer";
import { getCampaignsList } from "../../pages/campaignDetails/selector";
import { LeadFilters } from "./interface";
import { fetchCampaignData } from "../../pages/propertyDetail/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { sendActivityLog } from "../auth/service";
import styles from "./Leads.module.scss";

const INITIAL_STATE = {
  search: "",
  index: 1,
  size: 20,
  leadStatus: [],
  campaignIds: [],
};
const Leads = () => {
  const { reset, control, getValues, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: {
      leadStatus: [],
      campaignIds: [],
    },
  });
  const { orgId } = useParams();
  const [filterCampaigns, setFilterCampaigns] = useState<string[]>([]);
  const filterButtonRef = useRef<HTMLElement | null>(null);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filterFlag, setFilterFlag] = useState(false);
  const [campaigns, setCampaigns] = useState<MultiDropDownOption[]>([]);
  const [statusLeads, setStatusLeads] = useState([]);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [showfilterBtnDropdown, setShowFilterBtnDropdown] =
    useState<boolean>(false);
  const [filter, setFilter] = useState<LeadFilters>(INITIAL_STATE);
  const LeadStatusOptions = Object.entries(LeadStatus).map(
    ([key, value]) => ({
      label: value,
      value: key,
    })
  );

  const setSearchText = (value: string) => {
    setFilter((prev) => ({ ...prev, search: value, index: 1 }));
  };

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
  };

  const handleFilterButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    filterButtonRef.current = e.currentTarget;
    setShowFilterBtnDropdown(true);
  };

  const handleAttemptClose = () => {
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
    setApplyClicked(false);
  };
  const handleAttemptApplyClose = () => {
    setApplyClicked(true);
    if (
      getValues("leadStatus").length !== 0 ||
      getValues("campaignIds").length !== 0
    ) {
      setFilterFlag(true);
    }
    setFilter({ ...filter, index: 1, ...getValues() });
    setStatusLeads(getValues()?.leadStatus);
    setFilterCampaigns(getValues()?.campaignIds);
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
  };

  useEffect(() => {
    const fetchCampaignMinimalData = async () => {
      try {
        const response = await fetchCampaignData(orgId);
        const data = response?.data;
        setCampaigns(
          data.map((item: { id: string; name: string }) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaignMinimalData();
  }, []);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "Leads page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "Leads page viewed",
            jsonData: result,
          });
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo]);

  return (
    <div className={`${styles.leads}`}>
      <HeroSection title="Leads"></HeroSection>
      <div className={`${styles.leads__filters} dflex alignCenter`}>
      <div className={`${styles.leads__search} `}>
        <SearchBar
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            if (
              e.target.value.length > 0 &&
              e.target.value.trim().length === 0
            ) {
              value = "";
            }
            if (filter.search !== value) {
              setSearchText(value);
            }
          }}
          searchText={filter.search}
        ></SearchBar>
      </div>
      <div className={`${styles.leads__btn} `}>
      <Button
        label="Filter"
        className="primary filter-icon full"
        prefix={<Funnel />}
        action={handleFilterButtonClick}
        postfix={filterFlag ? <Circle color="#D92D20" /> : null}
      />
      </div>
      {showfilterBtnDropdown && (
        <FilterDropdown
          handleClose={handleAttemptClose}
          anchorEl={filterButtonRef.current}
          dataTestId="FilterBtnId"
        >
          <div className={`filterMenu`} data-testid="filterButtonModalId">
            <div className="filterMenu__info">
              <div
                className={`filterMenu__list`}
                data-testid="SelectCampaignTestId"
              >
                <p>Campaign</p>
                <MultiSelectDropDown
                  options={campaigns}
                  label="Select campaign(s)"
                  control={control}
                  name="campaignIds"
                  emptySearchMetaData={{
                    icon: <SearchReflation />,
                    title: "No results found",
                  }}
                />
              </div>
              <div className={`filterMenu__list`}>
                <p>Status</p>
                <MultiSelectDropDown
                  options={LeadStatusOptions}
                  label="Select status(es)"
                  control={control}
                  name="leadStatus"
                  emptySearchMetaData={{
                    icon: <SearchReflation />,
                    title: "No results found",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`dflex filterMenuFooter`}>
            <div className={`filterMenuFooter__half`}>
              <Button
                label="Reset"
                className="outline full"
                action={() => {
                  resetLeadState({});
                  // setLeadListIsLoading(true);
                  setStatusLeads([]);
                  setFilterCampaigns([]);
                  reset({
                    leadStatus: [],
                    campaignIds: [],
                  });
                  setFilter(INITIAL_STATE);
                  setFilterFlag(false);
                  setShowFilterBtnDropdown(false);
                }}
              />
            </div>
            <div className={`filterMenuFooter__half`}>
              <Button
                label="Apply"
                className="primary full"
                action={handleAttemptApplyClose}
                dataTestId={"ApplyFilterBtnId"}
              />
            </div>
          </div>
        </FilterDropdown>
      )}
      </div>
      <div className={`${styles.leads__table} `}>
      <LeadTable
        filter={filter}
        handleNextPage={handleNextPage}
        handleClearSearch={() => setSearchText("")}
      ></LeadTable>
      </div>
    </div>
  );
};

export default Leads;
