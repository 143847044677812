import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getLoadingOrganization,
  getOrgSubscription,
  getOrganization,
  getOrganizationList,
} from "../../pages/organization/selector";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../atoms/Button";
import UpdateCredits from "../../components/updateCredits/UpdateCredits";
import HeroSection from "../heroSection/HeroSection";
import AnalyticCards from "../analyticCards/AnalyticCards";
import PlusCircle from "../../assets/icons/plusCircle";
import styles from "./BillingHistory.module.scss";
import HistoryDetail from "../../pages/historyDetail/HistoryDetail";
import {
  getInvoices,
  getInvoicesLoading,
} from "../../pages/historyDetail/selector";
import Loader from "../../atoms/Loader";
import { fetchInvoices } from "../../pages/historyDetail/sagaAction";
import { formartNumberAsLocalString } from "../../utils/utils";
import dayjs from "dayjs";
import {
  ProductPriceIntervalType,
  ProductType,
} from "../../pages/historyDetail/enum";
import {
  fetchOrganization,
  fetchOrganizationSubscription,
} from "../../pages/organization/sagaAction";
import ManageSubscription from "./ManageSubscription";
import CancelSubscription from "./CancelSubscription";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import {  CurrencyExchange } from "@mui/icons-material";
import Refund from "./Refund";
import { getProfileInfo } from "../../pages/profile/selector";
import { sendActivityLog } from "../auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { Tooltip } from "@mui/material";
import { PermissionListEnum } from "../../pages/rolesAndPermission/enum";

const BillingHistory = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const orgDetail = useSelector((state) => getOrganization(state));
  const [openCancelSubscription, setOpenCancelSubscription] = useState(false);
  const [cancelSubscriptionProduct, setCancelSubscriptionProduct] =
    useState(null);
  const orgLoading = useSelector((state) => getLoadingOrganization(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const loading = useSelector((state) => getInvoicesLoading(state));
  const [currentCredits, setCurrentCredits] = useState(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [refundDetail, setRefundDetail] = useState({});
  const invoices = useSelector((state) => getInvoices(state));
  const [openManageSubscription, setOpenManageSubscription] = useState(false);
  const [invoiceFilter, setInvoiceFilter] = useState({
    last_Index: "",
    size: 10,
  });
  const handleUpdateCreditsModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const handleInvoicesNextPage = () => {
    dispatch(
      fetchInvoices({
        last_Index: invoiceFilter.last_Index,
        size: invoiceFilter.size,
        id: param.orgId,
      })
    );
  };

  const handleCancelSubscription = (product: any) => {
    setCancelSubscriptionProduct(product);
    setOpenCancelSubscription(true);
  };

  const logData = async () => {
    const attributes = {
      action:`User is trying to update credits of organisation ${param?.orgId}`,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      try {
        const res = await sendActivityLog({
          logType: "User is trying to update credits.",
          jsonData: result,
        });
      } catch (error) {
        console.error("Error sending activity log:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
};

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

  const displaySuccessMessage = (message: string) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: ToastType.success,
      })
    );
  };

  useEffect(() => {
    if (Object.keys(orgDetail).length > 0) {
      console.log(orgDetail?.availableCredit);
      setCurrentCredits(orgDetail?.availableCredit);
    }
  }, [orgDetail, param.orgId]);

  useEffect(() => {
    dispatch(fetchOrganization(param.orgId));
   
  }, [param.orgId]);

  useEffect(() => {
    dispatch(
      fetchInvoices({
        last_Index: invoiceFilter.last_Index,
        size: invoiceFilter.size,
        id: param.orgId,
      })
    );
  }, [param.orgId]);

  useEffect(() => {
    if (!invoices?.invoices) {
      return;
    }
    const data = invoices?.invoices?.map((invoice: any) => ({
      id: invoice?.id,
      invoice: (
        <div>
          {invoice?.productName && (
            <>
              {invoice.productName.toUpperCase()}
              {invoice.subsProductType !== ProductType.GENERAL_ACCESS &&
                invoice.interval !== ProductPriceIntervalType.ONETIME &&
                ` (${
                  invoice.interval === ProductPriceIntervalType.MONTH
                    ? "Monthly"
                    : "Annually"
                })`}
            </>
          )}
          &nbsp;
          {invoice?.subsProductType === ProductType.CREDIT_ACCESS &&
          invoice?.amount === 0 ? (
            <span className="badge  scheduled">Paused trial plan added</span>
          ) : (
            ""
          )}
        </div>
      ),
      amount: `${(
        invoice?.currency ?? ""
      ).toUpperCase()} $${formartNumberAsLocalString(+invoice?.amount ?? 0, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      createdAt: invoice?.createdAt
        ? dayjs(invoice?.createdAt).format(`MMM DD, YYYY`)
        : "",
      status: {
        label: invoice?.status
          ? invoice?.status.charAt(0).toUpperCase() + invoice?.status.slice(1)
          : "",
        toolTipText:
          invoice?.status === "refund"
            ? invoice?.amount_refunded === invoice?.amount
              ? `Refunded $${invoice?.amount}`
              : `Refunded $${invoice?.amount_refunded} out of $${invoice?.amount} `
            : "",
      },
      refund: (
        <span
        className={`${styles.refundIcon}`}
          onClick={() => {
            setRefundDetail(invoice);
            setShowRefundModal(true);
          }}
        >
          <CurrencyExchange />
        </span>
      ),
    }));
    setTableData(data);
    setInvoiceFilter((prev: any) => ({
      ...prev,
      last_Index: invoices?.startAfterId ?? "",
    }));
  }, [invoices]);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "Billing history page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "Billing history page viewed",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo]);
  return (
    <>
      <HeroSection title="Billing history"></HeroSection>
      {orgLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div
          className={`dflex justifySpaceBetween alignCenter ${styles.billing}`}
        >
          <div>
            <h2>Available credits</h2>
            <p>{currentCredits}</p>
          </div>
          <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.ADD_CREDIT) ? "" : "You don't have permission to access this." }>
            <div> 
          <Button
            prefix={<PlusCircle />}
            className="primary animated"
            label="Update Credits"
            action={()=>{
              logData()
            handleUpdateCreditsModal()
          }}
          disabled={!profileInfo?.permissions?.includes(PermissionListEnum.ADD_CREDIT)}
          ></Button>
          </div>
          </Tooltip>
        </div>
      )}

      <ManageSubscription
        handleClose={() => {
          setOpenManageSubscription(false);
        }}
        open={openManageSubscription}
        onCancelSubscription={handleCancelSubscription}
      ></ManageSubscription>

      <CancelSubscription
        handleClose={() => {
          setOpenCancelSubscription(false);
          setCancelSubscriptionProduct(null);
        }}
        open={openCancelSubscription}
        subscription={cancelSubscriptionProduct}
        displayErrorMessage={displayErrorMessage}
        displaySuccessMessage={displaySuccessMessage}
        closeManageSubscription={() => setOpenManageSubscription(false)}
      ></CancelSubscription>

      {showUpdateModal && (
        <UpdateCredits
          open={showUpdateModal}
          handleClose={handleUpdateCreditsModal}
          currentCredits={currentCredits}
          setCurrentCredits={setCurrentCredits}
        ></UpdateCredits>
      )}
      <div className={`${styles.billing__history} alignCenter dflex justifySpaceBetween`}>
      <div>
      <h3>Billing history</h3>
      <p>Access all your previous receipts.</p>
      </div>
       

        <Button
        className="primary animated"
        action={() => {
          setOpenManageSubscription(true);
          dispatch(fetchOrganizationSubscription(param.orgId));
        }}
        label={"Manage user subscription"}
      ></Button>
      </div>
      {loading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.billing__historyTable}`}>
          <HistoryDetail
            setInvoiceFilter={setInvoiceFilter}
            handleInvoicesNextPage={handleInvoicesNextPage}
            tableData={tableData}
            setTableData={setTableData}
            invoices={invoices}
          />
        </div>
      )}

      <Refund
        handleClose={() => {
          setShowRefundModal(false);
          setRefundDetail({});
        }}
        open={showRefundModal}
        detail={refundDetail}
        setRefundDetail={setRefundDetail}
        invoiceFilter={invoiceFilter}
      ></Refund>
    </>
  );
};

export default BillingHistory;
