import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import CurrencyDollar from "../../../../assets/icons/currencyDoller";
import Lock from "../../../../assets/icons/lock";
import Phone from "../../../../assets/icons/phone";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import Input from "../../../../atoms/Input";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import CustomTable from "../../../../molecules/table/CustomTable";
import {
  copyToClipboard,
  formatNewCurrency,
  formatPhoneNumber,
  getDateDifferenceInWords,
} from "../../../../utils/utils";
import {wholesaleCalculatorData,amortizationsaleCalculatorData} from "../constants"

import { ContactInfoItemType, Schedule } from "../../property.type";
import styles from "./OverviewTab.module.scss";
import BlueTick from "../../../../assets/icons/blueTick";
import { yupResolver } from "@hookform/resolvers/yup";
import { amortizationCalculatorSchema } from "../../validation";
import {
  ProfitMarginOptions,
  amortizationScheduleColumns,
} from "../../columnsData";
import SearchReflation from "../../../../assets/icons/searchReflation";
import Button from "../../../../atoms/Button";
import TargetIcon from "../../../../assets/icons/targetIcon";
import DropDown from "../../../../atoms/Dropdown";
import { MenuItem, Tooltip } from "@mui/material";
import Copy from "../../../../assets/icons/copy";
import DotVertical from "../../../../assets/icons/dotsVertical";
import FilterDropdown from "../../../../organisms/filterDropdown/FilterDropdown";
import Trash from "../../../../assets/icons/trash";
import { useParams } from "react-router-dom";
import Plus from "../../../../assets/icons/plus";
import Message from "../../../../assets/icons/message";
import { getPropertyDetailsData, getWholesaleCalculatorList, getWholesaleCalculatorloading, getamortizationCalculatorList, getamortizationCalculatorloading } from "../../selector";
import { fetchAmortizationCalculatorAction, fetchWholesaleCalculatorAction } from "../../sagaAction";

function calculatePaymentPerPeriod(
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod =
    principal *
    (periodInterestRate /
      (1 - Math.pow(1 + periodInterestRate, -totalPeriods)));
  if (!isFinite(paymentPerPeriod) || isNaN(paymentPerPeriod)) {
    return 0;
  }

  return paymentPerPeriod;
}

const calculateAmortizationSchedule = (
  principal: number,
  annualInterestRate: number,
  totalYears: number,
  isMonthly: boolean
) => {
  const periodsPerYear = isMonthly ? 12 : 1;
  const totalPeriods = totalYears * periodsPerYear;
  const periodInterestRate = annualInterestRate / periodsPerYear;
  const paymentPerPeriod = calculatePaymentPerPeriod(
    principal,
    annualInterestRate,
    totalYears,
    isMonthly
  );

  const generateSchedule = (
    period: number,
    balance: number,
    schedule: Schedule[]
  ): Schedule[] => {
    if (period > totalPeriods || balance <= 0) {
      return schedule;
    }

    const interestForPeriod = balance * periodInterestRate;
    let principalForPeriod = paymentPerPeriod - interestForPeriod;
    // Preventing principalForPeriod from going negative due to rounding in the final period
    principalForPeriod =
      principalForPeriod > balance ? balance : principalForPeriod;
    const endingBalance = balance - principalForPeriod;

    schedule.push({
      period: period,
      totalPayment: parseFloat(paymentPerPeriod.toFixed(2)),
      interest: parseFloat(interestForPeriod.toFixed(2)),
      principal: parseFloat(principalForPeriod.toFixed(2)),
      endingBalance: parseFloat(endingBalance.toFixed(2)),
    });

    return generateSchedule(period + 1, endingBalance, schedule);
  };

  return generateSchedule(1, principal, []);
};

interface OverviewTabsProps {
  handleClickSkipTrace?: () => void;
  handleClickAddContact?: () => void;
}

const OverviewTabs = ({
  handleClickSkipTrace,
  handleClickAddContact,
}: OverviewTabsProps) => {
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const contactInfo = propertyDetails.contactInfo as ContactInfoItemType[];
  const emails = propertyDetails.emails;

  const dispatch = useDispatch();
  const {
    formState: { errors, isValid, isDirty },
    register,
    watch,
    control,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      afterRepairValue: 0,
      costOfRepairs: 0,
      assignmentFee: 0,
      loanAmount: 0,
      loanTerm: "",
      interestRate: "",
      profitMargin: ProfitMarginOptions[0].value,
    },
    resolver: yupResolver(amortizationCalculatorSchema),
  });

  const Profitmargin = watch("profitMargin");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [wholesaleLoading, setWholesaleLoading] = useState(false);
  const [wholesaleFailLoading, setWholesaleFailLoading] = useState(false);
  const [amortizationFailLoading, setAmortizationFailLoading] = useState(false);
  const [amortizationLoading, setAmortizationLoading] = useState(false);
  const { orgId,id } = useParams();

  const amortizationCalculatorloading = useSelector((state) =>
    getamortizationCalculatorloading(state)
  );
  const wholesaleCalculatorloading = useSelector((state) =>
    getWholesaleCalculatorloading(state)
  );

  const wholesaleCalculatorData = useSelector((state) =>
    getWholesaleCalculatorList(state)
  );
  const amortizationsaleCalculatorData = useSelector((state) =>
    getamortizationCalculatorList(state)
  );
 
  const afterRepairValue = parseFloat(
    (watch("afterRepairValue") ?? "").toString().replace(/,/gi, "")
  );
  const costOfRepairs = parseFloat(
    (watch("costOfRepairs") ?? "").toString().replace(/,/g, "")
  );
  const assignmentFee = parseFloat(
    (watch("assignmentFee") ?? "").toString().replace(/,/g, "")
  );



  const maxAllowableOffer = useMemo(() => {
    if (
      afterRepairValue == null ||
      costOfRepairs == null ||
      assignmentFee == null ||
      !Profitmargin
    ) {
      return 0;
    }
    return afterRepairValue * Profitmargin - costOfRepairs - assignmentFee;
  }, [afterRepairValue, costOfRepairs, assignmentFee, Profitmargin]);

  const loanAmount = parseFloat(
    (watch("loanAmount") ?? "").toString().replace(/,/g, "")
  );
  const loanTerm = parseFloat(
    (watch("loanTerm") ?? "").toString().replace(/,/g, "")
  );
  const interestRate = parseFloat(
    (watch("interestRate") ?? "").toString().replace(/,/g, "")
  );

  const monthlyPayment = useMemo(() => {
    if (
      isNaN(loanAmount) ||
      loanTerm == null ||
      interestRate == null ||
      !isValid
    ) {
      return;
    }
    return calculatePaymentPerPeriod(
      loanAmount,
      interestRate / 100,
      loanTerm,
      true
    );
  }, [loanAmount, loanTerm, interestRate, isValid]);

  const handleMenu = (event: any, id: string) => {
    setMenuAnchorEl(event.currentTarget);
    setShowMenu(!showMenu);
    setSelectedItemId(id);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
    setMenuAnchorEl(null);
    setSelectedItemId(null);
  };

  const amortizationSchedule = useMemo(() => {
    if (!loanAmount || !loanTerm || loanTerm > 60 || !interestRate || !isValid) {
      return [];
    }
    return calculateAmortizationSchedule(
      loanAmount,
      interestRate / 100,
      loanTerm,
      false
    );
  }, [loanAmount, loanTerm, interestRate, isValid]);

  useEffect(() => {
    const payload = {
       orgId,
      propertyId: id,
    };
    dispatch(fetchWholesaleCalculatorAction(payload));
    dispatch(fetchAmortizationCalculatorAction(payload));
  }, [orgId, id]);

  useEffect(() => {
    setWholesaleLoading(wholesaleCalculatorloading);
    if (wholesaleCalculatorData) {
      const { afterRepairValue, costOfRepairs, assignmentFee, profitMargin } =
        wholesaleCalculatorData;
      if (afterRepairValue || afterRepairValue === 0) {
        setValue("afterRepairValue", wholesaleCalculatorData.afterRepairValue, {
          shouldTouch: true,
        });
      } else {
        setValue("afterRepairValue", null, { shouldTouch: false });
      }
      if (costOfRepairs || costOfRepairs === 0) {
        setValue("costOfRepairs", wholesaleCalculatorData.costOfRepairs, {
          shouldTouch: true,
        });
      } else {
        setValue("costOfRepairs", null, { shouldTouch: false });
      }
      if (assignmentFee || assignmentFee === 0) {
        setValue("assignmentFee", wholesaleCalculatorData.assignmentFee, {
          shouldTouch: true,
        });
      } else {
        setValue("assignmentFee", null, { shouldTouch: false });
      }
      if (profitMargin) {
        setValue(
          "profitMargin",
          parseFloat(wholesaleCalculatorData.profitMargin),
          { shouldTouch: true }
        );
      }
    }
  }, [wholesaleCalculatorData, setValue, 
    wholesaleCalculatorloading
  ]);

  useEffect(() => {
    setAmortizationLoading(amortizationCalculatorloading);
    if (amortizationsaleCalculatorData) {
      console.log(
        "amortizationsaleCalculatorData",
        amortizationsaleCalculatorData
      );

      const { loanAmount, interestRate, loanTerm } =
        amortizationsaleCalculatorData;

      if (loanAmount || loanAmount === 0) {
        setValue("loanAmount", loanAmount, { shouldTouch: true });
      } else {
        setValue("loanAmount", null, { shouldTouch: false });
      }
      if (interestRate || interestRate === 0) {
        setValue("interestRate", interestRate.toString(), {
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        setValue("interestRate", "", { shouldTouch: false });
      }
      if (loanTerm || loanTerm === 0) {
        setValue("loanTerm", loanTerm.toString(), {
          shouldValidate: true,
          shouldTouch: true,
        });
      } else {
        setValue("loanTerm", "", { shouldTouch: false }); 
      }
    }
  }, [amortizationsaleCalculatorData, setValue,
     amortizationCalculatorloading

  ]);

  return (
    <div className={` ${styles.overview}`}>
      <div className="wrapper">
        <ShadowBox
          title="Contact Information"
        >
          {/* NOTE: I removed the `skipButton` param from the above <ShadowBox /> as we're temporarily hiding this button until it is implemented. */}
          <div className={`dflex ${styles.contactInfo}`}>
            {contactInfo?.length > 0 || emails?.length > 0 ? (
              <>
                {contactInfo.map((item: any) => (
                  <div
                    className={`flex alignCenter ${styles.contactInfo__box}`}
                    key={item.id}
                  >
                    <figure>
                      <Phone />
                    </figure>
                    <figcaption>
                      <div
                        className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                      >
                        <a href={`tel:${item.number}`}>
                          {formatPhoneNumber(item.number)}
                        </a>
                        <Tooltip title={"Copy Phone Number"}>
                          <span
                            role="link"
                            className="copy-icon"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              copyToClipboard(item.number);
                            }}
                          >
                            <Copy />
                          </span>
                        </Tooltip>

                        {(item?.phoneAttempts?.length > 0 ||
                          item?.createdBy) && (
                          <span className={` ${styles.contactInfo__blueTick}`}>
                            <BlueTick />
                          </span>
                        )}
                      </div>
                      <p>
                        Added{" "}
                        {getDateDifferenceInWords(new Date(item.createdAt))}
                      </p>
                    </figcaption>
                  </div>
                ))}
                {emails.map((item: any) => (
                  <div
                    className={`flex alignCenter ${styles.contactInfo__box}`}
                    key={item.id}
                  >
                    <figure>
                      <Message />
                    </figure>
                    <figcaption>
                      <div
                        className={`flex alignCenter ${styles.contactInfo__dotMenu}`}
                      >
                        <a
                          className={`flex alignCenter ${styles.contactInfo__email}`}
                          href={`mailto:${item.email}`}
                        >
                          {item.email}
                        </a>
                        <Tooltip title={"Copy Email Address"}>
                          <span
                            role="link"
                            className="copy-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(item.email);
                            }}
                          >
                            <Copy />
                          </span>
                        </Tooltip>

                        {item?.createdBy && (
                          <span className={` ${styles.contactInfo__blueTick}`}>
                            <BlueTick />
                          </span>
                        )}
                        <Button
                          className="transparent"
                          prefix={<DotVertical />}
                          action={(event: any) => handleMenu(event, item.id)}
                        />
                        {showMenu && selectedItemId === item.id && (
                          <FilterDropdown
                            handleClose={handleMenuClose}
                            anchorEl={menuAnchorEl}
                            sizeList
                          >
                            <div className="dflex filterInfo">
                              <ul className="filterList">
                                <MenuItem
                                  className="verified-list-main"
                                  disableRipple
                                >
                                  <div
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setMenuAnchorEl(null);
                                    }}
                                    className={`verified-list-delete`}
                                    role="link"
                                    key={item.id}
                                  >
                                    <Trash />
                                    <span>Delete</span>
                                  </div>
                                  {item?.createdBy && (
                                    <div className="verified-list">
                                      <p>
                                        <BlueTick /> Added by a user
                                      </p>
                                    </div>
                                  )}
                                </MenuItem>
                              </ul>
                            </div>
                          </FilterDropdown>
                        )}
                      </div>
                      <p>
                        Added{" "}
                        {getDateDifferenceInWords(new Date(item.createdAt))}
                      </p>
                    </figcaption>
                    
                  </div>
                ))}
              </>
            ) : (
              <EmptyPlaceholder
                icon={<SearchReflation />}
                title={"No results found"}
                smallSize={true}
                bgGray={true}
                extraStyles={{ width: "100%" }}
              />
            )}
          </div>
        </ShadowBox>
        <ShadowBox
          title="Wholesale Calculator"
          subTitle="Easily make the right wholesale offer with confidence."
          rightTitle="Max Allowable Offer (MAO):"
          rightSubTitle={
            maxAllowableOffer || maxAllowableOffer === 0
              ? formatNewCurrency(maxAllowableOffer)
              : "-"
          }
          showstatus
          loading={wholesaleLoading}
          failLoading={wholesaleFailLoading}
        >
          <div className={`dflex ${styles.calculator}`}>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="number"
                label="After Repair Value (ARV)*"
                register={register}
                name="afterRepairValue"
                placeholder="Enter the ARV"
                errors={errors}
                // prefix={<CurrencyDollar />}
                control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="number"
                label="Cost of Repairs*"
                register={register}
                name="costOfRepairs"
                placeholder="Enter the repairs"
                errors={errors}
                // prefix={<CurrencyDollar />}
                control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <Input
                type="number"
                label="Assignment Fee*"
                register={register}
                name="assignmentFee"
                placeholder="Enter the assignment fee"
                errors={errors}
                // prefix={<CurrencyDollar />}
                control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__colFour}`}>
              <DropDown
                options={ProfitMarginOptions}
                label="Profit Margin*"
                control={control}
                name="profitMargin"
                errors={errors}
                placeholder="70%"
                defaultValue={ProfitMarginOptions[0].value}
                disabled
              ></DropDown>
            </div>
          </div>
        </ShadowBox>
        <ShadowBox
          title="Amortization Calculator"
          subTitle="Modify the values to quickly analyze a deal."
          rightTitle="Monthly Payment:"
          rightSubTitle={
            monthlyPayment || monthlyPayment === 0
              ? formatNewCurrency(monthlyPayment)
              : "-"
          }
          loading={amortizationLoading}
          showstatus
          failLoading={amortizationFailLoading}
        >
          <div className={`dflex ${styles.calculator}`}>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Loan Amount*"
                register={register}
                name="loanAmount"
                placeholder="Enter the loan amount"
                errors={errors}
                // prefix={<CurrencyDollar />}
                // control={control}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Loan Term*"
                register={register}
                name="loanTerm"
                placeholder="Enter number of years"
                errors={errors}
                min={0}
                readOnly
              ></Input>
            </div>
            <div className={` ${styles.calculator__col}`}>
              <Input
                type="number"
                label="Interest Rate*"
                register={register}
                name="interestRate"
                placeholder="Enter the interest rate"
                errors={errors}
                // prefix={<Percent />}
                readOnly
              ></Input>
            </div>
          </div>
          {amortizationSchedule.length !== 0 && (
            <div className="calculator-table">
              {" "}
              <CustomTable
                rows={amortizationSchedule}
                columns={amortizationScheduleColumns}
                tableStriped
              />
            </div>
          )}
        </ShadowBox>
        <ShadowBox
          title="Creative Finance Calculator"
          subTitle="Perform a deep analysis of your creative finance deal."
        >
          <EmptyPlaceholder
            title="Coming Soon"
            description="This calculator is coming soon. It will feature deep deal analysis for any seller finance or subject to deal, including a full breakdown of every aspect of the deal."
            icon={<Lock />}
            bgGray
          />
        </ShadowBox>
      </div>
    </div>
  );
};
export default OverviewTabs;
