import React from "react";
import CustomTable from "../../molecules/table/CustomTable";
import { INVOICE_COLUMN } from "./constant";
import SearchReflation from "../../assets/icons/searchReflation";

const HistoryDetail = ({
  invoices,
  handleInvoicesNextPage,
  tableData,
}: any) => {
  return (
    <>
      <CustomTable
        rows={tableData}
        columns={INVOICE_COLUMN}
        hasMore={invoices?.hasMore}
        tableStriped
        currentPageIndex={1}
        emptyTableMetaData={{
          icon: <SearchReflation />,
          title: "No results found",
          description:
            "We couldn’t find any results that match your search or filtering criteria.",
        }}
        handleNextPage={handleInvoicesNextPage}
      ></CustomTable>
    </>
  );
};

export default HistoryDetail;
