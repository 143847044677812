import React from "react";

import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routes";
import Providers from "./Providers";
import "../src/assets/scss/global.scss";
import MediaContextProvider from "./context/MediaContext";
import interceptor from "./Interceptors/Interceptors";

interceptor()

function App() {
  return (
    <div className="App">
      <Providers>
      <MediaContextProvider><Routes /></MediaContextProvider>  
      </Providers>
    </div>
  );
}

export default App;
