import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listLoading: false,
  myLists: [] as any[],
  listCount: 0,
};

const myListsTableSlice = createSlice({
  name: "myListsTable",
  initialState,
  reducers: {
    setMyLists: (state, { payload }) => {
      return {
        ...state,
        myLists: payload ?? [],
      }; 
    },
    appendMyLists: (state, { payload}) => {
      return {
        ...state,
        myLists: [...state.myLists, ...(payload ? payload : [])],
      };
    },
    setListCount: (state, { payload }) => {
        return {
          ...state,
          listCount: payload ?? [],
        };
      },
    setListLoading: (state, { payload }) => {
      return { ...state, listLoading: payload };
    },
    resetMyListsTableState: (state, { payload }) => {
      return initialState;
    },
  },
});

export const {
  setMyLists,
  setListLoading,
  appendMyLists,
  resetMyListsTableState,
  setListCount
} = myListsTableSlice.actions;

export default myListsTableSlice.reducer;
