import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import SideBar from "../../components/sideBar/SideBar";
import Header from "../../components/header/Header";
import { useContext } from "react";
import {
  MEDIA_TYPES,
  MediaContext,
  MediaContextProps,
} from "../../context/MediaContext";
import { getProfileInfoLoading } from "../../pages/profile/selector";
import Loader from "../../atoms/Loader";
import { fetchProfileInfo } from "../../pages/profile/sagaAction";

const LoginWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const { media } = useContext(MediaContext) as MediaContextProps;
  const { auth } = useSelector((state: any) => state.authGuard);
  const { orgId } = useParams();
  const profileInfoLoading = useSelector((state) =>
    getProfileInfoLoading(state)
  );
  useEffect(() => {
    dispatch(fetchProfileInfo({}));
  }, []);
  
  if (auth) {
    return (
      <>
        {profileInfoLoading ? (
          <Loader />
        ) : (
          <>
            <Header />
            <div
              className={`dashboard-wrapper ${orgId ? "small-sidebar" : ""}`}
            >
              <div className="dashboard-inner">
                {media == MEDIA_TYPES.DESKTOP && <SideBar />}

                {children}
              </div>
            </div>
          </>
        )}
      </>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default LoginWrapper;
