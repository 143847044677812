export const refundMethod = [
    { label: "Partial", value: "partial" },
    { label: "Full", value: "full"},
  ];


  export const PAUSE_SUBSCRIPTION_DAYS_OPTIONS = [
    { value: 7, label: "7 Days" },
    { value: 15, label: "15 Days" },
    { value: 30, label: "30 Days" },
  ];