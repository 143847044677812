import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../atoms/Button";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import styles from "./UserDetail.module.scss";
import { Close, ForwardToInbox, Refresh } from "@mui/icons-material";
import HeroSection from "../../organisms/heroSection/HeroSection";
import { useParams } from "react-router-dom";
import {
  addUser,
  disableAccount,
  disableUser,
  enableAccount,
  enableUser,
  resetPasswordEmail,
} from "./service";
import { fetchUserDetailList } from "./sagaAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoadingUserDetail,
  getUserDetailList,
  getUserListCount,
} from "./selector";
import AddUser from "../../organisms/addUser/AddUser";
import CustomModal from "../../molecules/customModal/CustomModal";
import CustomTable from "../../molecules/table/CustomTable";
import { columns } from "./constants";
import Trash from "../../assets/icons/trash";
import PlusCircle from "../../assets/icons/plusCircle";
import Loader from "../../atoms/Loader";
import {
  getLoadingOrganization,
  getOrganization,
  getOrganizationList,
} from "../organization/selector";
import { Tooltip } from "@mui/material";
import Check from "../../assets/icons/check";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { CustomError } from "../../models/customError.interface";
import { setUserDetailList } from "./reducer";
import Message from "../../assets/icons/message";
import { fetchOrganization } from "../organization/sagaAction";
import { sendActivityLog } from "../../organisms/auth/service";
import { getProfileInfo } from "../profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { PermissionListEnum } from "../rolesAndPermission/enum";

const UserDetail = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newUserloading, setNewUserLoading] = useState(false);
  const userDetail = useSelector((state) => getUserDetailList(state));
  const total = useSelector((state) => getUserListCount(state));
  const [detail, setDetail] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const tableLoading = useSelector((state) => getLoadingUserDetail(state));
  const orgDetail = useSelector((state) => getOrganization(state));
  console.log(orgDetail);
  const [showButton, setShowButton] = useState(false);
  const [emailReset, setEmailReset] = useState("");
  const [currentUser, setCurrentUser] = useState(-1);
  const handleDisableModal = () => {
    setShowDisableModal(!showDisableModal);
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleDisableAccount = async () => {
    setLoading(true);
    try {
      const response = await disableAccount(orgId);
      setLoading(false);
      setShowDisableModal(!showDisableModal);
      setShowButton(true);
      console.log(response?.data?.data?.deletedAt);
      const updatedList = userDetail.map((item: any) => {
        return { ...item, deletedAt: response?.data?.data?.deletedAt };
      });
      dispatch(setUserDetailList(updatedList));
      handleShowToast("Organisation disabled successfully!", ToastType.success);
      logAuditData(`User has disabled organisation ${orgId} and all users related to this org will automatically get disabled.`, "Disabled organisation")
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleEnableAccount = async () => {
    setLoading(true);
    try {
      await enableAccount(orgId);
      setLoading(false);
      setShowButton(false);
      handleShowToast("Organisation enabled successfully!", ToastType.success);
      logAuditData(`User has enabled Organisation ${orgId}`, "Enabled organisation")
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const logAuditData = async (data:any,logType:any) => {
    const attributes = {
      action: data ? `${data}` : `User is trying to disable organisation ${orgId}`,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType:logType ? `${logType}` : "User is trying to disable organisation",
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handleAddModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };

  const onSubmitDetail = async (data: any) => {
    setNewUserLoading(true);
    try {
      const response = await addUser(data, orgId);
      setNewUserLoading(false);
      setShowAddUserModal(false);
      handleShowToast("User added successfully!", ToastType.success);
      dispatch(setUserDetailList([...userDetail, response.data.result]));
      logAuditData(`New User is added to Organisation ${orgId}`, "New user added")
    } catch (error) {
      setNewUserLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleResetEmail = async (email: string) => {
    setEmailReset(email);
    try {
      await resetPasswordEmail(email);
      setEmailReset("");
      handleShowToast(
        "Reset password email sent successfully!",
        ToastType.success
      );
    } catch (error) {
      setEmailReset("");
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleRemoveUser = () => {
    setRemoveUserModal(!removeUserModal);
  };

  const handleDisableUser = async () => {
    setLoading(true);
    try {
      const response = await disableUser(currentUser);
      console.log(response);
      setLoading(false);
      const updatedList = userDetail.map((item: any) => {
        if (item.id === currentUser.toString()) {
          return { ...item, deletedAt: response?.data?.data?.deletedAt };
        }
        return item;
      });
      dispatch(setUserDetailList(updatedList));
      handleRemoveUser();
      handleShowToast("User disabled successfully!", ToastType.success);
      logAuditData(`User has disabled ${currentUser}`, "Disabled user")
      setCurrentUser(-1);
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleEnableUser = async (data: any) => {
    try {
      await enableUser(data);
      setLoading(false);
      const updatedList = userDetail.map((item: any) => {
        if (item.id === data.toString()) {
          return { ...item, deletedAt: null };
        }
        return item;
      });
      dispatch(setUserDetailList(updatedList));
      handleShowToast("User enabled successfully!", ToastType.success);
      logAuditData(`User has enabled ${data}`, "Enabled user")
      setCurrentUser(-1);
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  useEffect(() => {
    if (Object.keys(orgDetail).length > 0 && orgDetail?.deletedAt) {
      setShowButton(true);
    }
  }, [orgDetail]);

  useEffect(() => {
    dispatch(fetchUserDetailList(orgId));
    dispatch(fetchOrganization(orgId));
  }, [orgId]);
  
  useEffect(() => {
    if (!removeUserModal) {
      setCurrentUser(-1);
    }
  }, [removeUserModal]);

  const displayListData = useCallback(() => {
    const userListData = userDetail?.map((list: any) => {
      return {
        name: `${list?.nameFirst} ${list?.nameLast ? list?.nameLast : ""}`,
        email: list?.email,
        removeUser: (
          <div className="flex align-center gap-10">
            <a
              className={`flex icon button alignCenter ${styles.emilList}`}
              href={`mailto:${list.email}`}
            >
              <span className="button__icon">
                <Message />
              </span>
            </a>
            <Tooltip
              title={
                "By clicking on this user will get an email to reset password."
              }
            >
              <div>
              
             
              <Button
                key={list?.id}
                className="icon"
                label=""
                action={() => handleResetEmail(list.email)}
                prefix={<ForwardToInbox />}
                disabled={emailReset === list.email}
                clicked={emailReset === list.email}
              ></Button>
               </div>
            </Tooltip>
            <span className={`${styles.buttonSpace}`}>
              {userDetail?.length > 1 ? (
                list?.deletedAt || showButton ? (
                  <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER) ? "": "You don't have permission to access this."}>
                  <div>
                  <Button
                    key={list?.id}
                    className="green-light"
                    label="Enable"
                    action={() => {
                      setCurrentUser(list?.id);
                      handleEnableUser(list?.id);
                    }}
                    prefix={<Check />}
                    disabled={currentUser !=-1|| showButton || !profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER)}
                    clicked={currentUser === list?.id}
                    ></Button>
                    </div>
                  </Tooltip>
                ) : (
                <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER) ? "": "You don't have permission to access this."}>
                  <div>
                  <Button
                    key={list?.id}
                    className="red-light"
                    label="Disable"
                    disabled={!profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER)}
                    action={() => {
                      setCurrentUser(list?.id);
                      logAuditData(`User is trying to disable ${list?.email} of organisation ${orgId}`, "Disable user")
                      handleRemoveUser();
                    }}
                    prefix={<Close />}
                    ></Button>
                    </div>
                  </Tooltip>
                )
              ) : (
                ""
              )}
              {userDetail?.length === 1 && list?.deletedAt ? (
                <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER) ? "": "You don't have permission to access this."}>
                  <div>
                <Button
                  key={list?.id}
                  className="green-light"
                  label="Enable"
                  action={() => { 
                    setCurrentUser(list?.id);
                    handleEnableUser(list?.id);
                  }}
                  prefix={<Check />}
                  disabled={currentUser === list?.id || showButton || !profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER)}
                  clicked={currentUser === list?.id}
                  ></Button>
                  </div>
                  </Tooltip>
              ) : (
                ""
              )}
            </span>
          </div>
        ),
      };
    });
    setDetail(userListData ?? []);
  }, [userDetail, emailReset, currentUser, showButton,removeUserModal]);

  useEffect(() => {
    if (Object.keys(orgDetail).length > 0 && orgDetail?.deletedAt) {
      setShowButton(true);
    }
  }, [orgDetail]);

  useEffect(() => {
    dispatch(fetchUserDetailList(orgId));
    dispatch(fetchOrganization(orgId));
  }, [orgId]);

  useEffect(()=>{
    displayListData()
  },[userDetail, emailReset, currentUser, showButton,removeUserModal])

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "Organisation page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "Organisation page viewed",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo]);

  useEffect(()=>{
    if(!removeUserModal){
      setCurrentUser(-1)
    }
      },[removeUserModal])

  return (
    <>
      <HeroSection title="Organisation Detail">
        {tableLoading ? (
          <Button
            prefix={<Check />}
            label="Enable Organisation"
            disabled={true}
            clicked={true}
          ></Button>
        ) : (
          <Tooltip
            title={
              profileInfo?.permissions?.includes(PermissionListEnum.ADD_USER)
                ? ""
                : "You don't have permission to access this."
            }
          >
            <div>
              <Button
                prefix={<PlusCircle />}
                className="primary animated"
                label="Add user"
                action={handleAddModal}
                disabled={
                  showButton ||
                  !profileInfo?.permissions?.includes(
                    PermissionListEnum.ADD_USER
                  )
                }
              ></Button>
            </div>
          </Tooltip>
        )}
        <div className={`${styles.userDetail} flex justifyEnd`}>
          {tableLoading ? (
            <Button
              prefix={<Check />}
              label="Enable Organisation"
              disabled={true}
              clicked={true}
            ></Button>
          ) :
           showButton ? (
            <Tooltip
            title={
              profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER)
                ? ""
                : "You don't have permission to access this."}>
            <div>
              <Button
              prefix={<Check />}
              className="animated green-light"
              label="Enable Organisation"
              action={handleEnableAccount}
              disabled={loading ||
                !profileInfo?.permissions?.includes(
                  PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER
                )
              }
              clicked={loading}></Button>
            </div>
            </Tooltip>

          ) : (
            <Tooltip
            title={
              profileInfo?.permissions?.includes(PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER)
                ? ""
                : "You don't have permission to access this."}>
            <div>
            <Button
              prefix={<Close />}
              className="animated red-light"
              label="Disable Organisation"
              action={() => {
                logAuditData(null, null);
                handleDisableModal();
              }}
              disabled={!profileInfo?.permissions?.includes(
                PermissionListEnum.ENABLE_DISABLE_ORGANISATION_USER
              )}
            ></Button>
            </div>
            </Tooltip>
          )}
        </div>
      </HeroSection>

      {tableLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.userDetail} `}>
          <CustomTable
            rows={detail}
            columns={columns}
            search={""}
            tableStriped
            hasMore={detail?.length < total}
          ></CustomTable>

          <ConfirmationModal
            isOpen={removeUserModal ? removeUserModal : showDisableModal}
            setIsOpen={
              removeUserModal ? setRemoveUserModal : setShowDisableModal
            }
            buttonComponent={
              <Button
                label={"Yes, Disable"}
                className="red xl full"
                action={
                  removeUserModal ? handleDisableUser : handleDisableAccount
                }
                disabled={loading}
                clicked={loading}
              />
            }
          >
            <h2>{removeUserModal ? "Disable User" : "Disable Organisation"}</h2>
            <p>
              Are you sure you want to{" "}
              {removeUserModal
                ? "disable this user"
                : "disable this organisation"}
              ? <br />
              This action cannot be undone.
            </p>
          </ConfirmationModal>
        </div>
      )}

      <CustomModal
        modalTitle={"Add User"}
        handleClose={handleAddModal}
        open={showAddUserModal}
        customClass="dialogLarge"
      >
        <AddUser
          handleClose={handleAddModal}
          onSubmitDetail={onSubmitDetail}
          loading={newUserloading}
        />
      </CustomModal>
    </>
  );
};

export default UserDetail;
