import { useCallback, useEffect, useState } from "react";
import Button from "../../atoms/Button";
import CustomModal from "../../molecules/customModal/CustomModal";
import AddRole from "../../components/addRole/AddRole";
import { fetchPermissionList, fetchRolesList } from "./sagaAction";
import { useDispatch, useSelector } from "react-redux";
import { getRolesList, getPermissionList, getRolesListCount, getRolesListLoading, getPermissionListLoading } from "./selector";
import CustomTable from "../../molecules/table/CustomTable";
import { Columns } from "./constants";
import Trash from "../../assets/icons/trash";
import EditIcon from "../../assets/icons/editIcon";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import {
  addNewRole,
  addNewRolePermission,
  deleteRole,
  editRoleName,
} from "./service";
import { CustomError } from "../../models/customError.interface";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import { setRolesList, setRolesListCount } from "./reducer";
import HeroSection from "../../organisms/heroSection/HeroSection";
import styles from "./RolesAndPermission.module.scss";
import Loader from "../../atoms/Loader";
import { RoleData, RowData, TableHeader } from "./interface";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { getProfileInfo } from "../profile/selector"; 
import { sendActivityLog } from "../../organisms/auth/service";
import { capitalizeFirstLetter } from "../../utils/utils";
import { Tooltip } from "@mui/material";
import { PermissionListEnum } from "./enum";

const RolesAndPermission = () => {
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const rolesList = useSelector((state) => getRolesList(state));
  const permissionList = useSelector((state) => getPermissionList(state));
  const rolesListLoading = useSelector((state) => getRolesListLoading(state));
  const permissionListLoading = useSelector((state) => getPermissionListLoading(state));
  const roleListCount = useSelector((state) => getRolesListCount(state));
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [roleData, setRoleData] = useState<RoleData | null>(null);
  const [rolesPermission, setRolesPermission] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openAddRoleModal, setOpenAddRoleModal] = useState<boolean>(false);
  const [tableHeader, setTableHeader] = useState<TableHeader[]>([
    {
      id: "",
      label: "",
      minWidth: 10,
      type: "",
    },
  ]);
  const [filter, setFilter] = useState<{
    search: string;
    size: number;
    index: number;
  }>({
    search: "",
    size: 10,
    index: 1,
  });
  const handleOpenAddRoleModal = async() => {
    setOpenAddRoleModal(true);
  };

  const logRoleData = async (role:any) => {
    const attributes = {
      action:role ? "Update role button clicked" :"Add role button clicked",
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      try {
        const res = await sendActivityLog({
          logType: role? "Update role button clicked": "Add role button clicked",
          jsonData: result,
        });
      } catch (error) {
        console.error("Error sending activity log:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
};

  const handleClose = () => {
    setOpenAddRoleModal(false);
    setRoleData(null);
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await addNewRole({ roleName: data?.roleName });
      try {
        const res = await addNewRolePermission({
          roleId: response?.data?.id,
          permissionIds: data?.permissions,
        });
        handleShowToast("Role added successfully!", ToastType.success);
        dispatch(setRolesList([...rolesList, res?.data?.data]));
        setLoading(false);

        const attributes = {
          action: `${data?.roleName} role added successfully`,
          userId: profileInfo?.id,
          uuid: "99",
          email: profileInfo?.email,
          roleName: profileInfo?.roleName,
        };
        try {
          const result = await myAuditLogs(attributes);
          try {
            const res = await sendActivityLog({
              logType: `${data?.roleName} role added successfully`,
              jsonData: result,
            });
          } catch (error) {
            console.error(error);
          }
        } catch (error) {
          console.error("Error logging audit data:", error);
        }

        handleClose();
      } catch (err) {
        setLoading(false);
        console.log(err);
        handleShowToast(
          (err as CustomError)?.response?.data?.message ||
            "Something went wrong!",
          ToastType.error
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
  };

  const arraysEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const arr2Copy = [...arr2];
    for (let i = 0; i < arr1.length; i++) {
      const index = arr2Copy.indexOf(arr1[i]);
      if (index === -1) {
        return false;
      }
      arr2Copy.splice(index, 1);
    }

    return true;
  };

  const onUpdateSubmit = async (data: any) => {
    setLoading(true);
    if (roleData && roleData.roleName !== data.roleName) {
      try {
        await editRoleName(roleData?.id, { roleName: data.roleName });
        handleShowToast("Role name updated successfully!", ToastType.success);
        const updateRoleName = rolesList.map((el: any) => {
          if (el.id === roleData?.id) {
            return { ...el, roleName: data.roleName };
          }
          return el;
        });
        dispatch(setRolesList(updateRoleName));
        setLoading(false);

        const attributes = {
          action: `Role name changed from ${roleData.roleName} to ${data.roleName} successfully.`,
          userId: profileInfo?.id,
          uuid: "99",
          email: profileInfo?.email,
          roleName: profileInfo?.roleName,
        };
        try {
          const result = await myAuditLogs(attributes);
          try {
            const res = await sendActivityLog({
              logType: `Role name changed from ${roleData.roleName} to ${data.roleName} successfully.`,
              jsonData: result,
            });
          } catch (error) {
            console.error(error);
          }
        } catch (error) {
          console.error("Error logging audit data:", error);
        }

        handleClose();
      } catch (err) {
        setLoading(false);
        handleShowToast(
          (err as CustomError)?.response?.data?.message ||
            "Something went wrong!",
          ToastType.error
        );
      }
    }
    if (!arraysEqual(rolesPermission, data?.permissions)) {
      try {
        const response = await addNewRolePermission({
          roleId: roleData?.id,
          permissionIds: data?.permissions,
        });
        console.log(response);
        const updateRolePermission = rolesList.map((el: any) => {
          if (el.id === roleData?.id) {
            return { ...response?.data?.data };
          }
          return el;
        });
        console.log(updateRolePermission);
        dispatch(setRolesList(updateRolePermission));
        const attributes = {
          action: `Permission updated for ${data.roleName} role successfully.`,
          userId: profileInfo?.id,
          uuid: "99",
          email: profileInfo?.email,
          roleName: profileInfo?.roleName,
        };
        try {
          const result = await myAuditLogs(attributes);
          try {
            const res = await sendActivityLog({
              logType: `Permission updated for ${data.roleName} role successfully.`,
              jsonData: result,
            });
          } catch (error) {
            console.error(error);
          }
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
        handleShowToast("Permission updated successfully!", ToastType.success);
        setLoading(false);
        handleClose();
      } catch (err) {
        setLoading(false);
        handleShowToast(
          (err as CustomError)?.response?.data?.message ||
            "Something went wrong!",
          ToastType.error
        );
      }
    }
    if(arraysEqual(rolesPermission, data?.permissions) && roleData?.roleName === data.roleName){
      setLoading(false);
      handleClose();
    }
    setRoleData(null);
    setRolesPermission([]);
  };

  const handleDeleteRoleClick = async (data: any) => {
    setLoading(true);
    try {
      await deleteRole(roleData?.id);
      handleShowToast("Role deleted successfully!", ToastType.success);
      const attributes = {
        action: `${roleData?.roleName} deleted successfully.`,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        try {
          const res = await sendActivityLog({
            logType: `${roleData?.roleName} deleted successfully.`,
            jsonData: result,
          });
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }

      const updatedlist = rolesList.filter((el: any) => el.id !== roleData?.id);
      dispatch(setRolesList(updatedlist));
      dispatch(setRolesListCount(roleListCount - 1));
      setLoading(false);
      setShowDeleteModal(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      handleShowToast(
        (err as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  const handleTable = useCallback(() => {
    const updatedRole = rolesList.map((role: any) => {
      const rolePermissions = role.rolePermissionMap.map(
        (rp: any) => rp.permission.id
      );
      const roleData: RowData = {
        roleName: role.roleName?.split("_")
        .map((word: any) => capitalizeFirstLetter(word))
        .join(" "),
        editrole: (
          <div className="flex gap-10 alignCenter">
            {role.roleName !== "super_admin"?
            <>
            <Tooltip title={!profileInfo?.permissions?.includes(PermissionListEnum.ADD_UPDATE_ROLE) ? "You don't have permission to access this." : ''}>
            <div>
            <span
              className={`inlineFlex alignCenter button outline icon sm ${!profileInfo?.permissions?.includes(PermissionListEnum.ADD_UPDATE_ROLE) ? "disable": ""}`}
              onClick={() => {
                setRoleData(role);
                setRolesPermission(rolePermissions);
                logRoleData(role)
                handleOpenAddRoleModal();
              }}
              >
              <EditIcon />   
            </span>
                </div>
            </Tooltip>
            <Tooltip title={!profileInfo?.permissions?.includes(PermissionListEnum.DELETE_ROLE) ? "You don't have permission to access this." : ''}>
            <div>
            <span
              className={`inlineFlex alignCenter button outline icon sm ${!profileInfo?.permissions?.includes(PermissionListEnum.DELETE_ROLE) ? "disable": ""}`}
              onClick={async(e: any) => {
                setRoleData(role);
                setShowDeleteModal(true);
                const attributes = {
                  action: `User is trying to delete ${role?.roleName} role. `,
                  userId: profileInfo?.id,
                  uuid: "99",
                  email: profileInfo?.email,
                  roleName: profileInfo?.roleName,
                };
                try {
                  const result = await myAuditLogs(attributes);
                  try {
                    const res = await sendActivityLog({
                      logType: "Delete role button clicked",
                      jsonData: result,
                    });
                  } catch (error) {
                    console.error(error);
                  }
                } catch (error) {
                  console.error("Error logging audit data:", error);
                }
              }}
            >
              <Trash />
            </span> 
            </div>
            </Tooltip>
            </>
            :''}
          </div>
        ),
      };
      tableHeader.forEach((header: TableHeader) => {
        roleData[header.label] = rolePermissions.includes(header.value);
      });
      return roleData;
    });
    setRowData(updatedRole);
  }, [rolesList, tableHeader]);
  
  useEffect(()=>{
    if(!permissionListLoading && !rolesListLoading){
    setTableLoading(false)
  }
  },[permissionListLoading,rolesListLoading])

  useEffect(() => {
    dispatch(fetchRolesList(filter));
  }, [filter]);

  useEffect(() => {
    dispatch(fetchPermissionList(filter));
    setTableLoading(true)
  }, []);

  useEffect(() => {
   if(!showDeleteModal){
    setRoleData(null);
   }
  }, [showDeleteModal]);

  useEffect(() => {
    const updatedHeader = permissionList.map((permission: any) => ({
      id: permission.permissionName,
      label: permission.permissionName,
      minWidth: 10,
      type: "permission",
      value: permission.id,
    }));

    updatedHeader.unshift({
      id: "roleName",
      label: "Role Name",
      minWidth: 10,
      type: "Phone",
    });
    updatedHeader.push(...Columns);
    setTableHeader(updatedHeader);
  }, [permissionList]);

  useEffect(() => {
    handleTable();
  }, [rolesList, tableHeader]);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "Roles and permission page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo.email,
        roleName: profileInfo.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "Roles and permission page",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
   logAuditData();
  }, [profileInfo]);
  return (
    <>
          <div className={`${styles.roles}`}>
            <HeroSection
              title="Roles and Permissions"
              desc="Add, edit or delete user roles and permissions."
            >
              <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.ADD_UPDATE_ROLE) ? "": "You don't have permission to access this."}>
              <div>
              <Button
                className="primary"
                label="Add Role"
                action={()=>{
                  logRoleData(null)
                  handleOpenAddRoleModal()
                }}
                disabled={!profileInfo?.permissions?.includes(PermissionListEnum.ADD_UPDATE_ROLE)}
                ></Button>
                </div>
              </Tooltip>
            </HeroSection>
            <CustomModal
              modalTitle={roleData ? "Update Role" : "Add Role"}
              handleClose={handleClose}
              open={openAddRoleModal}
              customClass="dialogLarge"
            >
              <AddRole
                handleClose={handleClose}
                roleData={roleData}
                onSubmit={onSubmit}
                loading={loading}
                onUpdateSubmit={onUpdateSubmit}
              />
            </CustomModal>
            <div className={` ${styles.roles__table}`}>
              {tableLoading ? (
                <div className="table-loader">
                  <Loader></Loader>
                </div>
              ) : (
                <CustomTable
                  rows={rowData}
                  columns={tableHeader}
                  search={""}
                  tableStriped
                  hasMore={rowData?.length < roleListCount}
                  handleNextPage={handleNextPage}
                  lastColSticky
                ></CustomTable>
              )}
            </div>
            <ConfirmationModal
              isOpen={showDeleteModal}
              setIsOpen={setShowDeleteModal}
              buttonComponent={
                <Button
                  label="Yes, Delete"
                  className="red xl full "
                  action={handleDeleteRoleClick}
                  disabled={loading}
                  clicked={loading}
                />
              }
            >
              <h2>Delete Role</h2>
              <p>
                Are you sure you want to delete this role? <br />
                This action cannot be undone.
              </p>
            </ConfirmationModal>
          </div>
        </>
  );
};

export default RolesAndPermission;
