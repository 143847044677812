import { Dialog } from "@mui/material";
import style from "./CancelSubscription.module.scss";
import Close from "../../assets/icons/close";
import Alert from "../../assets/icons/alert";
import Button from "../../atoms/Button";
import InfoOutlineSolid from "../../assets/icons/infoOutlineSolid";
import AlertTriangle from "../../assets/icons/alertTriangle";
import { ProductType } from "./enum";

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { fetchOrganizationSubscription } from "../../pages/organization/sagaAction";
import { cancelSubscription } from "./service";
import { getProfileInfo } from "../../pages/profile/selector";
import { sendActivityLog } from "../auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";

const CancelSubscription = ({
  handleClose,
  open,
  subscription,
  displayErrorMessage,
  displaySuccessMessage,
  closeManageSubscription,
}: any) => {
  console.log(subscription)
  const dispatch = useDispatch();
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const handleCancelNow = async () => {
    try {
      setLoading(true);

      const payload = {
        subscriptionId: subscription?.stripeSubscriptionId,
        subsProductType: subscription?.productType,
      };
      await cancelSubscription(payload, param?.orgId);
      setTimeout(() => {
        setLoading(false);
        displaySuccessMessage("Subscription cancelled!");
        logAuditData(subscription?.productType);
        if (subscription?.productType === ProductType.GENERAL_ACCESS) {
          closeManageSubscription && closeManageSubscription();
        }
        dispatch(fetchOrganizationSubscription(param?.orgId));
        handleClose();
      }, 3000);
    } catch (error) {
      displayErrorMessage(error);
      setLoading(false);
    }
  };

  const logAuditData = async (type:any) => {
    const attributes = {
      action: `User has cancelled ${type} subscription of organisation ${param.orgId}`,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo?.email,
      roleName: profileInfo?.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      try {
        const res = await sendActivityLog({
          logType: `User has cancelled ${type} subscription`,
          jsonData: result,
        });

      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };
  
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog welcomeDialog cancelSubscription`}
      >
        {ProductType.GENERAL_ACCESS === subscription?.productType ? (
          <div className={style.cancel}>
            <div className={`${style.cancel__header} flex justifySpaceBetween`}>
              <InfoOutlineSolid />
              <span
                role="link"
                className="closeDialog"
                onClick={() => !loading && handleClose()}
              >
                <Close />
              </span>
            </div>
            <h4>Are you sure you want to do this?</h4>

            <div className={`${style.cancel__item} flex alignCenter`}>
              <AlertTriangle />
              <aside>
                <strong>All of the user's data will be permanently lost.</strong>
                <p>This includes leads, lists, campaigns, and more.</p>
              </aside>
            </div>

            <div className={`${style.cancel__item} flex alignCenter`}>
              <AlertTriangle />
              <aside>
                <strong>
                  All of the user's add-on subscriptions will be ended immediately.
                </strong>
                <p>This includes any active AI calling subscription.</p>
              </aside>
            </div>

            <div className={style.cancel__text}>
              <p>
                The cancellation is effective <strong>immediately</strong> and
                this action cannot be undone.
              </p>
            </div>
            <div className={`${style.cancel__button} flex`}>
              <Button
                label="Keep User's Plan"
                className="button primary xl animated"
                action={handleClose}
              />
              <Button
                label="End Subscription Now"
                className="outline xl outline-red"
                action={handleCancelNow}
                clicked={loading}
                disabled={loading}
              />
            </div>
          </div>
        ) : (
          <div className={style.cancel}>
            <div className={`${style.cancel__header} flex justifySpaceBetween`}>
              <Alert />
              <span
                role="link"
                className="closeDialog"
                onClick={() => !loading && handleClose()}
              >
                <Close />
              </span>
            </div>
            <h4>
              Are you sure? User will <u>lose</u> all AI Calling credits!
            </h4>
            <div className={style.cancel__text}>
              <p>
              Canceling the subscription before it renews will result in all
                of the user's remaining credits being{" "}
                <strong>permanently deleted</strong> and the user will lose access to starting new campaigns.
              </p>
              <p>
              The cancellation is effective<strong> immediately</strong>.
              </p>
            </div>
            <div className={`${style.cancel__button} flex`}>
              <Button
                label="Keep User's Plan"
                action={handleClose}
                className="button primary xl animated"
              />
              <Button
                label="Cancel Now"
                className="outline xl outline-red"
                action={handleCancelNow}
                clicked={loading}
                disabled={loading}
              />
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};
export default CancelSubscription;
