import logo from "../assets/images/logo.png";

const Logo = () => {
  return (
    <>
    <span className="inlineFlex logo-main">
    <img src={logo} alt="logo" />
    </span>
    
    </>
  );
};

export default Logo;
