import { initialState } from "./reducer";

const getStateData = (state: any) => state["organization"] || initialState;

export const getOrganizationList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.organizationList;
}


export const getLoadingOrganizationList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingOrganizationList;
};

export const getOrganizationListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.organizationListCount;
};

export const getOrganization=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.organization;
}

export const getLoadingOrganization= (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingOrganization;
};

export const getOrgSubscription=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.orgSubscription;
}

export const getLoadingOrgSubscription= (state: any) => {
  const reducer = getStateData(state);
  return reducer.loadingOrgSubscription;
};

export const getTotalAvailableCredits = (state: any) => {
  const reducer = getStateData(state);
  return reducer.totalAvailableCredits;
};
