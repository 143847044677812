export const getPillStatus = (status: string) => {
    switch (status?.toLowerCase()) {
      case "closed deal":
      case "new lead":
      case "paid":
      case "calling":
      case "delivering": {
        return "calling";
      }
  
      case "make offer":
      case "completed": {
        return "completed";
      }
      case "refund":
      case "scheduled": {
        return "scheduled";
      }
  
      case "processing": {
        return "processing";
      }
      case "off": {
        return "off";
      }
      case "follow up": {
        return "blue-dark";
      }
      case "contract send": {
        return "blue-bright";
      }
      case "in contract": {
        return "blue-light";
      }
      case "reset": {
          return "reset";
        }
      case "failed":
      case "cancelled":
      case "dead deal": {
        return "red";
      }
    }
  };
  