import { postAPI } from "../../apis/apis";

export const handleBot = async (payload: any) => {
  return postAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/bot-emergency-stop`,
    headers: {},
    data: payload,
  });
};

export const handleIndividualWorkerBot = async (payload: any) => {
  return postAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/bot-worker-emergency-stop`,
    headers: {},
    data: payload,
  });
};