import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import DropDown from "../../atoms/Dropdown";
import { PAUSE_SUBSCRIPTION_DAYS_OPTIONS } from "./constants";
import { useForm } from "react-hook-form";
import Close from "../../assets/icons/cross";
import Button from "../../atoms/Button";
import { pauseSubscription } from "./service";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomError } from "../../models/customError.interface";
import { setOrgSubscription } from "../../pages/organization/reducer";
import { useParams } from "react-router-dom";
import InfoOutlineSolid from "../../assets/icons/infoOutlineSolid";
import { addDaysToDate } from "../../utils/utils";
import CheckCircle from "../../assets/icons/checkCircle";
import Zap from "../../assets/icons/zap";
import Check from "../../assets/icons/check";
import { getOrgSubscription } from "../../pages/organization/selector";
import styles from "./PauseSubscription.module.scss";
import Refresh from "../../assets/icons/refresh";

const PauseSubscription = ({
  handleClose,
  open,
  setPauseModal,
  subscriptions,
  setConfirmPauseModal,
  confirmPauseModal,
  creditUsage
}: any) => {
  console.log("creditUsage",creditUsage)
  const param = useParams();
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      pauseDays: PAUSE_SUBSCRIPTION_DAYS_OPTIONS[0]?.value,
    },
  });

  const [loading, setLoading] = useState(false);
  const pauseDay= watch("pauseDays")
  const dispatch = useDispatch();
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleOnFocus = (e: any) => (e.target.readOnly = true);

  const handlePause = async () => {
    try {
      setLoading(true);
      const payload={
        stripeSubscriptionId: subscriptions[1]?.stripeSubscriptionId,
        daysCount: watch("pauseDays"),
      }
      await pauseSubscription(payload,param.orgId);
      setLoading(false);
      handleShowToast("Subscription paused successfully!", ToastType.success);
      dispatch(setOrgSubscription({
        ...subscriptions,
        [1]: {
          ...subscriptions[1],
          isPaused: 1
        }
      }));
      handleClose()
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  useEffect(() => {
    if (!open) {
      setValue("pauseDays", PAUSE_SUBSCRIPTION_DAYS_OPTIONS[0].value);
    }
  }, [open]);

  return (
    <>
      <Dialog 
       className={`stepsDialog dialogLarge ${confirmPauseModal ? `` : `showPurchaseModal`}`}
      onClose={handleClose} open={open}>
     { confirmPauseModal ? 
        <div className="dialogWrapper">
        <span role="link" onClick={handleClose} className="closeDialog">
          <Close />
        </span>
        <div className="dialogWrapper__header">
        <InfoOutlineSolid />
        <h2>Are you sure? The user won't be able to create new campaigns!</h2>
        </div>
        <div className="dialogWrapper__info">
        <p>
        Pausing will freeze the user's billing cycle days and access to launching new campaigns, but credits will remain intact.</p>
         <h3>How it works</h3> 
         <ul>
         <li>For example, if a user has 5 days left in their billing cycle and pauses for 7 days, they will regain those 5 days and any remaining credit balance once the pause ends.
         </li>
         <li> Credits reset at the user's next billing cycle.</li>
         </ul>
         
         <h3> Important</h3>
         <ul>
         <li> Only one pause per billing cycle.</li>
         <li> No consecutive pauses without renewal.</li>
         <li> Your pause is effective immediately. </li>
          </ul>
        
       <p>Have questions? Call/text (877)343-7844</p>
        <DropDown
          options={PAUSE_SUBSCRIPTION_DAYS_OPTIONS}
          label=""
          control={control}
          name="pauseDays"
          errors={errors}
          handleOnFocus={(e: any) => handleOnFocus(e)}
        ></DropDown>
        </div>
     
        <div className="mainCol actions">
          <div className="mainCol__half">
            <Button
              label="Keep User's Plan"
              className=" primary full md"
              disabled={loading}
              action={handleClose}
            />
          </div>
          <div className="mainCol__half">
            <Button
              label="Pause Now"
              className="full animated outline  md"
              action={() => {
                setConfirmPauseModal(false)
              }}
              clicked={loading}
              disabled={loading}
            />
          </div>
        </div>
      </div>
      :<div className="dialogWrapper">
      <span role="link" onClick={handleClose} className="closeDialog">
        <Close />
      </span>
      <div className={`${styles.confirm}`}>
      <div className={`${styles.headerIcon}`}>
      <span className={`${styles.flagBorder}`}>  <Check /></span>
        </div>
        <div className={`${styles.confirm__header}`}>
      <h2>Let's confirm the user's pause request.</h2>
      <p>The following are important details to review.</p>
      </div>
      <div className={`${styles.confirm__info}`}>
        <ul>
            <li>
                <figure>
                    <Zap />
                </figure>
                <figcaption>
                <p>
          
                User's plan will be pause for <strong>{watch("pauseDays")} days</strong>  and reactivate on <strong>{addDaysToDate(pauseDay)}.</strong>
        </p>
       
                </figcaption>
            </li>
            <li>
                <figure>
                    <Refresh />
                </figure>
                <figcaption>

                <p>The user's existing credit balance of <strong>{creditUsage?.availableCredit}</strong>  will remain available for use until <strong>{addDaysToDate(pauseDay)}</strong> before refreshing on the user's next billing cycle.</p>
                </figcaption>
            </li>
            <li>
                <figure>
                    <CheckCircle />
                </figure>
                <figcaption>
                <p>User's next billing date is <strong>{addDaysToDate(pauseDay ,subscriptions[1]?.expiresAt)}.</strong></p>
                </figcaption>
            </li>
        </ul>
     </div>
     <div className="mainCol actions">
        <div className="mainCol__half">
          <Button
            label="Go Back"
            className=" primary full md"
            action={() => {
              setConfirmPauseModal(true)
            }}
            disabled={loading}

          />
        </div>
        <div className="mainCol__half">
          <Button
            label="Confirm Pause"
            className="full animated outline  md"
            action={() => {
              handlePause();
            }}
            clicked={loading}
            disabled={loading}
          />
        </div>
      </div>
      </div>


    </div>  
      }
      </Dialog>
    </>
  );
};

export default PauseSubscription;
