import { getAPI } from "../../apis/apis";

export const fetchExportCSVList = (payload:any) => {
    const {id,orgId}=payload
    return getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/campaign/lead-by-listId/${id}/${orgId}`,
      headers: {},
    });
  };