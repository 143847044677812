import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const LogOutWrapper = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useSelector((state: any) => state.authGuard);

  if (auth) {
    return <>{<Navigate to="/organisation" />}</>;
  } else {
    return <>{children}</>;
  }
};

export default LogOutWrapper;
