import React, { useEffect } from "react";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../atoms/Input";
import { registerFormSchema } from "./validations";
import MaskedInput from "../../atoms/MaskedInput";
import { getProfileInfo } from "../../pages/profile/selector";
import { useSelector } from "react-redux";
import { sendActivityLog } from "../auth/service";
import { useParams } from "react-router-dom";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";


const AddUser = ({ handleClose, onSubmitDetail, loading }: any) => {
  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registerFormSchema),
  });
  const { orgId } = useParams();
  
  const profileInfo = useSelector((state) => getProfileInfo(state));

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: `${profileInfo?.nameFirst + " " +profileInfo?.nameLast} is trying to add new user to organisation ${orgId}`,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: `Trying to add new user to organisation ${orgId}`,
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo]);
  return (
    <>
      <Input
        type="text"
        label="Name*"
        register={register}
        name="name"
        placeholder="Enter your name"
        errors={errors}
      ></Input>
      <Input
        type="email"
        label="Email*"
        register={register}
        name="email"
        placeholder="Enter your email"
        errors={errors}
      ></Input>
      <MaskedInput
        placeholder="Enter your phone number"
        label="Phone*"
        name="phone"
        register={register}
        errors={errors}
        mask={"999-999-9999"}
      ></MaskedInput>
      <Input
        type="password"
        label="Password*"
        register={register}
        name="password"
        placeholder="Create a password"
        errors={errors}
      ></Input>

      <div className="authStyle__footer">
        <Button
          label="Submit"
          className="primary full xl"
          action={handleSubmit(onSubmitDetail)}
          clicked={loading}
          disabled={loading}
        ></Button>
      </div>
    </>
  );
};

export default AddUser;
