import { useSelector } from "react-redux";
import InfoCard from "../../../../components/infoCard/InfoCard";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import { formartNumberAsLocalString } from "../../../../utils/utils";
import styles from "./PropertyDetailTab.module.scss";
import { getPropertyDetailsData } from "../../selector";
const SectionItem = ({ title, value }: { title: string; value: string }) => {
  if (typeof value === "boolean") {
    value = value ? "Yes" : "No";
  }

  if (typeof value === "number") {
    value = formartNumberAsLocalString(value);
  }

  value = Boolean(value) ? value : "-";
  return (
    <div className={`${styles.row__col}`}>
      <InfoCard title={title} description={value} />
    </div>
  );
};

const PropertyDetailTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const { ownerInfo, propertyInfo, lotInfo,
    neighborhood 
  } =
    propertyDetails.jsonData;

  const sections = {
    "Ownership Information": [
      {
        title: "Owner 1 Name",
        value: ownerInfo?.owner1FullName,
      },
      {
        title: "Owner 2 Name",
        value: ownerInfo?.owner2FullName,
      },
      {
        title: "Length of Ownership",
        value:ownerInfo?.ownershipLength && ownerInfo?.ownershipLength!=0 ? 
        (ownerInfo?.ownershipLength/12)>=1?Math.round(ownerInfo?.ownershipLength/12) + ` ${Math.round(ownerInfo?.ownershipLength/12)>1?'Years':'Year'}`  :
        ownerInfo?.ownershipLength + ` ${ownerInfo?.ownershipLength>1?'Months':'Month'}` 
        : "",
      },
      {
        title: "Mailing Address",
        value: ownerInfo?.mailAddress?.label,
      },
    ],
    "Property Information": [
      {
        title: "Bedrooms",
        value: propertyInfo?.bedrooms ? propertyInfo?.bedrooms : "",
      },
      {
        title: "Full Bathrooms",
        value: propertyInfo?.bathrooms ? propertyInfo?.bathrooms : "",
      },
      {
        title: "Partial Bathrooms",
        value: propertyInfo?.partialBathrooms ? propertyInfo?.partialBathrooms : "",
      },
      {
        title: "Living Area",
        value: propertyInfo?.livingSquareFeet
          ? formartNumberAsLocalString(propertyInfo?.livingSquareFeet) +
            " SqFt."
          : "",
      },
      {
        title: "Stories",
        value: propertyInfo?.stories ? propertyInfo?.stories : "",
      },
      {
        title: "Property Use",
        value: propertyInfo?.propertyUse ,
      },
      {
        title: "Residential Units",
        value: propertyInfo?.unitsCount !=0 ? propertyInfo?.unitsCount : "",
      },
      {
        title: "Basement",
        value: propertyInfo?.basementType,
      },
      {
        title: "Basement Size",
        value: propertyInfo?.basementSquareFeet
          ? formartNumberAsLocalString(propertyInfo?.basementSquareFeet) +
            " SqFt."
          : "",
      },
      {
        title: "Parking Spaces",
        value: propertyInfo?.parkingSpaces ? propertyInfo?.parkingSpaces : "",
      },
      {
        title: "Fireplaces",
        value: propertyInfo?.fireplaces ? propertyInfo?.fireplaces : "",
      },
      {
        title: "Air Conditioning",
        value: propertyInfo?.airConditioningType,
      },
      {
        title: "Heating",
        value: propertyInfo?.heatingType,
      },
      {
        title: "Heating Fuel",
        value: propertyInfo?.heatingFuelType,
      },
      {
        title: "Water Source",
        value: propertyInfo?.utilitiesWaterSource,
      },
      {
        title: "Garage",
        value: propertyInfo?.garageType,
      },
      {
        title: "Garage Size",
        value: propertyInfo?.garageSquareFeet && propertyInfo?.garageSquareFeet!=0
          ? formartNumberAsLocalString(propertyInfo?.garageSquareFeet) +
            " SqFt."
          : "",
      },
      {
        title: "Carport",
        value: propertyInfo?.carport,
      },
      {
        title: "Porch",
        value: propertyInfo?.porchArea && propertyInfo?.porchArea!=0 ? propertyInfo?.porchArea  + " sqft" : "",
      },
      {
        title: "Patio",
        value: propertyInfo?.patioArea && propertyInfo?.patioArea!=0 ?  propertyInfo?.patioArea   + " sqft" : "",
      },
      {
        title: "Pool",
        value: propertyInfo?.pool,
      },
    ],
    "Land/Location Information": [
      {
        title: "Lot Size",
        value: lotInfo?.lotAcres && lotInfo?.lotAcres !=0
          ? formartNumberAsLocalString(lotInfo?.lotAcres) + " acres"
          : "",
      },
      {
        title: "Lot Area",
        value: lotInfo?.lotSquareFeet && lotInfo?.lotSquareFeet !=0
          ? formartNumberAsLocalString(lotInfo?.lotSquareFeet) + " sqft"
          : "",
      },
      {
        title: "Property Class",
        value: lotInfo?.landUse,
      },
      {
        title: "APN",
        value: lotInfo?.apn,
      },
      {
        title: "Zoning",
        value: lotInfo?.zoning,
      },
      {
        title: "Census Tract",
        value: lotInfo?.censusTract,
      },
      {
        title: "Block",
        value: lotInfo?.censusBlock,
      },
      {
        title: "Lot Number",
        value: lotInfo?.lotNumber ? lotInfo?.lotNumber : "",
      },
      {
        title: "Neighborhood Name",
        value: neighborhood?.name,
      },
      {
        title: "Neighborhood Type",
        value: neighborhood?.type,
      },
      {
        title: "Legal Description",
        value: lotInfo?.legalDescription,
      },
    ],
  };

  return (
    <div className={` ${styles.propertyDetails}`}>
      <div className="wrapper">
        {Object.entries(sections).map(([key, value]) => (
          <ShadowBox title={key} key={key}>
            <div className={`dflex ${styles.row}`}>
              {value.map(({ title, value }) => (
                <SectionItem title={title} value={value? value.toString() : ''} key={title} />
              ))}
            </div>
          </ShadowBox>
        ))}
      </div>
    </div>
  );
};
export default PropertyDetailTab;
