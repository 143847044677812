import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
 userDetailList: [] as any[],
 loadingUserDetailList: false,
 userListCount:0,
};

const userDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    setUserDetailList: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        userDetailList: payload,
      };
    },
    setUserListCount: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        userListCount: payload,
      };
    },
    setLoadingUserDetailList: (state, { payload }) => {
      return {
        ...state,
        loadingUserDetailList: payload,
      };
    },
  },
});

export const { setUserDetailList, setLoadingUserDetailList,setUserListCount} =
userDetailSlice.actions;

export default userDetailSlice.reducer;
