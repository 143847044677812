import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Menu from "../../assets/icons/menu";
import styles from "./ToggleMenu.module.scss";
import { ToggleMenuProps } from "./interface";
import SideBar from "../../components/sideBar/SideBar";
import MobileMenuDrawerContext from "../../context/MobileMenuDrawerContext";

type Anchor = "top" | "left" | "bottom" | "right";

export default function ToggleMenu({
  blackFixed,
}: ToggleMenuProps) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const MobileMenuDrawerContextValue = React.useMemo(
    () => ({
      closeDrawer: toggleDrawer("left", false),
    }),
    []
  );

  return (
    <>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            className={`${ styles.toggleMenuWhite}`}
            onClick={toggleDrawer("left", true)}
          >
         <span role="link"><Menu /></span> 
          </div>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <MobileMenuDrawerContext.Provider
              value={MobileMenuDrawerContextValue}
            >
              <div className={`${styles.toggleMenu__inner}`}>
                <div>
                 <SideBar/>
                </div>
              </div>
            </MobileMenuDrawerContext.Provider>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  );
}
