import { initialState } from "./reducer";

const getStateData = (state: any) => state["leadList"] || initialState;

export const getLeadsList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadList;
};

export const getLeadListCount=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.leadListCount
}

export const getLeadListLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadListLoading;
};

export const getPropertyDetailsData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.propertyDetailsData;
};

export const isLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.isLoading;
};

export const getWholesaleCalculatorList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.wholesaleCalculatorlist;
};

export const getWholesaleCalculatorloading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.wholesaleCalculatorLoading;
};

export const getamortizationCalculatorList = (state: any) => {
  const reducer = getStateData(state);
  return reducer.amortizationCalculatorlist;
};

export const getamortizationCalculatorloading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.amortizationCalculatorLoading;
}

export const getFinanceCalculatorlist = (state: any) => {
  const reducer = getStateData(state);
  return reducer.financeCalculatorlist;
};

export const getFinanceCalculatorDetail = (state: any) => {
  const reducer = getStateData(state);
  return reducer.financeCalculatorDetail;
};
export const getFinanceCalculatorLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.financeCalculatorLoading;
}