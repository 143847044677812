import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import InviteModal from "../../components/inviteModal/InviteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveUsersList,
  fetchPendingUsersList,
  
} from "./sagaAction";
import { UsersHeader } from "../../components/usersHeader/UsersHeader";
import HeroSection from "../../organisms/heroSection/HeroSection";
import PlusCircle from "../../assets/icons/plusCircle";
import { getProfileInfo } from "../profile/selector";
import { sendActivityLog } from "../../organisms/auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { PermissionListEnum } from "../rolesAndPermission/enum";
import { Tooltip } from "@mui/material";

const Users = () => {
  const dispatch = useDispatch();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [filter, setFilter] = useState<{
    search: string;
    size: number;
    index: number;
  }>({
    search: "",
    size: 10,
    index: 1,
  });
  const handleInviteModal = async() => {
    setShowInviteModal(!showInviteModal);
    const attributes = {
      action: "Invite button clicked",
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo?.email,
      roleName: profileInfo?.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      try {
        const res = await sendActivityLog({
          logType:  "Invite button clicked",
          jsonData: result,
        });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  useEffect(() => {
    dispatch(fetchActiveUsersList({}));
  }, []);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "Users page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo.email,
        roleName: profileInfo.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "Users page",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };

    logAuditData();
  }, [profileInfo]);

  return (
    <>
     <HeroSection title="Users" desc="See a list of existing active and pending users, or invite new users." >
     <Tooltip title={profileInfo?.permissions?.includes(PermissionListEnum.SEND_ADMIN_SIGNUP_INVITE)
                ?"": "You don't have permission to access this." }>
            <div>
     <Button className="primary animated" prefix={<PlusCircle/>} label="Invite" action={handleInviteModal}
     disabled={!profileInfo?.permissions?.includes(PermissionListEnum.SEND_ADMIN_SIGNUP_INVITE)}></Button>
     </div>
</Tooltip>
        </HeroSection>
      <UsersHeader />
      {showInviteModal && (
        <InviteModal
          open={showInviteModal}
          handleClose={handleInviteModal}
        ></InviteModal>
      )}
    </>
  );
};

export default Users;
