import { Column } from "../../models/table.interface";

export const columns: readonly Column[] = [
    {
      id: "status",
      label: "Status",
      minWidth: 100,
      type: "status",
    },
    {
      id: "name",
      label: "Campaign name",
      minWidth: 170,
      type: "name",
    },
    {
      id: "callCount",
      label: "Calls",
      minWidth: 110,
      type: "number",
    },
    {
      id: "inQueueCalls",
      label: "In Queue",
      minWidth: 110,
      type: "number",
    },
    {
      id: "leadCount",
      label: "Leads",
      minWidth: 110,
      type: "number",
    },
    {
      id: "voiceEmail",
      label: "Voicemail",
      minWidth: 110,
      type: "number",
    },
    {
      id: "hangupCalls",
      label: "Hung Up",
      minWidth: 110,
      type: "number",
    },
    {
      id: "deadCalls",
      label: "Dead",
      minWidth: 110,
      type: "number",
    },
    {
      id: "wrongNumberCalls",
      label: "Wrong #",
      minWidth: 110,
      type: "number",
    },
    {
      id: "failedCalls",
      label: "Disconnected",
      minWidth: 110,
      type: "number",
      sortKey: "",
    },
    {
      id: "dncCalls",
      label: "DNC",
      minWidth: 110,
      type: "number",
    },
    {
      id: "startDate",
      label: "Start Date",
      minWidth: 170,
      type: "string",
    },
  ];