const AngelDown = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="10"
        viewBox="0 0 17 10"
        fill="none"
      >
        <path
          d="M2.10117 0.355735L8.32239 6.6358L8.5 6.81508L8.67761 6.6358L14.8988 0.355735L16.2741 1.75182L9.74671 8.37815C9.06123 9.07402 7.93877 9.07402 7.25329 8.37815L0.725923 1.75182L2.10117 0.355735Z"
          fill="black"
          stroke="white"
          stroke-width="0.5"
        />
      </svg>
    </>
  );
};

export default AngelDown;
