import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import { inviteFormSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { DEFAULT_INVITE_DATA } from "./constants";
import DropDown from "../../atoms/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getPendingUserList } from "../../pages/users/selector";
import { sendInvite } from "./service";
import Close from "../../assets/icons/cross";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { CustomError } from "../../models/customError.interface";
import { capitalizeFirstLetter } from "../../utils/utils";
import { setPendingUserList } from "../../pages/users/reducer";
import PlusCircle from "../../assets/icons/plusCircle";
import { getRolesList } from "../../pages/rolesAndPermission/selector";
import { fetchRolesList } from "../../pages/rolesAndPermission/sagaAction";
import { sendActivityLog } from "../../organisms/auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";

const InviteModal = ({ handleClose, open }: any) => {
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    register,
    control,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(inviteFormSchema),
    defaultValues: DEFAULT_INVITE_DATA,
  });
  const roleLists = useSelector((state) => getRolesList(state));
  const pendingList = useSelector((state) => getPendingUserList(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [filter, setFilter] = useState<{
    search: string;
    size: number;
    index: number;
  }>({
    search: "",
    size: 10,
    index: 1,
  });
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit = async (values: any) => {
    const name = roleLists.find((role:any) => role.id === values.roleId);
    setLoading(true);
    try {
      await sendInvite(values);
      setLoading(false);
      handleClose();
      handleShowToast( "Invite sent successfully!",ToastType.success);
      dispatch(setPendingUserList([...pendingList, {...values,roleName:name.roleName}]));
      const attributes = {
        action: `Invite sent successfully to ${values?.email}` ,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        try {
          const res = await sendActivityLog({
            logType: `Invite sent successfully to ${values?.email}` ,
            jsonData: result,
          });
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    
    } catch (err) {
      console.log(err);
      setLoading(false);
      handleShowToast((err as CustomError)?.response?.data?.message ||
      "Something went wrong!",ToastType.error)
    }
  };

  useEffect(() => {
    const options = roleLists.map((list: any) => ({
      value: list?.id,
      label: list?.roleName?.split("_")
        .map((word: any) => capitalizeFirstLetter(word))
        .join(" "),
    }));
    setRoleList(options);
  }, [roleLists]);

  useEffect(() => {
    dispatch(fetchRolesList({}));
  }, []);

  return (
    <Dialog className="stepsDialog" onClose={handleClose} open={open}>
      <div className="dialogWrapper">
        <div className="dialogWrapper__header">
          <h2>Invite user</h2>
        </div>
        <span role="link" onClick={handleClose} className="closeDialog">
          <Close />
        </span>

        <Input
          type="text"
          label="First Name*"
          register={register}
          name="nameFirst"
          placeholder="Enter first name"
          errors={errors}
        ></Input>

        <Input
          type="text"
          label="Last Name*"
          register={register}
          name="nameLast"
          placeholder="Enter last name"
          errors={errors}
        ></Input>

        <Input
          type="email"
          label="Email*"
          register={register}
          name="email"
          placeholder="Enter your email"
          errors={errors}
        ></Input>

        <DropDown
          options={roleList}
          label="Role*"
          control={control}
          name="roleId"
          errors={errors}
          placeholder="Select role"
        ></DropDown>

        <div className="mainCol actions">
          <div className="mainCol__half">
            <Button
              className="full outline"
              label="Cancel"
              action={handleClose}
            ></Button>
          </div>
          <div className="mainCol__half">
            <Button
              className="full primary"
              label="Send Invite"
              action={handleSubmit(onSubmit)}
              disabled={loading}
              clicked={loading}
              prefix={<PlusCircle />}
            ></Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default InviteModal;
