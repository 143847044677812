

import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Tooltip } from "@mui/material";
import PendingUsers from "../../organisms/pendingUsers/PendingUsers";
import ActiveUsers from "../../organisms/activeUsers/ActiveUsers";
import styles from "./UsersHeader.module.scss";
import { useSelector } from "react-redux";
import { getProfileInfo } from "../../pages/profile/selector";
import { PermissionListEnum } from "../../pages/rolesAndPermission/enum";

export const UsersHeader = () => {
  const [value, setValue] = useState("one");
  

  return (
    <div className={`${styles.user}`}>
      <Tabs
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        className={`${styles.user__header}`}
   
      >
        <Tab value="one"label="Active Users"wrapped />
        <Tab value="two" label="Pending Users" wrapped />
      </Tabs>

      {value === "one" && <ActiveUsers />}
      {value === "two" && <PendingUsers />}
    </div>
  );
};
