import { Column } from "../../models/table.interface";

export const columns: Column[] = [
  {
    id: "userId",
    label: "User Id",
    minWidth: 30,
    type: "",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 30,
    type: "",
  },
  { id: "roleName", label: "Role Name", minWidth: 70, type: "" },
  {
    id: "logType",
    label: "Log Type",
    minWidth: 70,
    type: "",
  },
  { id: "createdAt", label: "Created At", minWidth: 70, type: "" },
  { id: "jsonData", label: "Data", minWidth: 70, type: "" },
];
