import { deleteAPI, postAPI, putAPI } from "../../apis/apis";

export const addNewRole = async (payload:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/role-permission/role`,
      headers: {},
      data: payload,
    });
  };


export const addNewRolePermission = async (payload:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/role-permission/map-role-permission`,
      headers: {},
      data: payload,
    });
  };

  export const editRoleName = async (id:any,payload: any) => {
    return putAPI({
      url: process.env.REACT_APP_BASE_URL + `/role-permission/role/${id}`,
      headers: {},
      data: payload,
    });
  };

  export const deleteRole = async (id:any) => {
    return deleteAPI({
      url: `${process.env.REACT_APP_BASE_URL}/role-permission/role/${id}`,
      headers: {},
    });
  };