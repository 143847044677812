import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer"
import * as sagaActions from "./sagaAction"
import { getAPI, postAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchOrganizationList({payload}:any) {
  const {index,size,search}=payload
  if (index === 1) {
    yield put(slice.setLoadingOrganizationList(true));
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/organisations?index=${index}&size=${size}&search=${search?.trim()}`,
      headers: {},
      data: {},
    });
    yield all([
      put( index > 1 ? slice.appendOrganizationList(response?.data?.data) : slice.setOrganizationList(response?.data?.data)),
      put(slice.setOrganizationListCount(response?.data?.total)),
      put(slice.setTotalAvailableCredits(response?.data?.totalAvailableCredits)),
      put(slice.setLoadingOrganizationList(false)),

    ]);
  } catch (error: any) {
    yield all([
      put(slice.setLoadingOrganizationList(false)),
    ]);
  }
}

function* fetchOrganization({payload}:any) {
  yield put(slice.setLoadingOrganization(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/get-organisation/${payload}`,
      headers: {},
    });
    yield put(slice.setOrganization(response?.data))
    yield put(slice.setLoadingOrganization(false));
    
  } catch (error: any) {
    yield put(slice.setLoadingOrganization(false));
  }
}

function* fetchOrganizationSubscription({payload}:any) {
  yield put(slice.setLoadingOrgSubscription(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/organisations/get-active-subscription/${payload}`,
      headers: {},
    });
    yield put(slice.setOrgSubscription(response?.data?.data))
    yield put(slice.setLoadingOrgSubscription(false));
    
  } catch (error: any) {
    yield put(slice.setLoadingOrgSubscription(false));
  }
}
function* organisationsSaga() {
  yield takeLatest(sagaActions.fetchOrganizationList, fetchOrganizationList);
  yield takeLatest(sagaActions.fetchOrganization, fetchOrganization);
  yield takeLatest(sagaActions.fetchOrganizationSubscription, fetchOrganizationSubscription);
}

export default organisationsSaga;
