import TickRounded from "../../assets/images/tickRounded";
import styles from "./Hero.module.scss";

export default function Hero() {
  return (
<>
<div className={` ${styles.heroSection}`}>
        <div className={` ${styles.heroSection__inner}`}>
          <h1>
          Our <span>real estate</span> AI that works like <span>magic</span>
          </h1>
          <p>Otto is an intelligent AI caller that will automatically generate
            real estate leads for you.</p>
          <ul className="dflex justifyCenter"
          >
            <li>
              <TickRounded /> AI lead generation
            </li>
            <li>
              <TickRounded /> Precision skip tracing
            </li>
            <li>
              <TickRounded /> Top tier property data
            </li>
          </ul>
        </div>
      </div>

    </>
  );
}
