import styles from "./AnalyticCards.module.scss";
import { AnalyticCardsProps } from "./interface";
import Pill from "../../atoms/Pill";
import ArrowUp from "../../assets/icons/arrowUp";
import RefreshSmall from "../../assets/icons/refereshSmall";
import Circle from "../../assets/icons/circle";


const AnalyticCards = ({
  title,
  value,
  children,
  status,
  statusToday,
  statusValue,
  showStatusKey,
}: AnalyticCardsProps) => {
   
  return (
    <>
      <div className={`${styles.card}`} role="link">
       
        <div
          className={`dflex justifySpaceBetween alignCenter ${styles.card__info}`}
        >
          <h2>{title}</h2>
          {/* <Switch defaultChecked /> */}
        </div>
        <p> {value}
        </p>
        {children}
        {Boolean(status) && showStatusKey && (
            <Pill
              infix={statusValue ? <RefreshSmall />: "" || statusToday ? <ArrowUp /> : <Circle />}
              label={status}
              className={statusValue ?  `reset` : ``  || statusToday ? `calling` : ``}
            />
          )}
      </div>
    </>
  );
};

export default AnalyticCards;
