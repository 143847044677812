import { useForm } from "react-hook-form";
import { addRoleSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { DEFAULT_ADD_ROLE_VALUES } from "./constants";
import Input from "../../atoms/Input";
import styles from "./AddRole.module.scss";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getPermissionList } from "../../pages/rolesAndPermission/selector";
import Button from "../../atoms/Button";
import { useEffect } from "react";
import { sendActivityLog } from "../../organisms/auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";

const AddRole = ({ handleClose, roleData, onSubmit, loading, onUpdateSubmit }: any) => {
    
  const profileInfo = useSelector((state) => getProfileInfo(state));
    const {
        formState: { errors },
        register,
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(addRoleSchema),
        defaultValues: DEFAULT_ADD_ROLE_VALUES,
    });
    const permissionList = useSelector((state) => getPermissionList(state));

    useEffect(() => {
        if (roleData) {
            console.log("test", roleData)
            setValue("roleName", roleData.roleName || "");
            const rolePermissions = roleData.rolePermissionMap
                ? roleData.rolePermissionMap.map((rp: any) => rp.permissionId)
                : [];
            setValue("permissions", rolePermissions);
        }
    }, [roleData, setValue]);


    return (
        <div className={`${styles.addRole}`}>
            <Input
                type="text"
                label="Role name*"
                register={register}
                name="roleName"
                placeholder="Enter role name"
                errors={errors}
            />
            <div className={`${styles.addRole__checkbox}`}>
            
                <label>Permissions*</label>
                <div className={`${styles.addRole__checkboxList} dflex`}>
                    {permissionList.map((permission: any) => (
                       <div className={`${styles.addRole__checkboxes}`}>
                        <FormControlLabel
                            key={permission.id}
                            control={
                                <Checkbox
                                    {...register("permissions")}
                                    value={permission.id}
                                    defaultChecked={
                                        roleData
                                            ? roleData.rolePermissionMap.some(
                                                (rp: any) => rp.permissionId === permission.id
                                            )
                                            : false
                                    }
                                />
                            }
                            label={permission.permissionName}
                        />
                        </div>
                    ))}
                </div>
                {errors.permissions && (
                    <p className={`${styles.addRole__error}`}>{errors.permissions.message}</p>
                )}

            </div>
            <div className="actions">
                <div className="mainCol">
                    <div className="mainCol__half">
                        <Button 
                        label="Cancel" 
                        action={handleClose}
                         className="outline full"
                        ></Button>
                    </div>
                    <div className="mainCol__half">
                        <Button
                            label="Submit"
                            action={roleData ? handleSubmit(onUpdateSubmit) : handleSubmit(onSubmit)}
                            disabled={loading}
                            clicked={loading}
                            className="primary full animated"
                        ></Button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AddRole;
