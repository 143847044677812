import Close from "../assets/icons/cross";
import Search from "../assets/icons/search";
import SearchSm from "../assets/icons/searchSm";

const SearchBar = ({
  onChange,
  searchText,
  searchIconRight,
  closeIcon,
  placeholder,
  focused,
}: {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchText: string;
  searchIconRight?: boolean;
  closeIcon?: boolean;
  placeholder?: string;
  focused?: boolean;
  dataTestId?: string;
}) => {
  return (
    <>
      <div
        className={`search ${searchIconRight ? `searchIconRight` : ``} ${
          closeIcon ? `closeIcon` : ``
        } ${focused ? `focused` : ``}`}
      >
        <span role="link" className="search__icon">
          {searchIconRight ? <SearchSm /> : <Search />}
        </span>
        {closeIcon && (
          <span role="link" className="search__close">
            <Close />
          </span>
        )}

        <input
          type="text"
          placeholder={placeholder ? placeholder : `Search`}
          onChange={onChange}
          value={searchText}
          onPause={
            onChange as unknown as React.ReactEventHandler<HTMLInputElement>
          }
          data-testid="campaignSearchTestId"
        />
      </div>
    </>
  );
};
export default SearchBar;
