import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  campaignsList: [] as any[],
  isFetchingMore:false,
  loadingCampaignList: false, 
  campaignsListCount:0,
  campaignLeadListCount: 0,
  campaignLeadList:[] as any[],
  loadingCampaignLeadList:false,
  campaignActivityList: [] as any[],
  loadingCampaignActivityList: false,
  campaignActivityListCount:0,
  topSectionData:[],
  isAttemptSelected:false,
  attemptDetails:[],
  campaignListDetail:[],
  loadingCampaignListDetail:false,
  iscampaignLeadListFetchingMore:false,
  hungUpData:[],
  hungUpDataLoading:false,
  campaignData:{}
};

const campaignsListSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaignsList: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        campaignsList: payload,
      };
    },
    setCampaignsListFetchingMore: (state, { payload }) => {
      return {
        ...state,
        isFetchingMore: payload,
      };
    },
    setLoadingCampaignsList: (state, { payload }) => {
      return {
        ...state,
        loadingCampaignList: payload,
      };
    },
    setCampaignsListCount: (state, { payload }) => {
      return {
        ...state,
        campaignsListCount: payload,
      };
    },
    appendCampaignsList:(state,{payload})=>{
      return {
        ...state,
        campaignsList:[...state?.campaignsList, ...(payload ? payload : [])],
      }
    },
    setCampaignLeadList: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        campaignLeadList: payload,
      };
    },
    setcampaignLeadListFetchingMore: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        iscampaignLeadListFetchingMore: payload,
      };
    },
    appendCampaignLeadList:(state,{payload})=>{
      return {
        ...state,
        campaignLeadList:[...state?.campaignLeadList, ...(payload ? payload : [])],
      }
    },
    setCampaignLeadListCount: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        campaignLeadListCount: payload,
      };
    },
    setLoadingCampaignLeadList: (state, { payload }) => {
      return {
        ...state,
        loadingCampaignLeadList: payload,
      };
    },
    setCampaignActivityList: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        campaignActivityList: payload,
      };
    },
    appendCampaignActivityList:(state,{payload})=>{
      return {
        ...state,
        campaignActivityList:[...state?.campaignActivityList, ...(payload ? payload : [])],
      }
    },
    setCampaignActivityListCount: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        campaignActivityListCount: payload,
      };
    },
    setLoadingCampaignActivityList: (state, { payload }) => {
      return {
        ...state,
        loadingCampaignActivityList: payload,
      };
    },
    setTopSectionData: (state, { payload }) => {
      return {
        ...state,
        topSectionData: payload,
      };
    },
    setAttemptDetails:  (state, { payload }) => {
      return {
        ...state,
        attemptDetails: payload,
      };
    },
    setIsAttemptSelected: (state, { payload }) => {
      return {
        ...state,
        isAttemptSelected: payload,
      };
    },
    setGraphData: (state, { payload }) => {
      return {
        ...state,
        graphData: payload,
      };
    },
    setCampaignListDetail: (state, { payload }) => {
      console.log(payload)
      return {
        ...state,
        campaignListDetail: payload,
      };
    },
    setLoadingCampaignList: (state, { payload }) => {
      return {
        ...state,
        loadingCampaignListDetail: payload,
      };
    },
    setHungUpData: (state, { payload }) => {
      return {
        ...state,
        hungUpData: payload,
      };
    },
    setHungUpDataLoading: (state, { payload }) => {
      return {
        ...state,
        hungUpDataLoading: payload,
      };
    },
    setCampaignData: (state, { payload }) => {
      return {
        ...state,
        campaignData: payload,
      };
    },
  },
});

export const {
  setCampaignsList,
  setLoadingCampaignsList,
  setCampaignsListCount,
  appendCampaignsList,
  setCampaignLeadList,
  appendCampaignLeadList,
  setCampaignLeadListCount,
  setLoadingCampaignLeadList,
  setCampaignActivityList,
  appendCampaignActivityList,
  setCampaignActivityListCount,
  setLoadingCampaignActivityList,
  setTopSectionData,
  setAttemptDetails,
  setIsAttemptSelected,
  setGraphData,
  setCampaignListDetail,
  setcampaignLeadListFetchingMore,
  setLoadingCampaignList,setCampaignsListFetchingMore,setHungUpData,
  setHungUpDataLoading ,setCampaignData
} = campaignsListSlice.actions;

export default campaignsListSlice.reducer;
