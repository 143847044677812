export enum PermissionListEnum {
  ADD_UPDATE_ROLE = "Add/Update Role",
  GET_ROLES = "Get Roles",
  DELETE_ROLE = "Delete Role",
  MAP_ROLE_PERMISSIONS = "Map Roles and Permissions",
  GET_ORGANISATION_DETAILS = "Get Organisation Details",
  ADD_CREDIT = "Add Credit",
  SEND_ADMIN_SIGNUP_INVITE = "Send Admin Signup Invite",
  GET_ADMIN_USERS = "Get Admin Users",
  ENABLE_DISABLE_ORGANISATION_USER = "Enable/Disable Organisation and User",
  ADD_USER = "Add User",
  GET_CAMPAIGN_DETAILS = "Get Campaign Details",
  UPDATE_DELETE_LEAD = "Update/Delete Lead",
  GET_LEAD = "Get Leads",
  GET_LIST = "Get List",
  UPDATE_DELETE_LIST = "Update/Delete List",
  PAYMENT_LEVEL_ACCESS = "Payment Level Access",
  GET_BOT_WORKER = "Get Bot Worker",
  STOP_BOT_WORKER = "Stop Bot Worker",
}
