import styles from "./Page404.module.scss";
import notFound from "../../assets/images/404-icon.svg";

const Page404 = () => {
  return (
    <div className={` ${styles.page404}`}>
      <div className="wrapper bottom-zero">
        <div className={`dflex alignCenter ${styles.page404__inner}`}>
          <figcaption>
            <h2>404 Error</h2>
            <p>
              Sorry, the page you are looking for doesn't exist or has been
              moved. Try going back.
            </p>
          </figcaption>
          <figure className="flex alignCenter justifyCenter">
            <img src={notFound} alt="404" />
          </figure>
        </div>
      </div>
    </div>
  );
};
export default Page404;
