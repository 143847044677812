import React from "react";
import InputMask from "react-input-mask";
 
const MaskedInput = ({register,name, onChange , errors, label, placeholder ,mask="999-999-9999",maskChar=undefined}: any) => {
  return (
    <>
    <div className="inputGroup inputPhone">
    <label className="label">{label}</label>
      <InputMask
        mask={mask}
        {...register(name)}
        errors={errors}
        maskChar={maskChar}
        className={errors?.[name] ? `inputError` : ``}
        placeholder={placeholder}
      ></InputMask>
       {errors?.[name] && <span className="error"> {errors?.[name]?.message} </span>}
       </div>
    </>
  );
};

export default MaskedInput;
