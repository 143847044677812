import React, { useEffect, useState } from "react";
import CustomTable from "../../../../molecules/table/CustomTable";
import Loader from "../../../../atoms/Loader";
import ExportToCsv from "../../../../atoms/ExportToCsvV2";
import { useDispatch, useSelector } from "react-redux";
import { getHungUpdata, getHungUpDataLoading } from "../../selector";
import { fetchHungupData } from "../../sagaAction";
import { useParams } from "react-router-dom";
import { leadStatus_column, LeadStatusTable_Column } from "../../constants";
import SearchReflation from "../../../../assets/icons/searchReflation";
import { setHungUpData } from "../../reducer";
import styles from "../LeadStatusTabs.module.scss";
import { ToastType } from "../../../../molecules/toaster/enum";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import { fetchLeadStatus } from "../../service";

const VoiceMail = () => {
  const dispatch = useDispatch();
  const id = useParams();
  const data = useSelector((state) => getHungUpdata(state));
  const loading = useSelector((state) => getHungUpDataLoading(state));
  const [isPreview, setIsPreview] = useState(true);
  const [leadsdetail, setLeadsDetail] = useState([]);
  const [exportListLoading, setExportListLoading] = useState(false);
  const[loadingText,setLoadingText]= useState("Export to CSV")

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleChange = async () => {
    try {
      setExportListLoading(true);
      const payload = {
        id: id?.id,
        status: "VOICEMAIL",
        preview: false,
      };
      const result: any = await fetchLeadStatus(payload);
      setExportListLoading(false);
      handleShowToast("List downloaded successfully!", ToastType.success);
      return result?.data?.map((item: any) => ({
        ...item,
      }));
    } catch (error) {
      setExportListLoading(false);
    }
  };

  useEffect(() => {
    let intervalId:NodeJS.Timeout;
    if (exportListLoading) {
      setLoadingText("Exporting...");
      const texts = [ "Still working...", "Almost there...", "Just a moment..."];
      let index = 0;
      intervalId = setInterval(() => {
        setLoadingText(texts[index]);
        index = (index + 1) % texts.length;
      }, 8000);
    } else {
      setLoadingText("Export to CSV");
    }
    return () => clearInterval(intervalId);
  }, [exportListLoading]);

  useEffect(() => {
    const payload = {
      id: id?.id,
      status: "VOICEMAIL",
      preview: isPreview,
    };
    dispatch(fetchHungupData(payload));
  }, [id,isPreview]);

  useEffect(() => {
    const leadData = data?.map((lead: any) => {
      return {
        lead: {
          title: `${(lead?.ownerNameFirst)} ${(
            lead?.ownerNameLast
          )}`,
          description: [lead?.addressStreet, ", ", lead?.addressCity, ", ", lead?.addressState, ", ", lead?.addressZip],
        },
        phone:lead.phone1,
        activityDrawerLeadId: false,
      };
    });
    setLeadsDetail(leadData ?? []);
  }, [data]);

  return (
    <>
      <div className={` ${styles.leadsTabInfo}`}>
      <div className={`${styles.leadsTabInfo__header} dflex justifySpaceBetween alignCenter`}>
      <h2>Voice Mail</h2>
          <div className={`dflex`}>
            <ExportToCsv
              headers={leadStatus_column.map((column) => {
                return { id: column.key, displayName: column.label };
              })}
              filename={`VoiceMail.csv`}
              loadData={() => handleChange()}
              label={loadingText}
              loading={exportListLoading}
            ></ExportToCsv>
          </div>
        </div>
        <div>
          {loading ? (
            <div className="table-loader">
              <Loader></Loader>
            </div>
          ) : (
            <CustomTable
              rows={leadsdetail}
              columns={LeadStatusTable_Column}
              tableStriped
              currentPageIndex={1}
              emptyTableMetaData={{
                icon: <SearchReflation />,
                title: "No results found",
              }}         
               ></CustomTable>
          )}
        </div>
      </div>
    </>
  );
};

export default VoiceMail;
