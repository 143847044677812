import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganizationList } from "./sagaAction";
import {
  getLoadingOrganizationList,
  getOrganizationList,
  getOrganizationListCount,
  getTotalAvailableCredits,
} from "./selector";
import Loader from "../../atoms/Loader";
import HeroSection from "../../organisms/heroSection/HeroSection";
import CustomTable from "../../molecules/table/CustomTable";
import { columns } from "./constants";
import CheckCircle from "../../assets/icons/checkCircle";
import { useNavigate } from "react-router-dom";
import { followUpDateFormatter } from "../../utils/utils";
import Close from "../../assets/icons/cross";
import { Payment } from "@mui/icons-material";
import { getProfileInfo } from "../profile/selector";
import { sendActivityLog } from "../../organisms/auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { Tooltip } from "@mui/material";
import SearchBar from "../../atoms/SearchBar";
import styles from "./Organization.module.scss";
import Button from "../../atoms/Button";
import SearchReflation from "../../assets/icons/searchReflation";
import { useDbounce } from "../../hooks/debounce.hook";

const Organization = () => {
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const organizations = useSelector((state) => getOrganizationList(state));
  const organizationLoading = useSelector((state) =>
    getLoadingOrganizationList(state)
  );
  let navigate = useNavigate();
  const total = useSelector((state) => getOrganizationListCount(state));
  const totalCredits= useSelector((state)=>getTotalAvailableCredits(state));
  const [filter, setFilter] = useState({ index: 1, size: 20, search: "" });
  const [tableData, setTableData] = useState([]);

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
  };

  const setSearchText = (value: string) => {
    setFilter((prev) => ({ ...prev, search: value, index: 1 }));
  };

  const handleClearSearch= (value: string) => {
    setFilter((prev) => ({ ...prev, search: '', index: 1 }));
  };
 
  const debounce = useDbounce(500, (filter:any) => {
    dispatch(fetchOrganizationList(filter));
  });
  
  const handleNext = async (id: any) => {
    navigate(`/organisation/${id}/campaigns`);
    const attributes = {
      action: `The user has accessed the organisation ${id}.`,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo?.email,
      roleName: profileInfo?.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType: "Organisation Selected",
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handleRedirect = async (id: any, data: any) => {
    const attributes = {
      action: `The user is trying to access stripe portal of organisation ${data?.id}.`,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo?.email,
      roleName: profileInfo?.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType: "Stripe portal access",
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
    const url = `${process.env.REACT_APP_STRIPE_URL}/${id}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    const listData = organizations?.map((org: any) => {
      let emailList: string[] = []
      org?.user.map((el : any)=> emailList.push(el.email))
      
      return {
        id: org.id,
        name: `${org?.organisationName}`,
        email: emailList,
        date: followUpDateFormatter(
          org?.createdAt ? new Date(org?.createdAt) : null
        ),
        availableCredit:org?.availableCredit,
        action: (
          <div className="flex gap-10 alignCenter">
            {org?.deletedAt ? (
              <span className="close-circle-main">
                <span className="close-circle">
                  <Close />
                </span>
              </span>
            ) : (
              <CheckCircle />
            )}
            <Tooltip
              title={"Clicking on this will navigate to the Stripe portal"}
            >
              <span
                role="link"
                className="stripe-icon"
                onClick={() => handleRedirect(org?.stripeCustomerId, org)}
              >
                <Payment />
              </span>
            </Tooltip>
          </div>
        ),
      };
    });
    setTableData(listData ?? []);
  }, [organizations]);

  useEffect(() => {
    debounce(filter)
  }, [filter]);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "Organisation page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "Organisation page",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };

    logAuditData();
  }, [profileInfo]);

  return (
    <div className={`${styles.main}`}>
      <HeroSection
        title="Organisations"
        desc="See a list of active and disabled organisations."
      >
         <div className={`${styles.main__total}`}>
         Total available credits :<span>{totalCredits}</span>
         </div>
      </HeroSection>

     
  <div className={`${styles.main__search}`}>
      <SearchBar
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          let value = e.target.value;
          if (e.target.value.length > 0 && e.target.value.trim().length === 0) {
            value = "";
          }
          if (filter.search !== value) {
            setSearchText(value);
          }
        }}
        searchText={filter.search}
      ></SearchBar>
</div>
<div className={`${styles.main__table}`}>
      {organizationLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <>
          <CustomTable
            rows={tableData}
            columns={columns}
            tableStriped
            search={filter.search}
            hasMore={tableData?.length < total}
            handleNext={handleNext}
            handleNextPage={handleNextPage}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
              description:
                "We couldn’t find any results that match your search.",
              button1: (
                <Button
                  label="Clear search"
                  className="outline"
                  action={handleClearSearch}
                />
              ),
            }}
          ></CustomTable>
        </>
      )}
    </div>
    </div>
  );
  
};

export default Organization;
