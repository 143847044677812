import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import * as sagaActions from "./sagaAction";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchInvoices({ payload }: any) {
  const{ last_Index,size,  id, }= payload
  try {
    if (!last_Index) {
      yield put(slice.setInvoicesIsLoading(true));
    }
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/organisations/get-invoice/${id}`,
      headers: {},
      data: payload,
    });
    yield all([
      put(
       last_Index
          ? slice.appendInvoices(response.data) 
          : slice.setInvoices(response.data)
      ),
      put(slice.setInvoicesIsLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setInvoicesIsLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* billingSaga() {
  yield takeLatest(sagaActions.fetchInvoices, fetchInvoices);
}

export default billingSaga;
