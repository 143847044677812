import { postAPI } from "../../apis/apis";

export const signIn = async (payload: Record<string, any>) => {
    return postAPI ({
        url:
          process.env.REACT_APP_BASE_URL + `/admin/google-signin`,
        headers: {},
        data: payload,
      });
  };


  export const generateRefreshToken = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BASE_URL + "/admin/refresh",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data?.access_token;
    } catch (error) {
      console.error("Error during refresh token generation:", error);
    }
  };

  export const sendActivityLog = async (payload:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/admin/activity-log`,
      headers: {},
      data: payload,
    });
  };