import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer"
import * as sagaActions from "./sagaAction"
import { getAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchUserDetailList({payload}:any) {
    yield put(slice.setLoadingUserDetailList(true));
    try { 
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL +
          `/admin/get-organisations-user/${payload}`,
        headers: {},
    });

      yield all([
        put( slice.setUserDetailList(response?.data?.data)),
        put(slice.setUserListCount(response?.data?.total)),
        put(slice.setLoadingUserDetailList(false)),
      ]);
    } catch (error: any) {
      yield all([
        put(slice.setLoadingUserDetailList(false)),
      ]);
    }
  }

  function* UserDetailListSaga() {
    yield takeLatest(sagaActions.fetchUserDetailList, fetchUserDetailList);

  }
  
  export default UserDetailListSaga;