import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPendingListCount,
  getPendingListLoading,
  getPendingUserList,
} from "../../pages/users/selector";
import Button from "../../atoms/Button";
import CustomTable from "../../molecules/table/CustomTable";
import { columns } from "./constants";
import { resendInvite } from "./service";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { capitalizeFirstLetter } from "../../utils/utils";
import { Refresh } from "@mui/icons-material";
import { sendActivityLog } from "../auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { fetchPendingUsersList } from "../../pages/users/sagaAction";
import Loader from "../../atoms/Loader";
import { PermissionListEnum } from "../../pages/rolesAndPermission/enum";
import { Tooltip } from "@mui/material";

const PendingUsers = () => {
  const pendingList = useSelector((state) => getPendingUserList(state));
  const total = useSelector((state) => getPendingListCount(state));
  const loadingTable = useSelector((state) => getPendingListLoading(state));
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [filter, setFilter] = useState<{
    search: string;
    size: number;
    index: number;
  }>({
    search: "",
    size: 20,
    index: 1,
  });
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const resendUserInvite = async (email: string, id: any) => {
    setLoadingIndex(id);
    try {
      const result = await resendInvite(email);
      console.log(result);
      dispatch(
        addToast({
          id: new Date().getTime(),
          message: "Invite sent successfully!",
          type: ToastType.success,
        })
      );
      const attributes = {
        action: `Invite resend successfully to ${email}`,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        try {
          const res = await sendActivityLog({
            logType: `Invite resend successfully to ${email}`,
            jsonData: result,
          });
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
      setLoadingIndex(-1);
    } catch (err) {
      console.log(err);
      setLoadingIndex(-1);
    }
  };

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
  };

  useEffect(() => {
    const listData = pendingList?.map((list: any) => {
      return {
        name: `${list?.nameFirst} ${list?.nameLast}`,
        email: list?.email,
        roleName: list.roleName
          ?.split("_")
          .map((word: any) => capitalizeFirstLetter(word))
          .join(" "),
        resendInvite: (
          <Tooltip
            title={
              profileInfo?.permissions?.includes(
                PermissionListEnum.SEND_ADMIN_SIGNUP_INVITE
              )
                ? ""
                : "You don't have permission to access this."
            }
          >
            <div>
              <Button
                key={list?.id}
                className="icon"
                label=""
                action={() => resendUserInvite(list.email, list?.id)}
                disabled={
                  loadingIndex === list?.id ||
                  !profileInfo?.permissions?.includes(
                    PermissionListEnum.SEND_ADMIN_SIGNUP_INVITE
                  )
                }
                clicked={loadingIndex === list?.id}
                prefix={<Refresh />}
              ></Button>
            </div>
          </Tooltip>
        ),
      };
    });
    setTableData(listData ?? []);
  }, [pendingList, loadingIndex]);

  useEffect(() => {
    dispatch(fetchPendingUsersList(filter));
  }, [filter]);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "View pending user tab",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo.email,
        roleName: profileInfo.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "View pending user tab",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };

    logAuditData();
  }, [profileInfo]);
  console.log(tableData?.length < total, tableData?.length, total);

  return (
    <>
      {loadingTable ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <CustomTable
          rows={tableData}
          columns={columns}
          search={""}
          tableStriped
          hasMore={tableData?.length < total}
          handleNextPage={handleNextPage}
        ></CustomTable>
      )}
    </>
  );
};

export default PendingUsers;
