import axios from "axios";
import { decodetoken } from "../utils/utils";
import { generateRefreshToken } from "../organisms/auth/service";
import { defaultLogout } from "../organisms/auth/auth";

const handleLogout = () => {
  window.location.href = "/";
  defaultLogout()
};

const interceptor = async () => {
  axios.interceptors.request.use(async (req) => {
    let JWTToken: any = localStorage.getItem("accessToken");
    if (JWTToken != null) {
        const expiryTime: any = decodetoken(JWTToken) ?? -1;
        const currentTime = new Date().getTime();
        if (expiryTime <= currentTime) {
          try {
            JWTToken = await generateRefreshToken();
            localStorage.setItem("accessToken", JWTToken);
          } catch {
            handleLogout();
          }
        }
      
      req.headers.Authorization = `Bearer ${JWTToken}`;
    }
    return {
      ...req,
    };
  });

  axios.interceptors.response.use(
    (res: any) => {
      return res;
    },
    (error: any) => {
      console.log(error);

      if (
        error?.response?.status === 401 &&
        error?.response?.data?.message === "Unauthorized"
      ) {
        error.response.data.message = "";
        error.message = "";
        handleLogout();
      }

      return Promise.reject(error);
    }
  );
};

export default interceptor;
