import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer"
import * as sagaActions from "./sagaAction"
import { getAPI, postAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchLogActivityList({payload}:any) {
  const {index,size,id}=payload
  if (index === 1) {
    yield put(slice.setLogActivityListLoading(true));
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/activity-log?index=${index}&size=${size}&userId=${id}`,
      headers: {},
      data: {},
    });
    yield all([
      put( index > 1 ? slice.appendLogActivityList(response?.data?.data) : slice.setLogActivityList(response?.data?.data)),
      put(slice.setLogActivityListCount(response?.data?.total)),
      put(slice.setLogActivityListLoading(false)),

    ]);
  } catch (error: any) {
    yield all([
      put(slice.setLogActivityListLoading(false)),
    ]);
  }
}

function* logActivitySaga() {
    yield takeLatest(sagaActions.fetchLogActivityList, fetchLogActivityList);
  }
  
  export default logActivitySaga;