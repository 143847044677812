import React from "react";
import Loader from "./Loader";

const Button = ({ label,labelNormal, action, prefix, postfix, className, disabled, clicked, type = "button",tabIndex,dataTestId  }: any) => {
  return (
    <>
      <button
        onClick={action}
        disabled={disabled}
        type={type}
        tabIndex={tabIndex}
        data-testid={dataTestId}
        className={`button ${className} ${clicked ? `loader` : ``} ${disabled ? "disable" : ""}`}
      >
        {clicked ? (
          <span className="button__loader">
             <Loader />
          </span>
        ) : (
          ``
        )}
        {prefix ? (
          <>
            {" "}
            <span className="button__icon">{prefix}</span>
          </>
        ) : (
          ""
        )}
        <span className="button__text">{label} {labelNormal && <span>{labelNormal}</span>}</span>
        {postfix ? (
          <>
            {" "}
            <span className="button__icon">{postfix}</span>
          </>
        ) : (
          ""
        )}
      </button>
    </>
  );
};

export default Button;
