import * as yup from "yup";

export const inviteFormSchema = yup.object({
nameFirst: yup.string().trim().required("First name is required"),
nameLast: yup.string().trim().required("Last name is required"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email"
    ),
    roleId: yup.string().required("Role is required"), 
});
