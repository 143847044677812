import * as yup from "yup";

export const refundSchema = (maxAmount:any) => yup.object({
  refundMethod: yup.string().required("Refund method is required"),
  amount: yup.lazy((value, context) => {
    const { refundMethod } = context.parent;
    if (refundMethod === 'full') {
      return yup.number().nullable().notRequired();
    } else {
      return yup.number()
      .moreThan(0, "Amount must be greater than 0.")
        .transform((value, originalValue) => {
          return originalValue === "" || isNaN(originalValue) ? null : Number(originalValue);
        })
        .max(Number(maxAmount.toFixed(2)), `Amount must be at most ${maxAmount.toFixed(2)}`)
        .required("Amount is required");
    }
  }),
});
