    export enum ProductPriceIntervalType {
    YEAR = "year",
    MONTH = "month",
    ONETIME = "one_time"
  }
  
  export enum ProductType {
    CREDIT_ACCESS = "credit_access",
    GENERAL_ACCESS = "general_access",
  }
  