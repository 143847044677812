import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../../molecules/table/CustomTable";
import { column, columns, skipList_column } from "./constants";
import {
  formartNumberAsLocalString,
  timeAgoFormatter,
} from "../../utils/utils";
import { CircularProgress, Tooltip } from "@mui/material";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import AlertTriangle from "../../assets/icons/alertTriangle";
import Upload from "../../assets/icons/upload";
import HeroSection from "../heroSection/HeroSection";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "./sagaAction";
import { getListLoading, getMyListCount, getMylistData } from "./selector";
import Loader from "../../atoms/Loader";
import SearchReflation from "../../assets/icons/searchReflation";
import { fetchExportCSVList } from "./service";
import SearchBar from "../../atoms/SearchBar";
import Button from "../../atoms/Button";
import { sendActivityLog } from "../auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import styles from "./List.module.scss";
import { useDbounce } from "../../hooks/debounce.hook";

const List = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const[ selectedId, setSelectedId]= useState(-1)
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const listLoading = useSelector((state) => getListLoading(state));
  const [filter, setFilter] = useState<{
    index: number;
    size: number;
    search:string
  }>({
    index: 1,
    size: 20,
    search:""
  });

  const myLists = useSelector((state) => getMylistData(state));
  const totalCount = useSelector((state) => getMyListCount(state));
  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
  };
  const handleCSVData = async (data: any) => {
    setSelectedId(data);
    try {
      const response = await fetchExportCSVList({id:data,orgId})
      const listingData = response?.data?.data?.map((list: any) => {
        return {
          nameFirst: list?.owner[0]?.nameFirst ?? "",
          nameLast: list?.owner[0]?.nameLast ?? "",
          addressStreet: list?.addressStreet ?? "",
          addressCity: list?.addressCity ?? "",
          addressState: list?.addressState ?? "",
          addressZip: list?.addressZip ?? "",
          phone1: list?.phones[0]?.number ?? "",
          phone2: list?.phones[1]?.number ?? "",
          phone3: list?.phones[2]?.number ?? "",
        };
      });
      return listingData; 
    } catch (err) {
    } finally {
      setSelectedId(-1);
    }
  };

  const debounce = useDbounce(500, (filter:any) => {
    const payload = { index: filter.index, size: filter.size, orgId,search: filter.search };
    dispatch(fetchList(payload));
  });

  const setSearchText = (value: string) => {
    setFilter((prev) => ({ ...prev, search: value, index: 1 }));
  };

  const handleClearSearch= (value: string) => {
    setFilter((prev) => ({ ...prev, search: '', index: 1 }));
  };

  const handleSkipData = async (data: any) => {
    setSelectedId(data);
    try {
      const response = await fetchExportCSVList({id:data,orgId});
      const listingData = response?.data?.data?.map((list: any) => {
        return {
          Owner1FirstName:`"${ list.skipData?.jsonData?.output?.identity?.names[0]?.firstName ?? "" }"`,
          Owner1LastName:
           `"${ list.skipData?.jsonData?.output?.identity?.names[0]?.lastName ?? ""}"`,
          Owner2FirstName:
           `"${ list.skipData?.jsonData?.output?.identity?.names[1]?.firstName ??
            "" }"`,
          Owner2LastName:
           `"${ list.skipData?.jsonData?.output?.identity?.names[1]?.lastName ?? ""}"`,
          MailingStreetAddress:
           `"${ list?.searchData?.jsonData?.mailAddress?.street ?? ""}"`,
          MailingCity: `"${list?.searchData?.jsonData?.mailAddress?.city ?? ""}"`,
          MailingState: `"${list?.searchData?.jsonData?.mailAddress?.state ?? ""}"`,
          MailingZip:`"${ list?.searchData?.jsonData?.mailAddress?.zip ?? ""}"`,
          MailingCounty: `"${list?.searchData?.jsonData?.mailAddress?.county ?? ""}"`,
          PropertyStreetAddress:
           `"${list?.searchData?.jsonData?.address?.street ?? ""}"`,
          PropertyCity: `"${list?.searchData?.jsonData?.address?.city ?? "" }"`,
          PropertyState: `"${list?.searchData?.jsonData?.address?.state ?? ""}"`,
          PropertyZip:`"${ list?.searchData?.jsonData?.address?.zip ?? ""}"`,
          PropertyCounty: `"${list?.searchData?.jsonData?.address?.county ?? ""}"`,
          Email1:
            list.skipData?.jsonData?.output?.identity?.emails[0]?.email ?? "",
          Email2:
            list.skipData?.jsonData?.output?.identity?.emails[1]?.email ?? "",
          Phone1:
            list.skipData?.jsonData?.output?.identity?.phones[0]?.phone ?? "",
          Phone2:
            list.skipData?.jsonData?.output?.identity?.phones[1]?.phone ?? "",
          Phone3:
            list.skipData?.jsonData?.output?.identity?.phones[2]?.phone ?? "",
          Phone4:
            list.skipData?.jsonData?.output?.identity?.phones[3]?.phone ?? "",
          Phone5:
            list.skipData?.jsonData?.output?.identity?.phones[4]?.phone ?? "",
          Phone6:
            list.skipData?.jsonData?.output?.identity?.phones[5]?.phone ?? "",
          IsVacant: `"${list?.searchData?.jsonData?.vacant ?? ""}"`,
          IsAbsentee: `"${list?.searchData?.jsonData?.absenteeOwner ?? ""}"`,
          APN: `"${list?.searchData?.jsonData?.apn ?? ""}"`,
          PropertyType:`"${list?.searchData?.jsonData?.propertyType ?? "" }"`, 
          PropertyUse: `"${list?.searchData?.jsonData?.propertyUse ?? ""}"`,
          BedroomCount: `"${list?.searchData?.jsonData?.bedrooms ?? ""}"`,
          BathroomCount: `"${list?.searchData?.jsonData?.bathrooms ?? ""}"`,
          BuildingSquareFeet: `"${list?.searchData?.jsonData?.squareFeet ?? ""}"`,
          LotSquareFeet: `"${list?.searchData?.jsonData?.lotSquareFeet ?? ""}"`,
          YearBuilt:`"${ list?.searchData?.jsonData?.yearBuilt ?? ""}"`,
          AssessedValue: `"${list?.searchData?.jsonData?.assessedValue ?? ""}"`,
          LastSaleDate: `"${list?.searchData?.jsonData?.lastSaleDate ?? ""}"`,
          LastSalePrice: `"${list?.searchData?.jsonData?.lastSaleAmount ?? ""}"`,
          MortgageBalanceOpen:
            `"${list?.searchData?.jsonData?.openMortgageBalance ?? ""}"`,
          MortgageLenderName: `"${list?.searchData?.jsonData?.lenderName ?? ""}"`,
          MortgageType: `"${list?.searchData?.jsonData?.lenderName ?? ""}"`,
          EstimatedEquity: `"${list?.searchData?.jsonData?.estimatedEquity ?? ""}"`,
          EstimatedValue: `"${list?.searchData?.jsonData?.estimatedValue ?? ""}"`,
          MLSStatus: `"${list?.searchData?.jsonData?.mlsActive ?? ""}"`,
          MLSListingDate: `"${list?.searchData?.jsonData?.mlsListingDate ?? ""}"`,
          MLSListingPrice: `"${list?.searchData?.jsonData?.mlsListingPrice ?? ""}"`,
            AISummary: `"${list?.aiSummary ?? ""}"`
        };
      });
      setSelectedId(-1);
      return listingData;
    } catch (err) {
      setSelectedId(-1);
    } 
  };

  const logAuditData = async (data:any,logType:any) => {
    const attributes = {
      action: data,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType:logType,
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handleDownload = async (type: string, id: any) => {
    logAuditData(`User is downloading list id : ${id}`,"Download List")
    if (type === "uploaded") {
      return await handleCSVData(id);
    } else {
      return await handleSkipData(id);
    }
  };

  const handleList = useCallback(() => {
    let list = myLists?.map((lead: any) => {
      const downloadListStatus = () => {
        if (lead?.status === "uploading") {
          return (
            <div className="disable-icon">
                <CircularProgress size={20} />
            </div>
          );
        } else if (lead?.status === "failed") {
          return (
            <Tooltip title={"Failed"}>
              <div className="disable-icon">
                <AlertTriangle />
              </div>
            </Tooltip>
          );
        } else if (lead?.status === "success") {
          return ( 
            <span role="link">
              <ExportToCsv
                headers={lead?.type === "uploaded" ? column : skipList_column}
                filename={`${lead?.name}.csv`}
                loadData={() => 
                  handleDownload(lead?.type,lead?.id)
                }
                loading={selectedId === lead?.id}
              />
            </span>
          );
        }
      };
      return {
        id: lead?.id,
        list: {
          title: lead?.name ?? "",
          description:
            lead?.status !== "failed"
              ? lead?.type === "ottolead"
                ? "Purchased"
                : lead?.type === "uploaded"
                ? "Uploaded"
                : lead?.type
              : "Failed",
          status: lead?.status,
        },
        uploadDate: timeAgoFormatter(new Date(lead?.createdAt)),
        records:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.recordCount ?? 0)
            : "-",
        phone:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.phonesCount ?? 0)
            : "-",
        downloadList: downloadListStatus(),
      };
    });
    setTableData(list ?? []);
  }, [myLists,selectedId]);

  useEffect(() => {
    handleList();
  }, [myLists,selectedId]);

  useEffect(() => {
    debounce(filter)
  }, [orgId, filter]);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "List page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "List page viewed",
            jsonData: result,
          });
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo]);

  return (
    <div className={`${styles.list}`}>
      <HeroSection title="List"></HeroSection>
      <div className={`${styles.list__filters}`}>
        <SearchBar
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            if (
              e.target.value.length > 0 &&
              e.target.value.trim().length === 0
            ) {
              value = "";
            }
            if (filter.search !== value) {
              setSearchText(value);
            }
          }}
          searchText={filter.search}
        ></SearchBar>
      </div>
      {listLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.list__table}`}>
          <CustomTable
            rows={tableData}
            columns={columns}
            search={filter.search}
            tableStriped
            hasMore={tableData.length < totalCount}
            handleNextPage={handleNextPage}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
              description:
                "We couldn’t find any results that match your search.",
              button1: (
                <Button
                  label="Clear search"
                  className="outline"
                  action={handleClearSearch}
                />
              ),
            }}
            emptyTableMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></CustomTable>
        </div>
      )}
    </div>
  );
};

export default List;
