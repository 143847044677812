import { ToastContainer } from "react-toastify";
import { PropsWithChildren } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";

const Providers = ({ children }: PropsWithChildren<{}>) => {
  return (
    <>
       <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}>
      <ToastContainer />
      {children}
       </APIProvider>
    </>
  );
};

export default Providers;
