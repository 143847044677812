import * as yup from "yup";

export const updateCreditsSchema = yup.object({
  credits: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .min(0, "Credits must be at least 0")
    .max(99999, "Credits must be at most 99999")
    .required("Credits are required")
});