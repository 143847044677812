import React from "react";
import { NavLink, Outlet, useParams, useLocation } from "react-router-dom";
import styles from "./OrganizationDetails.module.scss";
import { tabs } from "./constants";
import { useSelector } from "react-redux";
import { getProfileInfo } from "../profile/selector";
import { Tooltip } from "@mui/material";

const OrganizationDetails = () => {
  const { orgId } = useParams();
  const location = useLocation();

  const profileInfo = useSelector((state) => getProfileInfo(state));
  return (
    <div className={`dflex ${styles.tabs}`}>
      <nav>
        <ul>
          {tabs.map((tab) => (
            <Tooltip
              title={
                profileInfo?.permissions?.includes(tab.permissions)
                  ? ""
                  : "You don't have permission to access this."
              }
            >
              <li
                key={tab.path}
                className={
                  profileInfo?.permissions?.includes(tab.permissions)
                    ? ""
                    : `${styles.tabdisable}`
                }
              >
                <NavLink
                  to={`/organisation/${orgId}/${tab?.path}`}
                  className={
                    location.pathname.includes(tab?.path) ? styles.active : ""
                  }
                >
                  {tab.icon && <tab.icon />}
                  {tab?.label}
                </NavLink>
              </li>
            </Tooltip>
          ))}
        </ul>
      </nav>
      <div className={`${styles.tabs__info}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default OrganizationDetails;
