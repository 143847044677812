import { createAction } from "@reduxjs/toolkit";

export const fetchLeadList = createAction(
  "FETCH_LEAD_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const propertyUpdateDetailsAction = createAction(
    "updatePropertyDetails",
    (data) => ({ payload: data })
  );

export const fetchPropertyDetail = createAction(
    "FETCH_PROPERTY_DETAIL",
    function prepare(data) {
      return { payload: data };
    }
  );

  export const fetchWholesaleCalculatorAction = createAction(
    "fetchWholesaleCalculator",
    function prepare(data) {
      return { payload: data }
  }
  );
  
  export const fetchAmortizationCalculatorAction = createAction(
    "fetchAmortizationCalculator",
    function prepare(data) {
      return { payload: data }
  }
  );

  export const fetchFinanceCalculatorAction = createAction(
    "fetchFinanceCalculator",
    (data) => ({ payload: data })
  );
  
  export const fetchFinanceCalculatorIdAction = createAction(
    "fetchFinanceIdCalculator",
    (data) => ({ payload: data })
  );