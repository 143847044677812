import { Dialog, Tooltip } from "@mui/material";
import React, { useState } from "react";
import Close from "../../assets/icons/cross";
import Input from "../../atoms/Input";
import Button from "../../atoms/Button";
import PlusCircle from "../../assets/icons/plusCircle";
import { useForm } from "react-hook-form";
import { addCreditCount } from "../../organisms/billingHistory/service";
import { useParams } from "react-router-dom";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateCreditsSchema } from "./validation";
import { getProfileInfo } from "../../pages/profile/selector";
import { sendActivityLog } from "../../organisms/auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { PermissionListEnum } from "../../pages/rolesAndPermission/enum";

const UpdateCredits = ({ handleClose, open ,currentCredits,setCurrentCredits}: any) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(updateCreditsSchema)

  });
  const[loading,setLoading]= useState(false)
  const {orgId} = useParams();
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const dispatch= useDispatch()
 
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const onSubmit =async()=>{
    try{
      setLoading(true)
      const payload={
        organisationId: orgId,
        creditCount: watch('credits')
      }
      await addCreditCount(payload);
      setCurrentCredits(watch('credits'))
      handleShowToast( "Credits updated successfully!",ToastType.success);
      setLoading(false)
      handleClose()
      const attributes = {
        action:`User has updated credits of organisation ${orgId} from ${currentCredits} to ${watch('credits')}`,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo.email,
        roleName: profileInfo.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        try {
          const res = await sendActivityLog({
            logType: "User has updated credits.",
            jsonData: result,
          });
        } catch (error) {
          console.error("Error sending activity log:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
      
    }catch(error:any){
      handleShowToast(error?.message,ToastType.error);
      setLoading(false)
    }
  }
  return (
    <>
      <Dialog className="stepsDialog" onClose={handleClose} open={open}>
        <div className="dialogWrapper">
          <div className="dialogWrapper__header">
            <h2>Update user credits</h2>
          </div>
          <span role="link" onClick={handleClose} className="closeDialog">
            <Close />
          </span>

          <Input
            type="number"
            label="Credits*"
            register={register}
            name="credits"
            defaultValue={currentCredits}
            errors={errors}
          ></Input>

          <div className="mainCol actions">
            <div className="mainCol__half">
              <Button
                className="full outline"
                label="Cancel"
                action={handleClose}
                disabled={loading}
              ></Button>
            </div>
           <div className="mainCol__half">
              <Button
                className="full primary"
                label="Update Credits"
                action={handleSubmit(onSubmit)}
                prefix={<PlusCircle />}
                disabled={loading}
                clicked={loading}
                ></Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UpdateCredits;
