import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  invoices: {} as any,
  invoicesIsLoading:false
};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setInvoices: (state, { payload }) => {
      return { ...state, invoices: payload };
    },
    appendInvoices: (state, { payload }) => {
        return {
          ...state,
          invoices: {
            ...payload,
            invoices: [
              ...(state?.invoices?.invoices ? state?.invoices?.invoices : []),
              ...(payload?.invoices ? payload?.invoices : []),
            ],
          },
        };
      },
      setInvoicesIsLoading: (state, { payload }) => { 
        return { ...state, invoicesIsLoading: payload };
      },
  },
});

export const { setInvoices,appendInvoices,setInvoicesIsLoading } = billingSlice.actions;

export default billingSlice.reducer;
