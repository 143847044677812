import { getAPI, postAPI, putAPI } from "../../apis/apis";

export const fetchCampaignData = async (payload:any) => {
    return getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/campaign/campaign/minimal-data?organisationId=${payload}`,
      headers: {},
    });
  };

  export const postFinanceCalculator = async (payload:any) => {
    return postAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/finance`,
      headers: {},
      data: payload,
    });
  };

  export const updateFinanceCalculator = async (payload:any,id:any) => {
    return putAPI({
      url:
      `${process.env.REACT_APP_BASE_URL}/property-calculator/finance/${id}`,
      headers: {},
      data: payload,
    });
  };