import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaAction";
import { getAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchWorkerList() {
  yield put(slice.setWorkerListLoading(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/bot-worker`,
      headers: {},
      data: {},
    });
    console.log(response)
    yield all([put(slice.setWorkerList(response?.data)),
      put(slice.setWorkerListLoading(false)),
    ]);
  } catch (error: any) {
    console.log(error);
    yield put(slice.setWorkerListLoading(false));
  }
}

function* workerListSaga() {
  yield takeLatest(sagaActions.fetchWorkersList, fetchWorkerList);
}

export default workerListSaga;
