import React, { useCallback, useEffect, useState } from "react";
import { followUpDateFormatter } from "../../utils/utils";
import CustomTable from "../../molecules/table/CustomTable";
import { columns } from "./constants";
import HeroSection from "../heroSection/HeroSection";
import { useNavigate, useParams } from "react-router-dom";
import { SortDirection } from "../../molecules/table/enums";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignsList } from "../../pages/campaignDetails/sagaAction";
import styles from "./Campaigns.module.scss";
import {
  getCampaignsList,
  getCampaignsListCount,
  getCampaignsListMore,
  getLoadingCampaignsList,
} from "../../pages/campaignDetails/selector";
import Loader from "../../atoms/Loader";
import SearchReflation from "../../assets/icons/searchReflation";
import SearchBar from "../../atoms/SearchBar";
import Button from "../../atoms/Button";
import { CampaignStatusType } from "./enum";
import { sendActivityLog } from "../auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { PermissionListEnum } from "../../pages/rolesAndPermission/enum";
import { useDbounce } from "../../hooks/debounce.hook";

const Campaigns = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const [detailPermission, setDetailPermission] = useState(false);
  const campaignList = useSelector((state) => getCampaignsList(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const fetchMoreList = useSelector((state) =>  getCampaignsListMore(state));
  const campaignCount = useSelector((state) => getCampaignsListCount(state));
  const CampaignListLoading = useSelector((state) =>
    getLoadingCampaignsList(state)
  );
  const [filter, setFilter] = useState<{
    sortDirection: string;
    sortKey: string;
    index: number;
    size: number;
    search: string;
    filter:string
  }>({
    sortDirection: SortDirection.DESC,
    sortKey: "createdAt",
    index: 1,
    size: 20,
    search:"",
    filter:"all"
  });
  let navigate = useNavigate();

  const logAuditData = async (data:any,logType:any) => {
    const attributes = {
      action: data,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType:logType,
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  
  const debounce = useDbounce(500, (filter:any) => {
    if(!fetchMoreList){
      const payload = { index: filter?.index, size: filter?.size, organisationId:orgId,search: filter?.search,filter: filter?.filter};
      dispatch(fetchCampaignsList(payload));
    }
  });
  
  const handleNext = (id: any) => {
    navigate(`/organisation/${orgId}/campaigns/${id}`);
    logAuditData(`The user has selected campaign ${id} of organisation ${orgId} and is navigating to the campaign detail page.`,`Campaign selected`)
  };


  const setSearchText = (value: string) => {
    setFilter((prev) => ({ ...prev, search: value, index: 1 }));
  };

  const handleClearSearch= (value: string) => {
    setFilter((prev) => ({ ...prev, search: '', index: 1 ,filter: "all"}));
  };

  const handleFilterChange = (filter: string) => {
    setFilter((prev) => ({
      search: '',
       index: 1,
       size: 20,
      filter: filter,
      sortDirection: SortDirection.DESC,
      sortKey: "",
    }));
  };

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev?.index + 1 }));
  };

  const handleCampaignList = useCallback(() => {
    const listData = campaignList?.map((campaign: any) => {
      return {
        ...campaign,
        id: campaign.id,
        callCount: campaign?.callCount != "0" ? campaign?.callCount : "—",
        inQueueCalls:
          campaign?.inQueueCalls != "0" ? campaign?.inQueueCalls : "—",
        leadCount: campaign.leadCount != "0" ? campaign.leadCount : "—",
        hangupCalls: campaign.hangupCalls != "0" ? campaign.hangupCalls : "—",
        deadCalls: campaign.deadCalls != "0" ? campaign.deadCalls : "—",
        wrongNumberCalls:
          campaign.wrongNumberCalls != "0" ? campaign.wrongNumberCalls : "—",
        failedCalls: campaign.failedCalls != "0" ? campaign.failedCalls : "—",
        dncCalls: campaign.dncCalls != "0" ? campaign.dncCalls : "—",
        startDate: followUpDateFormatter(
          campaign.startDate ? new Date(campaign.startDate) : null
        ),
        voiceEmail:
          campaign?.voicemailCalls != "0" ? campaign.voicemailCalls : "—",
      };
    });
    setTableData(listData ?? []);
  }, [campaignList]);

  useEffect(() => {
    handleCampaignList();
  }, [campaignList]);

  useEffect(() => {
    debounce(filter)
  }, [orgId, filter]);

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "Campaign page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "Campaign page viewed",
            jsonData: result,
          });
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
    if(!profileInfo?.permissions?.includes(PermissionListEnum.GET_CAMPAIGN_DETAILS)){
      setDetailPermission(true)
    }
  }, [profileInfo]);

  return (
    <div className={`${styles.campaigns}`}>
      <HeroSection title="Campaigns"></HeroSection>
      <div className={`${styles.campaigns__filters} dflex alignCenter`}>
      <div className={`${styles.campaigns__search}`}>
        <SearchBar
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            if (
              e.target.value.length > 0 &&
              e.target.value.trim().length === 0
            ) {
              value = "";
            }
            if (filter.search !== value) {
              setSearchText(value);
            }
          }}
          searchText={filter.search}
        ></SearchBar>
      </div>
      <div className={`${styles.campaigns__filter}`}>
          <ul className="dflex">
            <li>
              <Button
                label="All Campaigns"
                className={`outline xl ${
                  CampaignStatusType.ALL === filter.filter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.ALL)}
              />
            </li>
            <li>
              <Button
                label="Scheduled Campaigns"
                className={`outline xl scheduled ${
                  CampaignStatusType.SCHEDULED === filter.filter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.SCHEDULED)}
              />
            </li>
            <li>
              <Button
                label="Active Campaigns"
                className={`outline xl active ${
                  CampaignStatusType.ACTIVE === filter.filter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.ACTIVE)}
              />
            </li>
            <li>
              <Button
                label="Completed Campaigns"
                className={`outline xl completed ${
                  CampaignStatusType.COMPLETED === filter.filter
                    ? "selcted-status"
                    : ""
                }`}
                action={() => handleFilterChange(CampaignStatusType.COMPLETED)}
              />
            </li>
          </ul>
        </div>
        </div>
      {CampaignListLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.campaigns__table}`}>
          <CustomTable
            rows={tableData}
            columns={columns}
            currentPageIndex={1}
            tableStriped
            search={filter?.search || (filter?.filter != "all" ? filter?.filter : '')}
            hasMore={tableData.length < campaignCount}
            handleNext={handleNext}
            handleNextPage={handleNextPage}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
              description:
                "We couldn’t find any results that match your search or filtering criteria",
              button1: (
                <Button
                  label="Clear search"
                  className="outline"
                  action={handleClearSearch}
                />
              ),
            }}
            emptyTableMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
            permissionAccess={detailPermission}
          ></CustomTable>
        </div>
      )}
    </div>
  );
};

export default Campaigns;
