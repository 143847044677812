import { initialState } from "./reducer";

const getStateData = (state: any) => state["myListsTable"] || initialState;

export const getMylistData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.myLists;
};

export const getListLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listLoading;
};

export const getMyListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listCount;
};
