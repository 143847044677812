import * as yup from "yup";

export const addRoleSchema = yup.object({
  roleName: yup
  .string()
  .trim()
  .required("Role name is required"),
permissions: yup
.array()
.min(1, "At least one permission must be selected")
.of(yup.string().required()),
});
