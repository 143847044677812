import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
//reducers
import organizationSlice from "../pages/organization/reducer";
import roleListSlice from "../pages/users/reducer";
import toastReducer from "../molecules/toaster/toastSlice";
import authGuardReducer from "../organisms/authroute/reducer";
import roleAndPermissionSlice from "../pages/rolesAndPermission/reducer";
import campaignsListSlice from "../pages/campaignDetails/reducer";
import userDetailSlice from "../pages/userDetail/reducer";
import leadListSlice from "../pages/propertyDetail/reducer"; 
import myListsTableSlice from "../organisms/list/reducer"
import billingSlice from "../pages/historyDetail/reducer"
import profileInfoSlice from "../pages/profile/reducer"
import logActivitySlice from "../pages/LogActivityDrawer/reducer"
import workerListSlice from "../pages/aiBotCalling/reducer"
//saga
import organisationsSaga from "../pages/organization/saga";
import rolesAndPermissionSaga from "../pages/rolesAndPermission/saga";
import usersSaga from "../pages/users/saga";
import CampaignsListSaga from "../pages/campaignDetails/saga";
import UserDetailListSaga from "../pages/userDetail/saga";
import leadListSaga from "../pages/propertyDetail/saga";
import myListsSaga from "../organisms/list/saga";
import billingSaga from "../pages/historyDetail/saga";
import profileInfoSaga from "../pages/profile/saga";
import logActivitySaga from "../pages/LogActivityDrawer/saga";
import workerListSaga from "../pages/aiBotCalling/saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    toasts: toastReducer,
    organization: organizationSlice,
    roleList: roleListSlice,
    authGuard: authGuardReducer,
    roleandPermission: roleAndPermissionSlice,
    campaigns: campaignsListSlice,
    userDetail: userDetailSlice,
    leadList: leadListSlice,
    myListsTable:myListsTableSlice,
    billing:billingSlice,
    profileInfo:profileInfoSlice,
    logActivity: logActivitySlice,
    workerList: workerListSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(organisationsSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(rolesAndPermissionSaga);
sagaMiddleware.run(CampaignsListSaga);
sagaMiddleware.run(UserDetailListSaga);
sagaMiddleware.run(leadListSaga);
sagaMiddleware.run(myListsSaga);
sagaMiddleware.run(billingSaga);
sagaMiddleware.run(profileInfoSaga);
sagaMiddleware.run(logActivitySaga);
sagaMiddleware.run(workerListSaga);
export default store;