import { createAction } from "@reduxjs/toolkit";

export const fetchRolesList = createAction(
  "ROLE_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchPermissionList = createAction(
  "PERMISSION_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

