
const TickIcon = () => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" 
        version="1.2" 
        viewBox="0 0 989 797" 
        width="989" height="797">
      
                <path fill="currentColor"
                d="m4.5 353.3l289.5 432.1c8.9 13.3 28 14.5 38.4 2.4l650.3-750.3c17.4-20.1-8.9-48.2-30-32.1l-647.3 490.1-270.6-173.1c-20.2-13-43.7 11-30.3 30.9z"/>
               

               </svg>

        </>
    );
};

export default TickIcon;
