import CalendarCheckInside from "../assets/icons/calenderCheckInside";
import Check from "../assets/icons/check";
import CheckVerified from "../assets/icons/checkVerified";
import CloseThick from "../assets/icons/closeThick";
import Refund from "../assets/icons/refund";

export const getPillStatusIcon = (status: string) => {
  switch ( status ? status?.toLowerCase() : "") {
    case "follow up": {
      return <CalendarCheckInside></CalendarCheckInside>;
    }
    case "closed deal": {
      return <CheckVerified></CheckVerified>;
    }
    case "paid": {
      return <Check></Check>;
    }
    case "refund": {
      return <Refund></Refund>;
    }
    case "failed": {
      return <CloseThick></CloseThick>;
    }
    default: {
      return <></>;
    }
  }
};
