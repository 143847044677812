import HeroSection from "../../organisms/heroSection/HeroSection";
import styles from "./CampaignDetail.module.scss";
import Chart from "../../molecules/chart/commonChart";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Call from "../../assets/icons/call";
import SearchReflation from "../../assets/icons/searchReflation";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import Loader from "../../atoms/Loader";
import { SortDirection } from "../../molecules/table/enums";
import AnalyticCards from "../../organisms/analyticCards/AnalyticCards";
import {
  firstName,
  formartNumberAsLocalString,
  getDateDifferenceInWords,
  toCamelCase,
  calculateProgress,
  timeAgoFormatter,
} from "../../utils/utils";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomTable from "../../molecules/table/CustomTable";
import { Leads_COLUMN, LeadsDetail_columns, ZONE_TIME } from "./constants";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import {
  getAttemptDetails,
  getCampaignActivityList,
  getCampaignActivityListCount,
  getCampaignData,
  getCampaignLeadList,
  getCampaignLeadListCount,
  getCampaignsLeadListMore,
  getCampaignsList,
  getCampaignsListDetail,
  getGraphData,
  getLoadingCampaignActivityList,
  getLoadingCampaignLeadList,
  getLoadingCampaignsListDetail,
  getTopSectionData,
} from "./selector";
import {
  fecthAttemptIdAction,
  fetchCampaignActivityList,
  fetchCampaignData,
  fetchCampaignLeadsList,
  fetchCampaignsList,
  fetchCampaignsListDetail,
  fetchTopSectionData,
  graphAction,
} from "./sagaAction";
import { fetchleadsExportList } from "./service";
import Button from "../../atoms/Button";
import ArrowUp from "../../assets/icons/arrowUp";
import { column, columns, skipList_column } from "../../organisms/list/constants";
import { Tab, Tabs, Tooltip } from "@mui/material";
import { fetchExportCSVList } from "../../organisms/list/service";
import AlertTriangle from "../../assets/icons/alertTriangle";
import Upload from "../../assets/icons/upload";
import { getProfileInfo } from "../profile/selector";
import { sendActivityLog } from "../../organisms/auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { useForm } from "react-hook-form";
import FilterDropdown from "../../organisms/filterDropdown/FilterDropdown";
import DropDown from "../../atoms/Dropdown";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import Filter from "../../assets/icons/fiter";
import Circle from "../../assets/icons/circle";
import { fetchPropertyDetail } from "../propertyDetail/sagaAction";
import HungUp from "./leadStatusTabs/hungUp/HungUp";
import VoiceMail from "./leadStatusTabs/VoiceMail/VoiceMail";
import Dead from "./leadStatusTabs/dead/Dead";
import WrongNumber from "./leadStatusTabs/WrongNumber/WrongNumber";
import Dnc from "./leadStatusTabs/dnc/Dnc";
import Failed from "./leadStatusTabs/failed/Failed";

type LeadFilterType = "" | "sms" | "call";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    background:
      theme.palette.mode === "light"
        ? "linear-gradient(270deg, #18CEFF -0.12%, #603FFE 100%)"
        : "#308fe8",
  },
}));

const attemptIdMap: any[] = [];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tabs-content">{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const CampaignsDetails = () => {
  const { control, resetField, getValues, setValue,reset } = useForm();
  const { orgId, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignProgress, setCampaignProgress] = useState(0);
  const [leadsdetail, setLeadsDetail] = useState([]);
  const [exportListLoading, setExportListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const[ selectedId, setSelectedId]= useState(-1)
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [openAttemptFilter, setOpenAttemptFilter] = useState<boolean>(false);
  const [anchorAttempt, setAnchorAttempt] = useState<null | HTMLElement>(null);
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const [focused, setFocused] = useState(false);
  const [filterFlag, setFilterFlag] = useState(false);
  const [selectedAttempt, setSelectedAttempt] = useState<string>("");
  const [filter, setFilter] = useState<{
    sortDirection: string;
    sortKey: string;
    leadFilterBy: LeadFilterType;
    index: number;
    size: number;
  }>({
    sortDirection: SortDirection.DESC,
    sortKey: "createdAt",
    leadFilterBy: "",
    index: 1,
    size: 20,
  });
  const [attemptOptions, setAttemptOptions] = useState<
  { value: number; label: any }[]
>([]);
const[listIdArray, setListIdArray] = useState([]);
const [listItems, setListItems] = useState([]);
const [attemptNoState, setAttemptNoState] = useState<string>("");
  const [leadFilter, setLeadFilter] = useState<{
    sortDirection: string;
    sortKey: string;
    leadFilterBy: LeadFilterType;
    index: number;
    size: number;
  }>({
    sortDirection: SortDirection.DESC,
    sortKey: "createdAt",
    leadFilterBy: "",
    index: 1,
    size: 20,
  });
  const response = useSelector((state) => getCampaignData(state));
  const listData = useSelector((state) => getCampaignsListDetail(state));
  const leadsData = useSelector((state: any) => getCampaignLeadList(state));
  const total = useSelector((state: any) => getCampaignLeadListCount(state));
  const isLeadsLoading = useSelector((state: any) =>
    getLoadingCampaignLeadList(state)
  );
  const isListLoading = useSelector((state: any) =>
    getLoadingCampaignsListDetail(state)
  );
  const activityData = useSelector((state: any) =>
    getCampaignActivityList(state)
  );
  const ActivityTotal = useSelector((state: any) =>
    getCampaignActivityListCount(state)
  );
  const isActivityLoading = useSelector((state: any) =>
    getLoadingCampaignActivityList(state)
  );
  const sectionData = useSelector((state: any) => getTopSectionData(state));
  const graphData = useSelector((state) => getGraphData(state));
  const attemptsData = useSelector((state) => getAttemptDetails(state));
  const campaignLeadListMore = useSelector((state) => getCampaignsLeadListMore(state));
  const existingData = graphData?.data?.result || [];
  const [value, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const newData = existingData.some((point: any) => point.timeStamp === "00:00")
    ? existingData
    : [{ timeStamp: "00:00", count: 0 }, ...existingData];

  const xValues =
    newData?.map((point: any) => {
      if (point.timeStamp === "00:00") {
        return "Start";
      }
      const [hours, minutes] = point.timeStamp?.split(":")?.map(Number);
      if (hours === 0 && minutes > 0) {
        return `${minutes} m`;
      } else if (hours > 0 && minutes === 0) {
        return `${hours} h`;
      } else if (hours > 0 && minutes > 0) {
        return `${hours} h ${minutes} m`;
      } else {
        return "Invalid time format";
      }
    }) || [];

  const options = {
    colors: ["#005DA4"],
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "18px",
        borderRadius: 4,
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    xaxis: {
      categories: xValues,
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "Calls Sent",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
  };

  const yValues =
    graphData?.data?.result?.map((point: any) => {
      const count = point?.count;
      return count;
    }) || [];

  const cards = [
    {
      title: "Leads",
      statusKey: "todayLeadCount",
      statusToday: true,
      value: response?.totalLeadCount,
      key: "totalLeadCount",
    },
    {
      title: "Total Calls",
      value: response?.totalCallCount,
      statusToday: true,
      statusKey: "totalCallCount",
      key: "totalCallCount",
    },
    {
      title: "Conversations",
      value: response?.totalConversion,
      statusToday: true,
      statusKey: "totalConversion",
      key: "totalConversion",
    },
    {
      title: "Voicemail",
      value: response?.voiceMail,
      key: "voiceMail",
    },
  ];

  const series = [
    {
      name: "Calls Sent",
      data: yValues,
    },
  ];

  const handleRowOnClick = (id: string | number) => {
    navigate(`/organisation/${orgId}/leads/${id}`);
    dispatch(fetchPropertyDetail({propertyId: id,orgId: orgId,}))
    window.scrollTo(0, 0);
  };

  const handleNextActivityPage = () => {
    const payload = {
      organisationId:orgId,
      campaignId:id,
      size: filter?.size,
      index: filter?.index + 1,
    };
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
    dispatch(fetchCampaignActivityList(payload));
  };

  const handleNextPage = () => {
    if(!campaignLeadListMore){ 
      const payload = {
      organisationId:orgId,
      campaignId:id,
      size: leadFilter?.size,
      index: leadFilter?.index + 1,
    };
    setLeadFilter((prev) => ({ ...prev, index: prev.index + 1 }));
    dispatch(fetchCampaignLeadsList(payload));
  }
  };

  const handleAttemptSelect = (value: any) => {
    attemptIdMap?.map((el) => {
      if (el?.value === value?.value) {
        setSelectedAttempt(el?.id);
      }
    });
  };

  const handleCSVData = async (data: any) => {
    setSelectedId(data);
    try {
      const response = await fetchExportCSVList({id:data,orgId})
      const listingData = response?.data?.data?.map((list: any) => {
        return {
          nameFirst: list?.owner[0]?.nameFirst ?? "",
          nameLast: list?.owner[0]?.nameLast ?? "",
          addressStreet: list?.addressStreet ?? "",
          addressCity: list?.addressCity ?? "",
          addressState: list?.addressState ?? "",
          addressZip: list?.addressZip ?? "",
          phone1: list?.phones[0]?.number ?? "",
          phone2: list?.phones[1]?.number ?? "",
          phone3: list?.phones[2]?.number ?? "",
        };
      });
      setSelectedId(-1);
      return listingData; 
    } catch (err) {
      setSelectedId(-1);
    }
  };


  const handleSkipData = async (data: any) => {
    setSelectedId(data);
    try {
      const response = await fetchExportCSVList({id:data,orgId});
      const listingData = response?.data?.data?.map((list: any) => {
        return {
          Owner1FirstName:`"${ list.skipData?.jsonData?.output?.identity?.names[0]?.firstName ?? "" }"`,
          Owner1LastName:
           `"${ list.skipData?.jsonData?.output?.identity?.names[0]?.lastName ?? ""}"`,
          Owner2FirstName:
           `"${ list.skipData?.jsonData?.output?.identity?.names[1]?.firstName ??
            "" }"`,
          Owner2LastName:
           `"${ list.skipData?.jsonData?.output?.identity?.names[1]?.lastName ?? ""}"`,
          MailingStreetAddress:
           `"${ list?.searchData?.jsonData?.mailAddress?.street ?? ""}"`,
          MailingCity: `"${list?.searchData?.jsonData?.mailAddress?.city ?? ""}"`,
          MailingState: `"${list?.searchData?.jsonData?.mailAddress?.state ?? ""}"`,
          MailingZip:`"${ list?.searchData?.jsonData?.mailAddress?.zip ?? ""}"`,
          MailingCounty: `"${list?.searchData?.jsonData?.mailAddress?.county ?? ""}"`,
          PropertyStreetAddress:
           `"${list?.searchData?.jsonData?.address?.street ?? ""}"`,
          PropertyCity: `"${list?.searchData?.jsonData?.address?.city ?? "" }"`,
          PropertyState: `"${list?.searchData?.jsonData?.address?.state ?? ""}"`,
          PropertyZip:`"${ list?.searchData?.jsonData?.address?.zip ?? ""}"`,
          PropertyCounty: `"${list?.searchData?.jsonData?.address?.county ?? ""}"`,
          Email1:
            list.skipData?.jsonData?.output?.identity?.emails[0]?.email ?? "",
          Email2:
            list.skipData?.jsonData?.output?.identity?.emails[1]?.email ?? "",
          Phone1:
            list.skipData?.jsonData?.output?.identity?.phones[0]?.phone ?? "",
          Phone2:
            list.skipData?.jsonData?.output?.identity?.phones[1]?.phone ?? "",
          Phone3:
            list.skipData?.jsonData?.output?.identity?.phones[2]?.phone ?? "",
          Phone4:
            list.skipData?.jsonData?.output?.identity?.phones[3]?.phone ?? "",
          Phone5:
            list.skipData?.jsonData?.output?.identity?.phones[4]?.phone ?? "",
          Phone6:
            list.skipData?.jsonData?.output?.identity?.phones[5]?.phone ?? "",
          IsVacant: `"${list?.searchData?.jsonData?.vacant ?? ""}"`,
          IsAbsentee: `"${list?.searchData?.jsonData?.absenteeOwner ?? ""}"`,
          APN: `"${list?.searchData?.jsonData?.apn ?? ""}"`,
          PropertyType:`"${list?.searchData?.jsonData?.propertyType ?? "" }"`, 
          PropertyUse: `"${list?.searchData?.jsonData?.propertyUse ?? ""}"`,
          BedroomCount: `"${list?.searchData?.jsonData?.bedrooms ?? ""}"`,
          BathroomCount: `"${list?.searchData?.jsonData?.bathrooms ?? ""}"`,
          BuildingSquareFeet: `"${list?.searchData?.jsonData?.squareFeet ?? ""}"`,
          LotSquareFeet: `"${list?.searchData?.jsonData?.lotSquareFeet ?? ""}"`,
          YearBuilt:`"${ list?.searchData?.jsonData?.yearBuilt ?? ""}"`,
          AssessedValue: `"${list?.searchData?.jsonData?.assessedValue ?? ""}"`,
          LastSaleDate: `"${list?.searchData?.jsonData?.lastSaleDate ?? ""}"`,
          LastSalePrice: `"${list?.searchData?.jsonData?.lastSaleAmount ?? ""}"`,
          MortgageBalanceOpen:
            `"${list?.searchData?.jsonData?.openMortgageBalance ?? ""}"`,
          MortgageLenderName: `"${list?.searchData?.jsonData?.lenderName ?? ""}"`,
          MortgageType: `"${list?.searchData?.jsonData?.lenderName ?? ""}"`,
          EstimatedEquity: `"${list?.searchData?.jsonData?.estimatedEquity ?? ""}"`,
          EstimatedValue: `"${list?.searchData?.jsonData?.estimatedValue ?? ""}"`,
          MLSStatus: `"${list?.searchData?.jsonData?.mlsActive ?? ""}"`,
          MLSListingDate: `"${list?.searchData?.jsonData?.mlsListingDate ?? ""}"`,
          MLSListingPrice: `"${list?.searchData?.jsonData?.mlsListingPrice ?? ""}"`,
            AISummary: `"${list?.aiSummary ?? ""}"`
        };
      });
     setSelectedId(-1);
      return listingData;
    } catch (err) {
      setSelectedId(-1);
    } 
  };

  const handleListDownload = async (type: string, id: any) => {
    logAuditData(`User is downloading list id : ${id}`,"Download List")
    if (type === "uploaded") {
      return await handleCSVData(id);
    } else {
      return await handleSkipData(id);
    } 
  };

  const handleDownload = async () => {
    try {
      setExportListLoading(true);
      const payload = {
        orgId,
        id,
        size: total,
        index: filter?.index,
      };
      const result: any = await fetchleadsExportList(payload);
      setExportListLoading(false);
      logAuditData("Downloaded leads list by clicking on export to CSV","Downloaded Leads List")
      return result?.data?.data.map((item: any) => ({
        ...item,
        summary: `"${item.summary}"`,
      })); 
    } catch (error) {
      setExportListLoading(false);
    }
  };

  const logAuditData = async (data:any,logType:any) => {
    const attributes = {
      action: data,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      try {
        const res = await sendActivityLog({
          logType:logType,
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handleFilterBtn = (event: any) => {
    setAnchorAttempt(event.currentTarget);
    setOpenAttemptFilter(true);
    setFocused(true);
    setValue("attemptList", attemptNoState);
  };

  const handleAttemptClose = () => {
    setAnchorAttempt(null);
    setFocused(false);
  };

  const handleResetBtn = () => {
    resetField("attemptList");
    reset({lists:[]})
    setListIdArray([])
    setAttemptNoState("");
    setSelectedAttempt(() => "");
    setFilterFlag(false);
    handleAttemptClose();
  };

  const handleApplyBtn = useCallback(() => {
    setListIdArray(getValues()?.lists)
    let newSelectedAttempt = selectedAttempt;
 if (getValues("attemptList")?.length === 0 && getValues("lists")?.length <= 0 ) {
      resetField("attemptList");
      setAttemptNoState("");
      newSelectedAttempt = "";
      setFilterFlag(false);
      handleAttemptClose();
      return;
    }
    const payload = {
      attempt_id:selectedAttempt,
      list_ids: getValues()?.lists,
      organisationId:orgId,
      campaignId:id,
      size: filter?.size,
      index: filter?.index,
    };
 
    setSelectedAttempt(newSelectedAttempt);
    dispatch(fetchCampaignData(payload));
    dispatch(fetchCampaignLeadsList(payload));
    dispatch(fetchCampaignActivityList(payload));
    dispatch(fetchTopSectionData(payload));
    dispatch(fecthAttemptIdAction(payload));
    dispatch(graphAction(payload));
    if (getValues("attemptList") || getValues("lists")) {
      setAttemptNoState(getValues("attemptList"));
      setFilterFlag(true);
    }
    handleAttemptClose();
  }, [selectedAttempt,listIdArray]);


  useEffect(() => { 
  setListItems(
          listData?.map((item: { id: string; name: string }) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      
  
  }, [listData]);

  useEffect(() => {
    const payload = {
      organisationId: orgId,
      campaignId:id,
      size: filter?.size,
      index: filter?.index,
    };
    if (selectedAttempt === "" && listIdArray?.length <=0) {
    dispatch(fetchCampaignData(payload));
    dispatch(fetchCampaignLeadsList(payload));
    dispatch(fetchCampaignActivityList(payload));
    dispatch(fetchTopSectionData(payload));
    dispatch(fecthAttemptIdAction(payload));
    dispatch(graphAction(payload));
    dispatch(fetchCampaignsListDetail(payload));
    }
  }, [orgId, id,listIdArray]);

  useEffect(() => {
    if (sectionData?.response?.data?.statusCode === 500) {
      navigate("/organisations");
      dispatch(
        addToast({
          id: new Date().getTime(),
          message: "Campaign does not exist!",
          type: ToastType.error,
        })
      );
    }
    if (sectionData?.response?.data?.statusCode === 404) {
      navigate("/campaigns");
      dispatch(
        addToast({
          id: new Date().getTime(),
          message: "Campaign does not exist!",
          type: ToastType.error,
        })
      );
      //   dispatch(setTopSectionData({}));
    }
  }, [sectionData]);

  useEffect(() => {
    const newAttemptOptions = [];
    for (let i = 0; i < sectionData?.data?.totalAttempt; i++) {
      const attempt = attemptsData[i];
      const zoneTime = ZONE_TIME.find(
        (zoneObj: any) => zoneObj?.zone === attempt?.zone
      );
      const labelText = zoneTime ? zoneTime.time_zone : "";
      const label = <span className="time-pill">{labelText}</span>;
      newAttemptOptions.push({
        value: i + 1,
        label: `Attempt ${i + 1}`,
        pill: label,
      });
    }
    setAttemptOptions(newAttemptOptions);
    attemptsData?.map((el: any, index: number) => {
      attemptIdMap.push({ value: index + 1, id: el?.id });
    });
    if (newAttemptOptions?.length > attemptsData?.length) {
      const difference = newAttemptOptions?.length - attemptsData?.length;
      for (let i = 1; i <= difference; i++) {
        attemptIdMap.push({ value: attemptsData?.length + i, id: "0" });
      }
    }
  }, [sectionData?.data?.totalAttempt, attemptsData]);

  useEffect(() => {
    let progress = calculateProgress(attemptsData ?? []);
    setCampaignProgress(progress);
  }, [attemptsData]);

  useEffect(() => {
    const leadData: any = leadsData?.map((lead: any) => {
      const date = getDateDifferenceInWords(new Date(lead?.createdAt), true);
      return {
        datecollected: date,
        lead: {
          title: `${firstName(lead?.name_first)} ${toCamelCase(
            lead?.name_last
          )}`,
          description: [lead?.street, ", ", lead?.city, ", ", lead?.state],
        },
        handleOnClicked: () => handleRowOnClick(lead.id),
        activityDrawerLeadId: false,
      };
    });
    setLeadsDetail(leadData ?? []);
  }, [leadsData]);

 
  useEffect(() => {
    let list = listData?.map((lead: any) => {
      const downloadListStatus = () => {
        if (lead?.status === "uploading") {
          return (
            <div className="disable-icon">
              <Upload />
            </div>
          );
        } else if (lead?.status === "failed") {
          return (
            <Tooltip title={"Failed"}>
              <div className="disable-icon">
                <AlertTriangle />
              </div>
            </Tooltip>
          );
        } else if (lead?.status === "success") {
         return ( 
            <span role="link" key={lead?.id}>
              <ExportToCsv
                headers={lead?.type === "uploaded" ? column : skipList_column}
                filename={`${lead?.name}.csv`}
                loadData={()=>handleListDownload(lead.type,lead?.id)}
                loading={selectedId === lead?.id}
              />
            </span>
          );
        }
      };
      return {
        id: lead?.id,
        list: {
          title: lead?.name ?? "",
          description:
            lead?.status !== "failed"
              ? lead?.type === "ottolead"
                ? "Purchased"
                : lead?.type === "uploaded"
                ? "Uploaded"
                : lead?.type
              : "Failed",
          status: lead?.status,
        },
        uploadDate: timeAgoFormatter(new Date(lead?.createdAt)),
        records:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.recordCount ?? 0)
            : "-",
        phone:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.phonesCount ?? 0)
            : "-",
          
        downloadList: downloadListStatus(),
      };
    });
    setTableData(list ?? []);
  }, [listData,selectedId]);

  useEffect(() => {
   logAuditData(`Viewed campaign detail page of ID ${id}`,"Campaign Detail page viewed")
  }, [profileInfo,id]);

  return (
    <>
      <HeroSection
        title={sectionData?.data?.campaign}
        campaign={
          sectionData?.data?.campaignStatus
            ? sectionData?.data?.campaignStatus
            : ""
        }
      ></HeroSection>

      <div className={` ${styles.progress} dflex`}>
        <div className={` ${styles.progress__bar}`}>
          <p>Your campaign is {campaignProgress}% of the way there!</p>
          <BorderLinearProgress
            variant="determinate"
            value={campaignProgress}
          />
        </div>
        <div className={` ${styles.progress__button} dflex alignCenter`}>
            <Button
              label="Advanced Filter"
              className={`outline filter-icon ${focused ? `focused` : ``}`}
              prefix={<Filter />}
              action={handleFilterBtn}
              postfix={filterFlag ? <Circle color="#D92D20" /> : null}
            />
            {openAttemptFilter && (
              <FilterDropdown
                handleClose={()=>{handleAttemptClose();
                  setValue("lists",listIdArray)}}
                anchorEl={anchorAttempt}
              >
                <div className={`filterMenu`}>
                  <div className="filterMenu__info multiSelectText">
                    <DropDown
                      options={attemptOptions}
                      control={control}
                      name="attemptList"
                      label="Attempt"
                      placeholder={
                        sectionData?.data?.totalAttempt > 0
                          ? "Select an Attempt"
                          : "No Attempts"
                      }
                      handleOnChange={handleAttemptSelect}
                    ></DropDown>
                    <p>List</p>
                    <MultiSelectDropDown
                      options={listItems}
                      label="Select a list"
                      name="lists"
                      control={control}
                      emptySearchMetaData={{
                        icon: <SearchReflation />,
                        title: "No results found",
                      }}
                      placeholderTextStyle
                    />
                  </div>
                </div>
                <div className={`dflex filterMenuFooter`}>
                  <div className={`filterMenuFooter__half`}>
                    <Button
                      label="Reset"
                      className="full outline"
                      action={handleResetBtn}
                    />
                  </div>
                  <div className={`filterMenuFooter__half`}>
                    <Button
                      label="Apply"
                      className="primary full"
                      action={handleApplyBtn}
                      // clicked={isAttemptSelected}
                      // disabled={isAttemptSelected}
                    />
                  </div>
                </div>
              </FilterDropdown>
            )}
          </div>
      </div>
      <div className={`dflex ${styles.cards}`}>
        {cards?.map((card: any, i: number) => {
          return (
            <div className={`${styles.cards__col}`} key={card.title + i}>
              <AnalyticCards
                key={card.title + i}
                title={card?.title}
                value={formartNumberAsLocalString(response?.[card?.key] ?? 0)}
                statusToday={card?.statusToday}
                showStatusKey={Boolean(Number(response?.[card?.statusKey]) > 0)}
              ></AnalyticCards>
            </div>
          );
        })}
      </div>
      <div className={`dflex ${styles.callAndActivity}`}>
        <div className={` ${styles.callAndActivity__call}`}>
          <h3>Call Tracker</h3>
          <p>Track the delivery of your campaign over time.</p>
          <Chart options={options} series={series} type="area"></Chart>
        </div>
        <div className={` ${styles.callAndActivity__activity}`}>
          <h2>Activity</h2>

          {isActivityLoading ? (
            <div className="table-loader">
              <Loader></Loader>
            </div>
          ) : (
            <div className={` ${styles.callInfo} `} id="scrollableDiv1">
              <InfiniteScroll
                dataLength={activityData?.length ?? ""}
                next={() => handleNextActivityPage && handleNextActivityPage()}
                hasMore={activityData?.length < ActivityTotal}
                scrollableTarget="scrollableDiv1"
                loader={
                  <div className="table-loader">
                    <Loader></Loader>
                  </div>
                }
              >
                <ul>
                  {activityData?.length ? (
                    activityData?.map((card: any, i: number) => {
                      const date = getDateDifferenceInWords(
                        new Date(card?.createdAt),
                        true
                      );
                      return (
                        <li>
                          <figure>
                            <Call />
                          </figure>
                          <figcaption>
                            <h5>
                              {firstName(card?.name_first)}{" "}
                              {toCamelCase(card?.name_last)} <span>{date}</span>
                            </h5>
                            <p style={{ textTransform: "capitalize" }}>
                              {card?.street}, {card?.city}, {card?.state}{" "}
                            </p>
                          </figcaption>
                        </li>
                      );
                    })
                  ) : (
                    <EmptyPlaceholder
                      icon={<SearchReflation />}
                      title="No results found"
                      smallSize
                    />
                  )}
                </ul>
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
      

      <div className={` ${styles.leadsTab}`}>
         <Tabs value={value} onChange={handleTabChange}  variant="scrollable">
         <Tab label="New Leads" {...a11yProps(0)} />
          <Tab label="Hung Up" {...a11yProps(1)} />
          <Tab label="Voice Mail" {...a11yProps(2)} />
          <Tab label="Dead" {...a11yProps(3)} />
          <Tab label="Wrong Number" {...a11yProps(4)} />
          <Tab label="DNC" {...a11yProps(5)} />
          <Tab label="Failed" {...a11yProps(6)} />
        </Tabs>

        <CustomTabPanel value={value} index={0}>
        <div className={`${styles.lead}`}>
        <div
          className={`dflex justifySpaceBetween alignCenter ${styles.lead__header}`}
        >
          <div>
            <h2 className="flex alignCenter">
              Leads{" "}
              {total > 0 && (
                <span
                  className={`inlineFlex justifyCenter alignCenter ${styles.pill}`}
                >
                  {/* {total} Leads */}
                  {total === 1 ? `1 Lead` : `${total} Leads`}
                </span>
              )}
            </h2>
            <p>See a list of your collected leads.</p>
          </div>
          <div className={`dflex ${styles.buttonRight}`}>
            <ExportToCsv
              headers={LeadsDetail_columns.map((column: any) => {
                return { id: column.key, displayName: column.label };
              })}
              filename={`Leads.csv`}
              loadData={() => handleDownload()}
              label="Export to CSV"
              loading={exportListLoading}
            ></ExportToCsv>
            <Button
              label="Go to Lead Manager"
              className="primary"
              prefix={<ArrowUp />}
              action={() => {
                navigate(`/organisation/${orgId}/leads`);
              }}
            />
          </div>
        </div> 
        <div className={` ${styles.lead__list}`}>
          {isLeadsLoading ? (
            <div className="table-loader">
              <Loader></Loader>
            </div>
          ) : (
            <CustomTable
              rows={leadsdetail} 
              columns={Leads_COLUMN}
              hasMore={leadsdetail?.length < total}
              tableStriped
              currentPageIndex={1}
              emptyTableMetaData={{
                icon: <SearchReflation />,
                title: "No results found",
              }}
              sortDirection={filter.sortDirection}
              sortKey={filter.sortKey}
              handleNextPage={handleNextPage}
            ></CustomTable>
          )}
        </div>
      </div>
      </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
              <HungUp/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <VoiceMail/>
      </CustomTabPanel>
     
      <CustomTabPanel value={value} index={3}>
        <Dead/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
      <WrongNumber/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Dnc/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <Failed/>
      </CustomTabPanel>
    </div>



      <div className={` ${styles.lead}`}>
      <div
          className={` ${styles.lead__header}`}
        >
          <div>
            <h2 className="flex alignCenter">
              List{" "}
            </h2>
            <p>See a list of your collection.</p>
          </div>
          </div>
      {isListLoading ? (
            <div className="table-loader">
              <Loader></Loader>
            </div>
          ) : (
        <CustomTable
            rows={tableData}
            columns={columns}
            search={""}
            tableStriped
            
            emptyTableMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></CustomTable>)}
        </div>
    </>
  );
};
export default CampaignsDetails;
