import { getAPI } from "../../apis/apis";

export const fetchleadsExportList = (payload:any) => {
    const {index,size,search,id,orgId}=payload
    return getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/campaign/leads/${id}?organisationId=${orgId}&index=${index}&size=${size}`,
      headers: {},
    });
  };

  export const fetchLeadStatus = (payload:any) => {
    const { id ,status,preview} = payload;
    return getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/admin/campaign/attempt-status-data/${id}?status=${status}&isPreview=${preview}`,
      headers: {},
    });
  };