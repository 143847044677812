import axios from "axios";

interface ApiPayload {
  data?: any;
  url: string;
  headers?: any;
}
export const getAPI = (payload: ApiPayload): Promise<any> => {
  return axios.get(payload.url, {
    params: payload?.data,
    headers: { ...payload.headers },
  });
};

export const postAPI = (payload: ApiPayload): Promise<any> => {
  return axios.post(payload.url, payload.data, {
    headers: { ...payload.headers },
  });
};

export const putAPI = (payload: ApiPayload) => {
  return axios.put(payload.url, payload.data, {
    headers: { ...payload.headers },
  });
};

export const deleteAPI = (payload: ApiPayload) => {
  return axios.delete(payload.url, {
    headers: { ...payload.headers },
  });
};


