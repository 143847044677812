


const CrossIcon = () => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" 
version="1.2" 
viewBox="0 0 868 797" 
width="868" 
height="797">
    
    <path fill="currentColor" d="m41.1 792.9l392.8-331.8 392.9 331.8c5.7 4.8 14.2 4.3 19.3-1.2l17.5-18.7c5.1-5.4 5.1-13.8 0-19.2l-345.1-364.1 305.1-257.7c13.8-11.7 15-32.6 2.6-45.7l-71.6-75.8c-12.4-13.2-33.3-13.2-45.8-0.1l-274.9 290.1-274.9-290.1c-12.4-13.1-33.3-13.1-45.7 0.1l-71.6 75.8c-12.4 13.1-11.2 34 2.6 45.7l305.1 257.7-345.1 364.1c-5.1 5.4-5.1 13.8 0 19.2l17.4 18.7c5.2 5.5 13.7 6 19.4 1.2z"/>
  
            </svg>

        </>
    );
};

export default CrossIcon;