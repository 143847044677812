import { Column } from "../../models/table.interface";

export const columns: Column[] = [
    {
      id: "host",
      label: "Host",
      minWidth: 30,
      type: "",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 70,
      type: "",
    },
    { id: "peakConcurrentSTT", label: "Peak Concurrent STT", minWidth: 70, type: "" },
    { id: "activeCalls", label: "Active Calls", minWidth: 70, type: "" },
    { id: "inQueue", label: "In Queue", minWidth: 70, type: "" },
    { id: "callsPendingIntro", label: "Calls Pending Intro", minWidth: 70, type: "" },
    { id: "humanStreamEstablished", label: "Human Stream Established", minWidth: 70, type: "" },
    {id:"button",label:"", minWidth: 70, type: ""}
  ];