import { MenuItem, Select } from "@mui/material";
import DropDownIcon from "../assets/icons/dropDownIcon";
import Tick from "../assets/icons/tick";
import { Controller } from "react-hook-form";
import EmptyPlaceholder from "./EmptyPlacholder";
import { formartNumberAsLocalString } from "../utils/utils";

export interface MultiDropDownOption {
  value: any;
  label: string;
}
const MultiSelectDropDown = ({
  options,
  label,
  control,
  name,
  displayLabel,
  transformValue,
  displayLength,
  emptySearchMetaData,
  errors,
  placeholderTextStyle
}: any) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, ...props }) => (
          <div className="multiSelect" data-testid="multiSelect">
            <Select
              value={field?.value ? field?.value : []}
              fullWidth
              label={label}
              multiple
              displayEmpty
              onChange={
                transformValue
                  ? (e) => {
                      const newValue = transformValue(e.target.value);
                      field.onChange(newValue);
                    }
                  : field.onChange
              }
              MenuProps={{ classes: { paper: "multiSelectOverlay" } }}
              IconComponent={(props) => {
                if (props.className.includes("MuiSelect-iconOpen")) {
                  return (
                    <span className={`dropdownIcon opened`} role="link">
                      <DropDownIcon />
                    </span>
                  );
                }
                return (
                  <span className={`dropdownIcon`} role="link">
                    <DropDownIcon />
                  </span>
                );
              }}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return <>
                  <span className={placeholderTextStyle ? "multiSelect__placeholderText" : ""}>{label}</span>
                  
                  </>;
                }
                const selectedValues = options
                  .filter((option: any) => selected?.includes(option.value))
                  .map((el: any) => el.label);
                return displayLabel ? (
                  <div className="multiselectValue">
                    <span>{`${displayLabel} `}</span>
                    {displayLength ? `(${displayLength})` : ""}
                  </div>
                ) : (
                  selectedValues.join(", ")
                );
              }}
              data-testid="multiSelectOverlay"
            >
              {options.length > 0 ? (
                
                options.map((option: any, index: number) => (
                  <MenuItem
                    className="multiSelectItem"
                    value={option.value}
                    key={index}
                    data-testid="multiSelectItem"
                  >
                    
                    <div className="flex optionList alignCenter justifySpaceBetween">
                      <div className="optionList__left">{option.label}</div>
                      <div className="flex alignCenter optionList__records">
                      {option.count? <div className="options-records">{formartNumberAsLocalString(option.count)} records</div>:""}
                      <div className="optionList__right flex ">
                     <Tick />
                      </div>
                    </div>
                    </div>
                  </MenuItem>
                ))
              ) : (
                <EmptyPlaceholder
                  icon={emptySearchMetaData.icon}
                  title={emptySearchMetaData.title}
                  description={emptySearchMetaData.description}
                />
              )}
            </Select>
            {errors && errors?.[name] && (
              <span className="error">{errors?.[name]?.message}</span>
            )}
            {errors && errors?.message && (
              <span className="error">{errors?.message}</span>
            )}
          </div>
        )}
      ></Controller>
    </>
  );
};

export default MultiSelectDropDown;
