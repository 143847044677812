import React, { useEffect, useState } from "react";
import CustomTable from "../../molecules/table/CustomTable";
import {
  getActiveListCount,
  getActiveListLoading,
  getActiveUserList,
} from "../../pages/users/selector";
import { useSelector } from "react-redux";
import { columns } from "./constants";
import { capitalizeFirstLetter, followUpDateFormatter } from "../../utils/utils";
import Loader from "../../atoms/Loader";
import { sendActivityLog } from "../auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";

const ActiveUsers = () => {
  const activeList = useSelector((state) => getActiveUserList(state));
  const total = useSelector((state) => getActiveListCount(state));
  const loading = useSelector((state) =>  getActiveListLoading(state));
  const [tableData, setTableData] = useState([]);
  
  const profileInfo = useSelector((state) => getProfileInfo(state));

  useEffect(() => {
    const listData = activeList?.map((list: any) => {
      return {
        name: `${list?.nameFirst} ${list?.nameLast}`,
        email: list?.email,
        roleName: list.roleName?.split("_")
          .map((word: any) => capitalizeFirstLetter(word))
          .join(" "),
        createdAt: followUpDateFormatter (
          list?.createdAt ? new Date(list?.createdAt) : null
        )
      };
    });
    setTableData(listData ?? []);
  }, [activeList]);


  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "View active user tab",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo.email,
        roleName: profileInfo.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "View active user tab",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };

    logAuditData();
  }, [profileInfo]);

  return (
    <>{
      loading? 
      <div className="table-loader">
      <Loader></Loader>
    </div>
      :
      <CustomTable
        rows={tableData}
        columns={columns}
        search={""}
        tableStriped
        hasMore={tableData.length < total}
      ></CustomTable>
    }
    </>
  );
};

export default ActiveUsers;
