import { initialState } from "./reducer";

const getStateData = (state: any) => state["roleandPermission"] || initialState;

export const getRolesList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.roleList;
}

export const getPermissionList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.permissionList;
}

export const getRolesListCount=(state:any)=>{
  const reducer= getStateData(state);
  return reducer.roleListCount;
}

export const getPermissionListLoading=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.permissionLoading;
}

export const getRolesListLoading=(state:any)=>{
  const reducer= getStateData(state);
  return reducer.roleListLoading;
}
