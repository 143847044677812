import { createAction } from "@reduxjs/toolkit";

export const fetchOrganizationList = createAction(
  "ORGANIZATION_LIST",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchOrganization = createAction(
  "ORGANIZATION",
  function prepare(data) {
    return { payload: data };
  }
);

export const fetchOrganizationSubscription = createAction(
  "ORGANIZATION_SUBSCRIPTION",
  function prepare(data) {
    return { payload: data };
  }
);