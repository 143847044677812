
import Pill from "../../atoms/Pill";
import { getPillStatusIcon } from "../../atoms/PillStatusIcon";
import { getPillStatus } from "../../constant/status.constant";
import styles from "./HeroSection.module.scss";
import { HeroSectionProps } from "./interface";

const HeroSection = ({
  title,
  desc,
  campaign,
  children,
}: HeroSectionProps) => {
 return (
    <>
      <div className={`${styles.hero} flex alignCenter`}>
        <div className={`${styles.hero__inner}`}>
          <div
            className={`dflex justifySpaceBetween alignCenter ${styles.heroInfo}`}
          >
            <div>
              <h1>
                {title}
              
          
              </h1>
              {desc && <p>{desc}</p>}
              {campaign && (
                <p className={`${styles.campaigns}`} >
                  Campaign Status:{" "}
                  <Pill
                    className={getPillStatus(campaign)}
                    infix={getPillStatusIcon(campaign)}
                    label={campaign.charAt(0).toUpperCase() + campaign.slice(1)}
                  />
                </p>
              )}
            </div>
            <div className={`dflex ${styles.heroButton}`}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
