import { Column } from "../../models/table.interface";

export const columns: Column[] = [
    {
      id: "name",
      label: "Name",
      minWidth: 30,
      type: "",
     
    },
    {
      id: "email",
      label: "Email",
      minWidth: 70,
      type: "",
    },
    { id: "roleName", label: "Role", minWidth: 70, type: "" },
    { id: "resendInvite", label: "", minWidth: 70, type: "icon" },
  ];