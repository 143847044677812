import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  roleList: [] as any[],
  permissionList:[],
  roleListCount:0,
  roleListLoading:false,
  permissionLoading:false
};

const roleAndPermissionSlice = createSlice({
  name: "roleandPermission",
  initialState,
  reducers: {
    setRolesList: (state, { payload }) => {
      return {
        ...state,
        roleList: payload,
      };
    },
    setPermissionList: (state, { payload }) => {
      return {
        ...state,
        permissionList: payload,
      };
    },
    setRolesListCount:(state,{payload})=>{
      return{
        ...state,
        roleListCount:payload
      }
    },
    appendRolesList: (state, { payload }) => {
      return {
        ...state,
        roleList: [...state.roleList, ...(payload ? payload :[])],
      };
    },
    setPermissionsLoading:(state,{payload}) =>{
      return {
        ...state,
        permissionLoading:payload,
      }
    },
    setRoleListLoading:(state,{payload}) =>{
      return {
        ...state,
        roleListLoading:payload,
      }
    }
  },
});

export const { setRolesList,setPermissionList,setRolesListCount,appendRolesList,setRoleListLoading ,setPermissionsLoading} =
roleAndPermissionSlice?.actions;

export default roleAndPermissionSlice.reducer;
