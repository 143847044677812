import { initialState } from "./reducer";

const getStateData = (state: any) => state["workerList"] || initialState;

export const getworkerList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.workerList;
}


export const getworkerListLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.workerListLoading;
};
