import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  profileInfo: {} as any,
  profileInfoLoading: false, 
};

const profileInfoSlice = createSlice({
  name: "profileInfo",
  initialState,
  reducers: {
    setProfileInfo: (state, { payload }) => {
      return { ...state, profileInfo: payload };
    },
    setProfileInfoLoading: (state, { payload }) => {
        return { ...state, profileInfoLoading: payload };
    },
},
    
});
export const { setProfileInfo, setProfileInfoLoading} =
  profileInfoSlice.actions;

export default profileInfoSlice.reducer;