import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  organizationList: [] as any[],
  loadingOrganizationList: false,
  organizationListCount:0,
  organization:{},
  loadingOrganization:false,
  loadingOrgSubscription:false,
  orgSubscription:{},
  totalAvailableCredits:0
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganizationList: (state, { payload }) => {
      return {
        ...state,
        organizationList: payload,
      };
    },
    setLoadingOrganizationList: (state, { payload }) => {
      return {
        ...state,
        loadingOrganizationList: payload,
      };
    },
    setOrganizationListCount: (state, { payload }) => {
      return {
        ...state,
        organizationListCount: payload,
      };
    },
    appendOrganizationList:(state,{payload})=>{
      return {
        ...state,
        organizationList:[...state?.organizationList, ...(payload ? payload : [])],
      }
    },
    setOrganization:(state,{payload})=>{
      return {
        ...state,
        organization: payload,
      };
    },
    setLoadingOrganization: (state, { payload }) => {
      return {
        ...state,
        loadingOrganization: payload,
      };
    },
    setOrgSubscription:(state,{payload})=>{
      return {
        ...state,
        orgSubscription: payload,
      };
    },
    setLoadingOrgSubscription: (state, { payload }) => {
      return {
        ...state,
        loadingOrgSubscription: payload,
      };
    },
    setTotalAvailableCredits: (state, { payload }) => {
      return {
        ...state,
        totalAvailableCredits: payload,
      };
    },
  },
});

export const { setOrganizationList, setLoadingOrganizationList ,setOrganizationListCount,appendOrganizationList,setOrganization,setLoadingOrganization,setOrgSubscription,setLoadingOrgSubscription,setTotalAvailableCredits} =
  organizationSlice.actions;

export default organizationSlice.reducer;
