import styles from "./ProfileMenu.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "./selector";
import { capitalizeFirstLetter } from "../../utils/utils";
import Button from "../../atoms/Button";
import { useState } from "react";
import LogActivityDrawer from "../LogActivityDrawer/LogActivityDrawer";
import { Description } from "@mui/icons-material";

const ProfileMenu =() =>{
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const[open,setOpen]= useState(false)
  return (
    <>
    <div className={`${styles.profileMenu} flex alignCenter`} >
        <span className={` ${styles.userName}`}>
          {profileInfo && profileInfo?.nameFirst ? profileInfo?.nameFirst : ""}{" "}
          {profileInfo && profileInfo.nameLast ? profileInfo.nameLast : ""}
          <span>({profileInfo && profileInfo?.roleName?.split("_")
          .map((word: any) => capitalizeFirstLetter(word))
          .join(" ")})</span>
        </span>
        <Button className="transparent" prefix={<Description/>} action={()=>setOpen(true)}></Button>
    {open ? <LogActivityDrawer open={open} handleClose={()=> setOpen(false)}/> : ''}
    </div>
    
        
     </>
  );
}

export default ProfileMenu;