import { initialState } from "./reducer";

const getStateData = (state: any) => state["billing"] || initialState;

export const getInvoices = (state: any) => {
    const reducer = getStateData(state);
    return reducer.invoices;
  };

  export const getInvoicesLoading = (state: any) => {
    const reducer = getStateData(state);
    return reducer.invoicesIsLoading;
  };