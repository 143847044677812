import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  logActivityList: [] as any[],
  logActivityListLoading: false,
  logActivityListCount:0,
};

const logActivitySlice = createSlice({
  name: "logActivity",
  initialState,
  reducers: {
    setLogActivityList: (state, { payload }) => {
      return {
        ...state,
        logActivityList: payload,
      };
    },
    setLogActivityListLoading: (state, { payload }) => {
      return {
        ...state,
        logActivityListLoading: payload,
      };
    },
    setLogActivityListCount: (state, { payload }) => {
      return {
        ...state,
        logActivityListCount: payload,
      };
    },
    appendLogActivityList:(state,{payload})=>{
      return {
        ...state,
        logActivityList:[...state?.logActivityList, ...(payload ? payload : [])],
      }
    },
    
  },
});

export const { setLogActivityList, setLogActivityListLoading ,appendLogActivityList,setLogActivityListCount} =
logActivitySlice.actions;

export default logActivitySlice.reducer;
