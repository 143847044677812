import { postAPI, putAPI } from "../../apis/apis";

export const addCreditCount = async (payload: any) => {
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/organisations/add-credit`,
    headers: {},
    data: payload,
  });
};

export const cancelSubscription = async (payload: any,id:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/admin/cancel-subscription/${id}`,
      headers: {},
      data: payload,
    });
  };

  export const refundAmount = async (payload: any,id:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/admin/payment-refund/${id}`,
      headers: {},
      data: payload,
    });
  };

  export const pauseSubscription = async (payload: any,id:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/admin/pause-subscription/${id}`,
      headers: {},
      data: payload,
    });
  };

  export const resumeSubscription = async (subId:any,id:any) => {
    return postAPI({
      url:
        process.env.REACT_APP_BASE_URL + `/admin/resume-subscription/${subId}/${id}`,
      headers: {},
    });
  };