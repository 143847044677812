import { initialState } from "./reducer";

const getStateData = (state: any) => state["roleList"] || initialState;

export const getPendingUserList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.pendingUserList;
}

export const getPendingListLoading=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.pendingUserListLoading;
}

export const getPendingListCount=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.pendingUserListcount;
}

export const getActiveUserList=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.activeUserList;
}

export const getActiveListCount=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.activeUserListcount;
}

export const getActiveListLoading=(state:any)=>{
  const reducer = getStateData(state);
  return reducer.activeUserListLoading;
}
