import { Dialog, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Close from "../../assets/icons/cross";
import { useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { refundSchema } from "./validation";
import Button from "../../atoms/Button";
import { refundAmount } from "./service";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { sendActivityLog } from "../auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { CustomError } from "../../models/customError.interface";
import { fetchInvoices } from "../../pages/historyDetail/sagaAction";
import DropDown from "../../atoms/Dropdown";
import { refundMethod } from "./constants";

const Refund = ({ handleClose, open, detail ,setRefundDetail,invoiceFilter}: any) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    reset,clearErrors
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(refundSchema(detail?.amount - detail?.amount_refunded)),
  });
  const dispatch = useDispatch();
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
const onSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        paymentIntentId: detail?.paymentIntent,
        amount:watch("refundMethod") === "full"? detail.amount :  watch("amount"),
      };
      await refundAmount(payload, param?.orgId);
      handleShowToast(`Refund of $${watch("refundMethod") === "full"? detail.amount :  watch("amount")} processed successfully!`, ToastType.success);
      setLoading(false);
      setRefundDetail({})
      handleClose();
      dispatch(
        fetchInvoices({
          last_Index: '',
          size: invoiceFilter.size,
          id: param.orgId,
        })
      );
      const attributes = {
        action: `User has refunded amount for organisation ${param.orgId} of ${detail.paymentIntent} from ${detail?.amount} to ${watch("amount")}`,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);

        try {
          const res = await sendActivityLog({
            logType: `User has refunded amount for organisation ${param.orgId}`,
            jsonData: result,
          });
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
     
    } catch (error: any) {
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
      setLoading(false);
    }
  };
 
  useEffect(() => {
    if (!open) {
      setValue("amount", null as unknown as number);
      reset()
    }
    if(watch("refundMethod") === "full"){
      setValue("amount", null as unknown as number);
      clearErrors(["amount"])
    }
  }, [open,watch("refundMethod")]);



  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: `User is trying to refund amount for organisation ${param.orgId} of ${detail.paymentIntent}`,
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        try {
          const res = await sendActivityLog({
            logType: "User is trying to refund amount",
            jsonData: result,
          });
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    if(open){
      logAuditData();
    }
  }, [profileInfo,open]);

 return (
    <>
      <Dialog className="stepsDialog" onClose={handleClose} open={open}>
        <span role="link" onClick={handleClose} className="closeDialog">
          <Close />
        </span>
        <div className="dialogWrapper">

<DropDown
          options={refundMethod}
          label="Refund Method*"
          control={control}
          name="refundMethod"
          errors={errors}
          placeholder="Select method"
        ></DropDown>
          {watch("refundMethod") === "partial" ?  <Input
            type="numeric"
            label="Enter Amount*"
            register={register}
            name="amount"
            errors={errors}
            control={control}
          ></Input> : ''}
          <div className="actions">
              <Tooltip
                title={
                  watch("refundMethod") === "full"  && detail?.amount_refunded != 0 ? "You can not select payment method as full beacuse you have partially refunded before" :
                  detail?.amount - detail?.amount_refunded === 0
                    ? "The total amount has already been refunded"
                    : ""
                }
              >
                <div>
                  <Button
                    className="full primary"
                    label={ "Refund"
                    }
                    action={handleSubmit(onSubmit)}
                    disabled={
                      loading || detail?.amount - detail?.amount_refunded === 0 || (detail?.amount_refunded !== 0 && watch("refundMethod") === "full")
                    }
                    clicked={loading}
                  />
                </div>
              </Tooltip>
            </div>
         
        </div>
      </Dialog>
    </>
  );
};
export default Refund;
