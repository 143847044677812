import { createSvgIcon } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller } from "react-hook-form";
import Close from "../assets/icons/cross";

interface DatePickerProps {
  control: any;
  name: string;
  errors: any;
  label?: string;
  disabled?: boolean;
  placeHolder?: string;
  clearable?: boolean;
  onDateChange?: any;
  onBeforeInput?: any;
  defaultValue?: any;
  calenderCheckIcon?: any; 
}

const DatePickerInTable = ({
  control,
  name,
  errors,
  label,
  disabled,
  placeHolder = "",
  clearable = false,
  onDateChange,
  defaultValue,
  calenderCheckIcon,
}: DatePickerProps) => {
  const CalenderIcon = createSvgIcon(
    <path
      d="M17.5 8.33329H2.5M13.3333 1.66663V4.99996M6.66667 1.66663V4.99996M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8211 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7334 17.5 14.3333V7.33329C17.5 5.93316 17.5 5.2331 17.2275 4.69832C16.9878 4.22791 16.6054 3.84546 16.135 3.60578C15.6002 3.33329 14.9001 3.33329 13.5 3.33329H6.5C5.09987 3.33329 4.3998 3.33329 3.86502 3.60578C3.39462 3.84546 3.01217 4.22791 2.77248 4.69832C2.5 5.2331 2.5 5.93316 2.5 7.33329V14.3333C2.5 15.7334 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8211 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    "FlightLandIcon"
  );
  const CalenderIconCheck = createSvgIcon(
    <path
      d="M18.375 9.25H2.625M14 2.25V5.75M7 2.25V5.75M7.875 14.5L9.625 16.25L13.5625 12.3125M6.825 19.75H14.175C15.6451 19.75 16.3802 19.75 16.9417 19.4639C17.4356 19.2122 17.8372 18.8106 18.0889 18.3167C18.375 17.7552 18.375 17.0201 18.375 15.55V8.2C18.375 6.72986 18.375 5.99479 18.0889 5.43327C17.8372 4.93935 17.4356 4.53778 16.9417 4.28611C16.3802 4 15.6451 4 14.175 4H6.825C5.35486 4 4.61979 4 4.05827 4.28611C3.56435 4.53778 3.16278 4.93935 2.91111 5.43327C2.625 5.99479 2.625 6.72986 2.625 8.2V15.55C2.625 17.0201 2.625 17.7552 2.91111 18.3167C3.16278 18.8106 3.56435 19.2122 4.05827 19.4639C4.61979 19.75 5.35486 19.75 6.825 19.75Z"
      stroke="#344054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    "FlightLandIcon"
  );
  const [open, setOpen] = useState(false);
  const getPlaceholderText = (date: any) => {
    const today = dayjs().startOf("day");
    const yesterday = today.subtract(1, "day");
    const tomorrow = today.add(1, "day");

    if (date?.isSame(today, "day")) {
      return "Today";
    } else if (date?.isSame(yesterday, "day")) {
      return "Yesterday";
    } else if (date?.isSame(tomorrow, "day")) {
      return "Tomorrow";
    } else if (date === null) {
      return "Pick a date";
    } else {
      return "";
    }
  };

  return (
    <div
      className={`datePicker ${clearable ? `clearBtn` : ``}`}
      data-testid="datePickerTestid"
    >
      {label && <label className="label">{label}</label>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, ...props }) => {
          const dayText = getPlaceholderText(
            field.value ? dayjs(field.value) : null
          );
          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div
                  className={`date-picker-wrapper ${dayText === "Tomorrow" && `tomorrow`}
                   ${dayText === "Today" && `today`}
                    ${dayText === "Yesterday" && `yesterday`}
                    ${dayText === "Pick a date" && `pickadate`}`}
                >
                  <DesktopDatePicker
                    open={open}
                    orientation="portrait"
                    value={
                      [
                        "Tomorrow",
                        "Today",
                        "Yesterday",
                        "Pick a date",
                      ].includes(dayText) && !open
                        ? null
                        : field.value
                        ? dayjs(field.value)
                        : null
                    }
                    slots={{
                      openPickerIcon: calenderCheckIcon
                        ? CalenderIconCheck
                        : CalenderIcon,
                    }}
                    slotProps={{
                      textField: {
                        error: errors?.[name] ? true : false,
                        onClick: () => setOpen(true),
                        onFocus: (e:any) => e.target.blur(),
                        onBeforeInput: (e:any) => e.preventDefault(),
                        inputProps: {
                          placeholder: dayText,
                        },
                      },
                      field: {
                        clearable: clearable,
                      },
                    }}
                    onClose={() => setOpen(false)}
                    onChange={(newValue:any) => {
                      if (dayjs(newValue).isValid()) {
                        field.onChange(newValue);
                        if (onDateChange) {
                          onDateChange(dayjs(newValue).format());
                        }
                      } else {
                        field.onChange(null);
                        if (onDateChange) {
                          onDateChange(null);
                        }
                      }
                    }}
                    disabled={disabled}
                    minDate={dayjs(new Date())}
                    format="MMM DD, YYYY"
                    onOpen={() => setOpen(true)}
                  />

                  {!["Pick a date"].includes(dayText) &&
                    !open && (
                      <span
                        className="clearButtonOther"
                        onClick={() => {
                          field.onChange(null);
                          if (onDateChange) {
                            onDateChange(null);
                          }
                        }}
                      >
                        <Close />
                      </span>
                     )}
                </div>
              </LocalizationProvider>
              {errors && errors?.[name] && (
                <span className="error">{errors?.[name]?.message}</span>
              )}
            </>
          );
        }}
      />
    </div>
  );
};
export default DatePickerInTable;
