import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer"
import * as sagaActions from "./sagaAction"
import { getAPI, postAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchRolesList({payload}:any) {
  const {index,size}=payload
  yield put(slice.setRoleListLoading(true));
  try {
    let url = process.env.REACT_APP_BASE_URL + '/role-permission/roles';
    if (index !== undefined && size !== undefined) {
      url += `?index=${index}&size=${size}`;
    }
    const response: ResponseGenerator = yield getAPI({
      url:url,
      headers: {},
      data: {},
    });
    
    yield all([
      put(!index ? slice.setRolesList(response?.data?.result) : index > 1 ? slice.appendRolesList(response?.data?.result): slice.setRolesList(response?.data?.result)),
      put(slice.setRoleListLoading(false)),
      put(slice.setRolesListCount(response?.data?.total)),
    ]);
  } catch (error: any) {
    console.log(error)
    yield put(slice.setRoleListLoading(false));
  }
}

function* fetchPermissionList({payload}:any) {
  const {index,size}=payload
  yield put(slice.setPermissionsLoading(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url:
        process.env.REACT_APP_BASE_URL +
        `/role-permission/permissions?index=${index}&size=${size}`,
      headers: {},
      data: {},
    });
    
    yield all([
      put(slice.setPermissionList(response?.data?.result)),
       put(slice.setPermissionsLoading(false))
    ]);
  } catch (error: any) {
    console.log(error)
    yield put(slice.setPermissionsLoading(false));
  }
}

function* rolesAndPermissionSaga() {
  yield takeLatest(sagaActions.fetchRolesList, fetchRolesList);
  yield takeLatest(sagaActions.fetchPermissionList, fetchPermissionList);
}

export default rolesAndPermissionSaga;
