import React, { useCallback, useEffect, useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styles from "./LogActivityDrawer.module.scss";
import {
  getLogActivityList,
  getLogActivityListCount,
  getLogActivityListLoading,
} from "./selector";
import { getProfileInfo } from "../profile/selector";
import { fetchLogActivityList } from "./sagaAction";
import CustomTable from "../../molecules/table/CustomTable";
import {
  capitalizeFirstLetter,
  followUpDateFormatter,
} from "../../utils/utils";
import { columns } from "./constants";
import Loader from "../../atoms/Loader";
import Close from "../../assets/icons/cross";

const LogActivityDrawer = ({ open, handleClose }: any) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ index: 1, size: 20 });
  const activityList = useSelector((state) => getLogActivityList(state));
  const activityListCount = useSelector((state) =>
    getLogActivityListCount(state)
  );
  const activityListLoading = useSelector((state) =>
    getLogActivityListLoading(state)
  );
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [tableData, setTableData] = useState([]);

  const logList = useCallback(() => {
    const listData = activityList?.map((list: any) => {
      return {
        userId: list?.userId,
        email: list?.jsonData?.email,
        logType: list?.logType,
        roleName: list?.jsonData?.roleName?.split("_")
          .map((word: any) => capitalizeFirstLetter(word))
          .join(" "),
        createdAt: followUpDateFormatter(
          list?.createdAt ? new Date(list?.createdAt) : null
        ),
        jsonData: `${JSON.stringify(list?.jsonData)}`,
      };
    });
    setTableData(listData ?? []);
  }, [activityList]);

  useEffect(() => {
    logList();
  }, [activityList]);

  useEffect(() => {
    const payload = {
      id: profileInfo.id,
      size: filter.size,
      index: filter.index,
    };
    if (open) {
      dispatch(fetchLogActivityList(payload));
    }
  }, [open, filter]);

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
  };

  return (
    <>
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        onOpen={open}
        className="LogDrawer"
        hideBackdrop={false}
        
      >
        <div className={`${styles.log}`}>
          <div
            className={`flex alignCenter justifySpaceBetween ${styles.log__header}`}
          >
            <h2>Log Activity</h2>
            <span role="link" onClick={handleClose}>
              <Close />
            </span>
          </div>
          <div className={`${styles.log__body}`}>
            {activityListLoading ? (
              <Loader />
            ) : (
              <CustomTable
                rows={tableData}
                columns={columns}
                search={""}
                tableStriped
                hasMore={tableData?.length < activityListCount}
                handleNextPage={handleNextPage}
              ></CustomTable>
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default LogActivityDrawer;
