import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  pendingUserList:[] as any[],
  pendingUserListLoading:false,
  pendingUserListcount:0,
  activeUserList:[],
  activeUserListcount:0,
  activeUserListLoading:false
};

const roleListSlice = createSlice({
  name: "roleList",
  initialState: initialState,
  reducers: {
    setPendingUserList: (state, { payload }) => {
      return {
        ...state,
        pendingUserList: payload,
      };
      
    },
    appendPendingUserList: (state, { payload }) => {
      return {
        ...state,
        pendingUserList: [...state?.pendingUserList, ...(payload ? payload : [])],
      };
    },
    setPendingUserListLoading: (state, { payload }) => {
      return {
        ...state,
        pendingUserListLoading: payload,
      };
    },
    setPendingUserListCount: (state, { payload }) => {
      return {
        ...state,
        pendingUserListcount: payload,
      };
    },
    setActiveUserList: (state, { payload }) => {
      return {
        ...state,
        activeUserList: payload,
      };
      
    },
    setActiveUserListCount: (state, { payload }) => {
      return {
        ...state,
        activeUserListcount: payload,
      };
    },
    setActiveUserListLoading: (state, { payload }) => {
      return {
        ...state,
        activeUserListLoading: payload,
      };
    },
  },
});

export const { setPendingUserList,setPendingUserListLoading,setPendingUserListCount,setActiveUserList,setActiveUserListLoading,appendPendingUserList} = roleListSlice.actions;

export default roleListSlice.reducer;
