import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaAction";
import { getAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

// function* fetchRoleList() {
//   try {
//     const response: ResponseGenerator = yield getAPI({
//       url: process.env.REACT_APP_BASE_URL + `/admin/roles`,
//       headers: {},
//       data: {},
//     });
//     yield put(slice.setRoleList(response?.data));
//   } catch (error: any) {
//     console.log(error);
//   }
// }

function* fetchPendingList(payload:any) {
  const { index, size } = payload?.payload;
  if (index === 1) {
    yield put(slice.setPendingUserListLoading(true));
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/pending-admin-invite?index=${index}&size=${size}`,
      headers: {},
      data: {},
    });
    yield all([
      put((index ?? 0) > 1
      ? slice.appendPendingUserList(response?.data?.data)
      : slice.setPendingUserList(response?.data?.data)),
      put(slice.setPendingUserListLoading(false)),
      put(slice.setPendingUserListCount(response?.data?.total)),
    ]);
  } catch (error: any) {
    console.log(error);
    yield put(slice.setPendingUserListLoading(false));
  }
}

function* fetchActiveList() {
  yield put(slice.setActiveUserListLoading(true));
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/active-admin-user`,
      headers: {},
      data: {},
    });
    yield all([put(slice.setActiveUserList(response?.data?.data)),
      put(slice.setActiveUserListLoading(false)),
    ]);
  } catch (error: any) {
    console.log(error);
    yield put(slice.setActiveUserListLoading(false));
  }
}

function* usersSaga() {
  // yield takeLatest(sagaActions.fetchRoleList, fetchRoleList);
  yield takeLatest(sagaActions.fetchPendingUsersList, fetchPendingList);
  yield takeLatest(sagaActions.fetchActiveUsersList, fetchActiveList);
}

export default usersSaga;
