import { getAPI, postAPI, putAPI } from "../../apis/apis";

export const disableAccount = async (payload: any) => {
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/organisation/disable/${payload}`,
    headers: {},
  });
};

export const enableAccount = async (payload: any) => {
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/organisation/enable/${payload}`,
    headers: {},
  });
};

export const disableUser = async (payload: any) => {
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/user/disable/${payload}`,
    headers: {},
  });
};

export const enableUser = async (payload: any) => {
  return putAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/user/enable/${payload}`,
    headers: {},
  });
};

export const addUser = async (payload: any,id:any) => {
  return postAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/add-user/${id}`,
    headers: {},
    data: payload,
  });
};

export const resetPasswordEmail = async (payload: any) => {
  return getAPI({
    url: process.env.REACT_APP_BASE_URL + `/admin/password-reset-email/${payload}`,
    headers: {},
  });
};