import { toast } from "react-toastify";
import { AttemptStatus } from "../pages/campaignDetails/enum";
import { jwtDecode } from "jwt-decode";

export const token = localStorage.getItem("accessToken");

export const capitalizeFirstLetter = (value: any) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export function formartNumberAsLocalString(
  value: any,
  option: any = {},
  fallback?: any
) {
  if (!parseFloat(value)) {
    return fallback ? fallback : "—";
  }
  return parseFloat(value).toLocaleString(undefined, option);
}

export const timeAgoFormatter = (date: Date) => {
  const currentTime = new Date();
  const diffInMiliSeconds = currentTime.getTime() - date.getTime();
  let time = diffInMiliSeconds / (365 * 60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " years" : "a year"} ago`;
  }
  time = diffInMiliSeconds / (30 * 60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " months" : "a month"} ago`;
  }
  time = diffInMiliSeconds / (7 * 60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " weeks" : "a week"} ago`;
  }
  time = diffInMiliSeconds / (60 * 60 * 24 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " days" : "a day"} ago`;
  }
  time = diffInMiliSeconds / (60 * 60 * 1000);
  if (time >= 1) {
    return `${Math.floor(time) > 1 ? Math.floor(time) + " hours" : "over an hour"} ago`;
  }
  time = diffInMiliSeconds / (60 * 1000);
  if (time < 15) {
    return "a few moments ago";
  } else {
    return "less than an hour ago";
  }
};

export const followUpDateFormatter = (dateString: Date | null | any) => {
  if (!dateString) {
    return "None";
  }
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  if (
    inputDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
    inputDate.getUTCMonth() === currentDate.getUTCMonth() &&
    inputDate.getUTCDate() === currentDate.getUTCDate()
  ) {
    return "Today";
  }
  const tomorrow = new Date(currentDate);
  tomorrow.setUTCDate(currentDate.getUTCDate() + 1);
  if (
    inputDate.getUTCFullYear() === tomorrow.getUTCFullYear() &&
    inputDate.getUTCMonth() === tomorrow.getUTCMonth() &&
    inputDate.getUTCDate() === tomorrow.getUTCDate()
  ) {
    return "Tomorrow";
  }
  const yesterday = new Date(currentDate);
  yesterday.setUTCDate(currentDate.getUTCDate() - 1);
  if (
    inputDate.getUTCFullYear() === yesterday.getUTCFullYear() &&
    inputDate.getUTCMonth() === yesterday.getUTCMonth() &&
    inputDate.getUTCDate() === yesterday.getUTCDate()
  ) {
    return "Yesterday";
  }
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "UTC",
    month: "short",
    day: "numeric",
    year: "numeric" as const,
  };
  return new Intl.DateTimeFormat("en-US", options).format(inputDate);
};
export function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return "-";
  let match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!match) return "-";
  return "(" + match[1] + ") " + match[2] + "-" + match[3];
}

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    console.log("Text copied to clipboard:", text);
    toast.info("Copied!", { autoClose: 2000 });
  } catch (err) {
    console.error("Unable to copy text to clipboard:", err);
  }
};

export const dateFn = (inputDate: any) => {
  if (inputDate === null) {
    return null;
  }
  if (inputDate === "Today") {
    return new Date().toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  } else if (inputDate === "Tomorrow") {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  } else if (inputDate === "Yesterday") {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }
  const parsedDate = new Date(inputDate);
  if (isNaN(parsedDate.getTime())) {
    return "Invalid Date";
  }

  return parsedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const firstName = (str: string) => {
  const firstNameParts = str?.split(" ");
  let finalFirstName = "";
  for (let i = 0; i < firstNameParts?.length; i++) {
    finalFirstName = finalFirstName + " " + toCamelCase(firstNameParts[i]);
  }
  return finalFirstName;
};

export function toCamelCase(str: string) {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
}

export function getDateDifferenceInWords(timestamp: Date, minimal = false): string {
  const timeDifference = new Date().getTime() - timestamp.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return minimal ? "just now" : "moments ago";
  } else if (minutes === 1) {
    return minimal ? "1m ago" : "a minute ago";
  } else if (minutes < 60) {
    return minimal ? `${minutes}m ago` : `${minutes} minutes ago`;
  } else if (hours === 1) {
    return minimal ? "1h ago" : "an hour ago";
  } else if (hours < 24) {
    return minimal ? `${hours}h ago` : `${hours} hours ago`;
  } else if (days === 1) {
    return minimal ? "1d ago" : "a day ago";
  } else {
    return minimal ? `${days}d ago` : `${days} days ago`;
  }
}

export function calculateProgress(attempts:any) {
  let doneCount = 0;
  let inProgressCount = 0;

  attempts.forEach((attempt:any) => {
      if (attempt.status ===AttemptStatus.DONE) {
          doneCount++;
      } else if (attempt.status === AttemptStatus.IN_PROGRESS) {
          inProgressCount++;
      }
  });

  if (doneCount >= 3) {
      return 100;
  } else if (doneCount === 2) {
      return inProgressCount > 0 ? 82.5 : 66; 
  } else if (doneCount === 1) {
      return inProgressCount > 0 ? 49.5 : 33; 
  } else {
      return inProgressCount > 0 ? 16.5 : 0; 
  }
}

export const decodetoken = (token = "", wantDetails = false) => {
  const jwt = token ? token : localStorage.getItem("accessToken") ?? "";

  try {
    const decodedToken = jwtDecode(jwt);

    let expirationTime = null;
    if (decodedToken && decodedToken.exp) {
      expirationTime = decodedToken.exp * 1000;
    }
    if (wantDetails && decodedToken) {
      return decodedToken;
    }
    return expirationTime;
  } catch (error: unknown) {
    return null;
  }
};

export function getLocalStorageData(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key) ?? "");
  } catch {
    return localStorage.getItem(key) || null;
  }
}

export const formatNumber = (number: number): string => {
  if (number < 1e3) return number?.toFixed(1)?.replace(/\.0$/, "");

  if (number >= 1e3 && number < 1e6) {
    const roundedNumber = Math.floor((number / 1e3) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}k`;
  }

  if (number >= 1e6 && number < 1e9) {
    const roundedNumber = Math.floor((number / 1e6) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}M`;
  }

  if (number >= 1e9 && number < 1e12) {
    const roundedNumber = Math.floor((number / 1e9) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}B`;
  }

  if (number >= 1e12) {
    const roundedNumber = Math.floor((number / 1e12) * 10) / 10;
    return `${roundedNumber?.toFixed(1)?.replace(/\.0$/, "")}T`;
  }

  return number?.toString();
};

export function formatCurrency(value: any) {
  if (!parseFloat(value)) {
    return "-";
  }
  return "$" + formartNumberAsLocalString(parseFloat(value), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formatMonthYear(dateString: string | undefined | null, word = true) {
  if (dateString === undefined || dateString === null) {
    return "-";
  }
  const inputDate = new Date(dateString);

  // Get the month name and year
  if (word) {
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(inputDate);
    const year = inputDate.getFullYear();
    const day = inputDate.getDate();

    // Create the formatted string
    const formattedDateString = `${day},${month}, ${year}`;

    return formattedDateString;
  }
  const month = inputDate.getUTCMonth() + 1; // Months are zero-based
  const year = inputDate.getUTCFullYear();
  const day = inputDate.getDate();

  // Pad single-digit month with leading zero if necessary
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Create the formatted string
  const formattedDateString = `${formattedMonth}/${day}/${year}`;

  return formattedDateString;
}

export function formatMonths(value: any) {
  if (!Boolean(value)) {
    return "-";
  }

  const months = parseInt(value);

  if (months === 1) {
    return `1 month`;
  } else if (months < 12) {
    return `${months} months`;
  } else {
    const tempMonth = Math.floor(months / 12);
    let result = `${tempMonth} ${tempMonth === 1 ? "year" : "years"}`;
    if (months % 12) {
      result += ` ${formatMonths(months % 12)}`;
    }
    return result;
  }
}


export function formatPropertyType(propertyType: string | undefined | null) {
  if (propertyType === undefined || propertyType === null) {
    return "-";
  }
  // Split the input string into words
  const words = propertyType?.split("_");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with a space
  const formattedPropertyType = capitalizedWords.join(" ");

  return formattedPropertyType;
}

export function formatDate(inputDateString: string | undefined | null) {
  if (inputDateString === undefined || inputDateString === null) {
    return "-";
  }
  const inputDate = new Date(inputDateString);

  // Extract components of the date
  const day = inputDate.getUTCDate();
  const month = inputDate.getUTCMonth() + 1; // Months are zero-based
  const year = inputDate.getUTCFullYear();

  // Pad single-digit day or month with leading zero if necessary
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  // Create the formatted date string
  const formattedDateString = `${formattedDay}/${formattedMonth}/${year}`;

  return formattedDateString;
}

export function formatNewCurrency(value: any) {
  if (!parseFloat(value)) {
    return "0";
  }
  return "$" + formartNewNumberAsLocalString(parseFloat(value), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formatUpdateCurrency(value: any) {
  if (!parseFloat(value)) {
    return "0";
  }
  return  formartNewNumberAsLocalString(parseFloat(value), { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function formartNewNumberAsLocalString(value: any, option: any = {}, fallback?: any) {
  if (!parseFloat(value)) {
    return fallback ? fallback : "0";
  }
  return parseFloat(value).toLocaleString(undefined, option);
}

export const addDaysToDate = (days:any, expiryDate = new Date()) => {
  const result = new Date(expiryDate);
  result.setDate(result.getDate() + parseInt(days, 10));
  return result.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
};

