import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  leadList: [] as any[],
  leadListCount: 0,
  leadListLoading: false,
  propertyDetailsData: {},
  isLoading:false,
  wholesaleCalculatorlist:{},
  wholesaleCalculatorLoading:false,
  amortizationCalculatorlist:{},
  amortizationCalculatorLoading:false,
  financeCalculatorlist:[],
  financeCalculatorLoading:false,
  financeCalculatorDetail:{}
};

const leadListSlice = createSlice({
  name: "leadList",
  initialState,
  reducers: {
    setLeadList: (state, { payload }) => {
      return {
        ...state,
        leadList: payload,
      };
    },
    appendLeadList: (state, { payload }) => {
      return {
        ...state,
        leadList: [...state?.leadList, ...(payload ? payload : [])],
      };
    },
    setLeadListCount: (state, { payload }) => {
      return {
        ...state,
        leadListCount: payload,
      };
    },
    handleLoaderForRow: (state, { payload: { data } }) => {
      const { id, key, value } = data;
      const leadListCopy = [...state.leadList];
      const index = state.leadList.findIndex((e) => {
        return id === e.id;
      });
      if (index !== -1) {
        let temp = leadListCopy[index];
        leadListCopy[index] = JSON.parse(
          JSON.stringify({ ...temp, [key]: value })
        );
      }
      return {
        ...state,
        leadList: leadListCopy,
      };
    },
    setLeadListLoading: (state, { payload }) => {
      return { ...state, leadListLoading: payload };
    },
    resetLeadState: (state, { payload }) => {
      return initialState;
    },
    setPropertyDetails: (state, { payload }) => {
      return {
        ...state,
        propertyDetailsData: payload,
      };
    },
    setPropertyDetailLoading: (state, { payload }) => {
      return {
        ...state,
        isLoading: payload,
      };
    },setwholesaleCalculatorlist: (state, { payload }) => {
      return {
        ...state,
        wholesaleCalculatorlist: payload,
      };
    },
    setwholesaleCalculatorLoading: (state, { payload}) => {
      return {
        ...state,
        wholesaleCalculatorLoading: payload,
      };
    },
    setamortizationCalculatorlist: (state, { payload }) => {
      return {
        ...state,
        amortizationCalculatorlist: payload,
      };
    },
    setamortizationCalculatorLoading: (state, { payload }) => {
      return {
        ...state,
        amortizationCalculatorLoading: payload,
      };
    },
    setFinanceCalculatorlist: (state, { payload }) => {
      return {
        ...state,
        financeCalculatorlist: payload,
      };
    },
    setFinanceCalculatorDetail: (state, { payload }) => {
      return {
        ...state,
        financeCalculatorDetail: payload,
      };
    },
    setFinanceCalculatorLoading: (state, { payload }) => {
      return {
        ...state,
        financeCalculatorLoading: payload,
      };
    }
  },
});

export const {
  setLeadList,
  appendLeadList,
  setLeadListLoading,
  handleLoaderForRow,
  setLeadListCount,resetLeadState,setPropertyDetailLoading,
  setPropertyDetails,
  setwholesaleCalculatorlist,
  setwholesaleCalculatorLoading,
  setamortizationCalculatorlist,
  setamortizationCalculatorLoading,setFinanceCalculatorlist,setFinanceCalculatorLoading,setFinanceCalculatorDetail
} = leadListSlice.actions;

export default leadListSlice.reducer;
