import { takeLatest, put, all, call } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaAction";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchProfileInfo() {
  try {
    yield put(slice.setProfileInfoLoading(true));
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/profile`,
      headers: {},
    });
    console.log(response?.data)
    yield all([
      put(slice.setProfileInfoLoading(false)),
      put(slice.setProfileInfo(response?.data?.data)),
    ]);
  } catch (error: any) {
    yield all([
      put(
        addToast({ 
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  } finally {
    yield put(slice.setProfileInfoLoading(false));
  }
}

function* profileInfoSaga() {
  yield takeLatest(sagaActions.fetchProfileInfo, fetchProfileInfo);
}

export default profileInfoSaga;
