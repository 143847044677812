import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer"
import * as sagaActions from "./sagaAction"
import { getAPI, postAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchCampaignsList({payload}:any) {
    const {index,size,search,id,orgId,filter}=payload
    console.log(payload)
    if (index === 1) {
      yield put(slice.setLoadingCampaignsList(true));
    }
    yield put(slice.setCampaignsListFetchingMore(true));
    try { 
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL +
          `/admin/campaign/detail-table`,
       
          headers: {},
        data: payload,
      });
      yield all([
        put( index > 1 ? slice.appendCampaignsList(response?.data?.result) : slice.setCampaignsList(response?.data?.result)),
        put(slice.setCampaignsListCount(response?.data?.total)),
        put(slice.setLoadingCampaignsList(false)),
        put(slice.setCampaignsListFetchingMore(false))
      ]);
    } catch (error: any) {
      yield all([
        put(slice.setLoadingCampaignsList(false)),
      ]);
    }
  }
  
  function* fetchCampaignLeadList({payload}:any) {
    const {index,size,search,campaignId,orgId}=payload
    console.log(payload)
    if (index === 1) {
      yield put(slice.setLoadingCampaignLeadList(true));
    }
    yield put(slice.setcampaignLeadListFetchingMore(true))
    try { 
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL +
          `/admin/campaign/leads/${campaignId}`,
        headers: {},
        data: payload,
      });
      yield all([
        put( index > 1 ? slice.appendCampaignLeadList(response?.data?.data) : slice.setCampaignLeadList(response?.data?.data)),
        put(slice.setCampaignLeadListCount(response?.data?.total)),
        put(slice.setLoadingCampaignLeadList(false)),
        put(slice.setcampaignLeadListFetchingMore(false)),
        
      ]);
    } catch (error: any) {
      yield all([
        put(slice.setLoadingCampaignsList(false)),
      ]);
    }
  }

  function* fetchCampaignActivityList({payload}:any) {
    const {index,size,search,campaignId,orgId}=payload
    console.log(payload)
    if (index === 1) {
      yield put(slice.setLoadingCampaignActivityList(true));
    }
    try { 
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL +
          `/admin/campaign/leads/activity/${campaignId}`,
        headers: {},
        data:payload,
      });
    
      yield all([
        put( index > 1 ? slice.appendCampaignActivityList(response?.data?.data) : slice.setCampaignActivityList(response?.data?.data)),
        put(slice.setCampaignActivityListCount(response?.data?.total)),
        put(slice.setLoadingCampaignActivityList(false)),
      ]);
    } catch (error: any) {
      yield all([
        put(slice.setLoadingCampaignActivityList(false)),
      ]);
    }
  }

  function* fetchTopSectionData({ payload }: any) {
    const {index,size,search,campaignId,orgId}=payload
    try {
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL + `/admin/campaign/campaign-progress/${campaignId}`,
        data: payload,
        headers: {},
      });
      yield put(slice.setTopSectionData(response));
    } catch (error) {
      console.log("error", error);
      yield put(slice.setTopSectionData(error));
    }
  }

  function* fetchAttemptId({ payload }: any) {
    const {index,size,search,campaignId,orgId}=payload
    yield put(slice.setIsAttemptSelected(true));
    try {
      const response: ResponseGenerator = yield getAPI({
        url: process.env.REACT_APP_BASE_URL + `/admin/campaign/get-attempt/${campaignId}`,
        headers: {},
        data:payload
      });
      yield put(slice.setAttemptDetails(response?.data));
    } catch (error) {
      console.log("error=", error);
    } finally {
      yield put(slice.setIsAttemptSelected(false));
    }
  }

  function* fetchGraphData({ payload }: any) {
    const {index,size,search,campaignId,orgId}=payload
    try {
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL +
          `/admin/campaign/graph/${campaignId}`,
          
        headers: {},
        data:payload
      });
      yield put(slice.setGraphData(response));
    } catch (error) {
      console.log("error", error);
    }
  }

  function* fetchCampaignsListDetail({payload}:any) {
    const {campaignId,organisationId}=payload
    console.log(payload)
      yield put(slice.setLoadingCampaignList(true));
    try { 
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL +
          `/admin/campaign/get-list/${campaignId}?organisationId=${organisationId}`,
        headers: {},
        data: {},
      });
      yield all([
        put(slice.setCampaignListDetail(response?.data)),
        put(slice.setLoadingCampaignList(false)),
      ]);
    } catch (error: any) {
      yield all([
        put(slice.setLoadingCampaignList(false)),
      ]);
    }
  }

  function* fetchHungupData({ payload }: any) {
    const {id ,status,preview} = payload;
    try {
      yield put(slice.setHungUpDataLoading(true));
      const response: ResponseGenerator = yield getAPI({
        url: process.env.REACT_APP_BASE_URL + `/admin/campaign/attempt-status-data/${id}?status=${status}&isPreview=${preview}`,
        headers: {},
      });
      yield all([
        put( slice.setHungUpData(response.data)),
        put(slice.setHungUpDataLoading(false)),
      ]);
  
    } catch (error) {
      console.log("error=", error);
    }
  }

  function* fetchCampaignData({payload}:any) {
    const {campaignId}=payload
    try { 
      const response: ResponseGenerator = yield getAPI({
        url:
          process.env.REACT_APP_BASE_URL +
          `/admin/campaign/leads/dashboard/${campaignId}`,
       
          headers: {},
        data: payload,
      });
      yield put (slice.setCampaignData(response?.data)) 
    }
     catch (error: any) {
    }
  }

  function* CampaignsListSaga() {
    yield takeLatest(sagaActions.fetchCampaignsList, fetchCampaignsList);
    yield takeLatest(sagaActions.fetchCampaignLeadsList, fetchCampaignLeadList);
    yield takeLatest(sagaActions.fetchCampaignActivityList, fetchCampaignActivityList);
    yield takeLatest(sagaActions.fetchTopSectionData,fetchTopSectionData) 
    yield takeLatest(sagaActions.fecthAttemptIdAction,fetchAttemptId) 
    yield takeLatest(sagaActions.graphAction,fetchGraphData)
    yield takeLatest(sagaActions.fetchCampaignsListDetail,fetchCampaignsListDetail)
    yield takeLatest(sagaActions.fetchHungupData,fetchHungupData);
    yield takeLatest(sagaActions.fetchCampaignData,fetchCampaignData);
    
    
  }
  
  export default CampaignsListSaga;