import { useDispatch, useSelector } from "react-redux";
import GoogleIcon from "../../assets/icons/google";
import Button from "../../atoms/Button";
import { signIn } from "../../organisms/auth/service";
import { setAuthGuardToken } from "../../organisms/authroute/action";
import Logo from "../../atoms/Logo";
import styles from "./Header.module.scss";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import ToggleMenu from "../../organisms/toggleMenu/ToggleMenu";
import {
    MEDIA_TYPES,
    MediaContext,
    MediaContextProps,
  } from "../../context/MediaContext";
import { useContext } from "react";
import { fetchProfileInfo } from "../../pages/profile/sagaAction";
import ProfileMenu from "../../pages/profile/ProfileMenu";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state: any) => state.authGuard);
    const { media } = useContext(MediaContext) as MediaContextProps;
  const clientID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
  const dispatch = useDispatch();
  const handleLogin = async (credentialResponse: any) => {
    const payload = { token: credentialResponse.credential };
    try {
      const result = await signIn(payload);
      dispatch(fetchProfileInfo({}))
      let accessToken = result?.data?.access_token;
      let refreshToken= result?.data?.refresh_token;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      dispatch(setAuthGuardToken(accessToken));
    } catch (err) {
      console.log(err);
    }
  };
  const handleHomeNavigate=()=>{
    navigate("/organisation");
  }

  return (
    <>
    <div className={`${styles.headerSpace}`}></div>
      <div className={`${styles.header} ${auth && styles.headerLoggedIn} `}>
        <div
          className={`dflex justifySpaceBetween alignCenter ${styles.header__wrapper} `}
        >
         
          <>
          <div className={`${styles.header__logo} `} onClick={()=> auth && handleHomeNavigate() }>
           <Logo/>
          </div>
          <div className={`${styles.header__right} `}>
          {!auth ?
            <GoogleOAuthProvider clientId={clientID}>
              <GoogleLogin onSuccess={handleLogin} />
            </GoogleOAuthProvider>
            : 
           ( 
           <div className="flex gap-10">
             { media === MEDIA_TYPES.DESKTOP && (
           <ProfileMenu/>
        )}
           { media !== MEDIA_TYPES.DESKTOP && (
            <ToggleMenu />
            )}
           </div>)
        }
       
          </div>
          </>
        
        </div>
      </div>
    </>
  );
};

export default Header;
