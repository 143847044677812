import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import styles from "./PropertyTab.module.scss";
import LinkedTab from "./linkedTab/LinkedTab";
import MlsTab from "./mlsTab/MlsTab";
import MortgageTab from "./mortgageTab/MortgageTab";
import OverviewTab from "./overviewTab/OverviewTab";
import PropertyDetailTab from "./propertyDetailTab/PropertyDetailTab";
import TaxTab from "./taxTab/TaxTab";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../utils/utils";
import ForeclosuresAndLines from "./ForeclosuresAndLinesTab/ForeclosuresAndLines";
import { getPropertyDetailsData } from "../selector";
import CreativeFinance from "./creativeFinance/CreativeFinance";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className={` ${styles.propertyTabs__content}`}>{children}</div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface PropertyTabsProps {
  handleClickSkipTrace?: () => void;
  handleClickAddContact?: () => void;
}

export default function PropertyTabs({ handleClickSkipTrace ,handleClickAddContact}: PropertyTabsProps) {
  const [value, setValue] = React.useState(0);
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  return (
    <div className={` ${styles.propertyTabs}`}>
      <Tabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        allowScrollButtonsMobile
      >
        <Tab label="Overview" {...a11yProps(0)} />
        <Tab label="Property Details" {...a11yProps(1)} />
        <Tab label="MLS Details" {...a11yProps(2)} />
        <Tab label="Tax Information" {...a11yProps(3)} />
        <Tab
          label={
            <>
              <React.Fragment>
                <span className={` ${styles.tabsButton}`}>
                  Linked Properties
                  {propertyDetails?.jsonData?.linkedProperties?.totalOwned && (
                    <span>
                      {
                        formatNumber(parseFloat(propertyDetails?.jsonData?.linkedProperties?.totalOwned) - 1)
                      }
                    </span>
                  )}
                </span>
              </React.Fragment>
            </>
          }
        />
        <Tab
          label={
            <>
              <React.Fragment>
                <span className={` ${styles.tabsButton}`}>
                Foreclosures & Liens
                  {(propertyDetails?.jsonData?.foreclosureInfo?.[0] || propertyDetails?.jsonData?.taxLien) && (
                    <span>
                      {
                        propertyDetails?.jsonData?.foreclosureInfo?.[0] || propertyDetails?.jsonData?.taxLien ? (propertyDetails?.jsonData?.foreclosureInfo?.[0] && propertyDetails?.jsonData?.taxLien ? 2 : 1) : 0
                      }
                    </span>
                  )}
                </span>
              </React.Fragment>
            </>
          }
        />
        <Tab label="Mortgage & Transactions" {...a11yProps(5)} />
        <Tab label="Creative finance Calculator" {...a11yProps(6)} />
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        <OverviewTab 
        // handleClickSkipTrace={handleClickSkipTrace} handleClickAddContact={handleClickAddContact}
         />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PropertyDetailTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <MlsTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <TaxTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <LinkedTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <ForeclosuresAndLines />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <MortgageTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        <CreativeFinance />
      </CustomTabPanel>
    </div>
  );
}
