import { useState } from "react";
import SearchReflation from "../../../../assets/icons/searchReflation";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../../../../molecules/table/CustomTable";
import AnalyticCards from "../../../../organisms/analyticCards/AnalyticCards";
import {
  formartNumberAsLocalString,
  formatCurrency,
  formatMonthYear,
  formatMonths,
  formatNumber,
} from "../../../../utils/utils";
// import { getPropertyDetails } from "../../property.selector";
import styles from "./LinkedTab.module.scss";
import { currentLinkedPropertiesColumns } from "./constants";
// import { createLead } from "../../../leads/leadsService";
// import { setCreateLeads } from "../../../../organisms/addLeads/reducer";
import { toast } from "react-toastify";
import { getPropertyDetailsData } from "../../selector";
const LinkedTab = () => {
  const dispatch = useDispatch();
  const [linkedPropertiesLoading, setLinkedPropertiesLoading] = useState<boolean>(false);
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const { linkedProperties } = propertyDetails?.jsonData;
  const { properties } = propertyDetails?.jsonData?.linkedProperties ?? [];

  const handleCreateLeads = async (property: any) => {
    // dispatch(setCreateLeads({}));
    const addressValues = property?.propertyInfo?.address;
    const missingInfo = ["state", "city", "street", "zip"];
    if (missingInfo.some((info) => !addressValues?.[info])) {
      toast.error(
        "Address value is incomplete, lacking either city, state, zip, or street information."
      );
      return;
    }
    try {
      let payload: {
        nameFirst: string;
        nameLast?: string;
        addressStreet: string;
        addressCity: string;
        addressState: string;
        addressZip: string;
        phones: string;
        isAddFromSearch: boolean;
      } = {
        nameFirst:
          property?.ownerInfo?.owner1FirstName ??
          property?.ownerInfo?.owner1LastName ??
          "-",
        addressStreet:
          property?.propertyInfo?.address?.address ??
          property?.propertyInfo?.address?.street ??
          "-",
        addressCity: property?.propertyInfo?.address?.city
          ? property?.propertyInfo?.address?.city
          : "-",
        addressState: property?.propertyInfo?.address?.state
          ? property?.propertyInfo?.address?.state
          : "-",
        addressZip: property?.propertyInfo?.address?.zip
          ? property?.propertyInfo?.address?.zip
          : "-",
        phones: "-",
        isAddFromSearch: true,
      };

      if (property?.ownerInfo?.owner1LastName) {
        payload = { ...payload, nameLast: property?.ownerInfo?.owner1LastName };
      }
      setLinkedPropertiesLoading(true);
      // const newLeadCreated = await createLead(payload);
      // if (newLeadCreated?.data?.property_id) {
      //   window.open(`/properties/${newLeadCreated?.data?.property_id}`);
      // }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLinkedPropertiesLoading(false);
    }
  };
  const handleLinkedPropertiesTableData = (data = []) => {
    if (!data.length) {
      return [];
    }

    return data?.map((el: Record<string, any>) => {
      let ownerName = "-";
      if (el?.ownerInfo?.owner1FullName) {
        ownerName = el?.ownerInfo?.owner1FullName;
      }
      if (el?.ownerInfo?.owner2FullName) {
        ownerName += "," + el?.ownerInfo?.owner2FullName;
      }

      return {
        address: el?.propertyInfo?.address?.label,
        handleOnClick: () => handleCreateLeads(el),
        beds:
          typeof el?.propertyInfo?.bedrooms === "number" &&
          !isNaN(el?.propertyInfo?.bedrooms)
            ? el?.propertyInfo?.bedrooms
            : "-",
        bath:
          typeof el?.propertyInfo?.bathrooms === "number" &&
          !isNaN(el?.propertyInfo?.bathrooms)
            ? el?.propertyInfo?.bathrooms
            : "-",

        sqFt:
          typeof el?.propertyInfo?.livingSquareFeet === "number" &&
          !isNaN(el?.propertyInfo?.livingSquareFeet)
            ? formartNumberAsLocalString(el?.propertyInfo?.livingSquareFeet)
            : "-",
        ownerNames: ownerName,
        yearBuilt: el?.propertyInfo?.yearBuilt ?? "-",
        ownershipLength: formatMonths(el?.ownerInfo?.ownershipLength),
        lastSaleDate: formatMonthYear(el?.lastSaleDate, false),
        lastSalePrice: formatCurrency(el?.lastSalePrice),
        estimatedMortgageBalance: formatCurrency(el?.estimatedMortgageBalance),
        estValue: formatCurrency(el?.estimatedValue),
        activityDrawerLeadId: false,
      };
    });
  };
  const addressTableRows = handleLinkedPropertiesTableData([]);

  return (
    <div className={` ${styles.linked}`}>
      <div className="wrapper">
        <div className={`dflex ${styles.cards}`}>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Total Properties Owned"
              value={
                linkedProperties?.totalOwned
                  ? formatNumber(parseInt(linkedProperties?.totalOwned))
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Total Open Loan Amount"
              value={
                linkedProperties?.totalMortgageBalance
                  ? `$ ${formatNumber(
                      parseInt(linkedProperties?.totalMortgageBalance)
                    )}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Total Estimated Value"
              value={
                linkedProperties?.totalValue
                  ? `$ ${formatNumber(parseInt(linkedProperties?.totalValue))}`
                  : "-"
              }
            />
          </div>
          <div className={` ${styles.card}`}>
            <AnalyticCards
              title="Portfolio Equity"
              value={
                linkedProperties?.totalEquity
                  ? `$ ${formatNumber(parseInt(linkedProperties?.totalEquity))}`
                  : "-"
              }
            />
          </div>
        </div>

        <div className={`calculator-table ${styles.table}`}>
          <div className={`calculator-table ${styles.tableHeading}`}>
            <h2>Linked Properties</h2>
            <p>Here's a sample of this property owner's linked properties.</p>
          </div>
          <div className={`${styles.tableInner}`}>
            {linkedPropertiesLoading && (
              <div className="map-loader">
                <div className="dot-pulse"></div>
              </div>
            )}

            {addressTableRows?.length > 0 ? (
              <CustomTable
                rows={addressTableRows}
                columns={currentLinkedPropertiesColumns}
                tableStriped
                emptyTableMetaData={{
                  icon: <SearchReflation />,
                  title: "No Data",
                }}
              />
            ) : (
              <EmptyPlaceholder
                icon={<SearchReflation />}
                title={"No results found"}
                description="We couldn’t find any results that match your search or filtering criteria."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LinkedTab;
